<form [formGroup]="secondStepForm" onsubmit="return false">
    <fieldset class="product-fieldset">
        <legend>{{ 'cabinet.product.chooseRetirementPlan' | translate }}</legend>
        <p class="product-fieldset__paragraph">
            {{ 'cabinet.product.retirementConfigurePlanWizzardDetails' | translate }}
        </p>
        <mat-form-field class="input--lg">
            <mat-label>{{ 'cabinet.offers.planSelect' | translate }}</mat-label>
            <mat-select formControlName="retirementPlan" (selectionChange)="handlePlanSelect($event)">
                @for (value of templates; track value.externalId) {
                <mat-option [value]="value">{{ value.externalId }}</mat-option>
                }
            </mat-select>
            @if (isRetirementPlanInvalid) {
            <mat-error>Required.</mat-error>}
        </mat-form-field>
        <div class="product-fieldset__template_options">
            <button
                type="button"
                color="primary"
                mat-raised-button
                [disabled]="!planTemplateSelected?.value || !isEditable"
                (click)="handleConfigurePlan()"
            >
                {{ 'cabinet.product.configurePlan' | translate }}<mat-icon iconPositionEnd>settings</mat-icon>
            </button>
        </div>
    </fieldset>
    @if(nextLoading) {
    <div class="spinner-box">
        <mat-spinner diameter="90" style="margin: 0 auto"></mat-spinner>
    </div>
    } @else { @if (showConfigurePlanForm && currentPlan) {
    <app-configure-plan
        [isPreviewMode]="isPreviewMode"
        [isEditable]="isEditable"
        [isPlanEditable]="offer?.planEditable"
        [currentPlan]="currentPlan"
        [offerId]="offerId"
        [savePlanOnNextBtn]="savePlanOnNextBtn"
    ></app-configure-plan>
    } }
    <next-back-button
        [nextLoading]="nextLoading"
        [isNextButtonDisabled]="isNextButtonDisabled"
        (backButton)="handleBackStep()"
        (nextButton)="handleSecondStepSubmit()"
    ></next-back-button>
</form>
