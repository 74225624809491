import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]',
  standalone: true
})
export class DragAndDropDirective {

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() openPanel = new EventEmitter<any>();
  @Input() disabled:boolean = false;
  constructor() { }
  @HostListener('dragover', ['$event']) onDragOver(evt){
    evt.preventDefault();
    evt.stopPropagation();
    if(this.disabled) {
      return;
    }
    this.fileOver = true;
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt){
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
  }
  @HostListener('drop', ['$event']) public onDrop(evt){
      evt.preventDefault();
      evt.stopPropagation();
      if(this.disabled) {
        return;
      }
      const files = evt.dataTransfer.files;
      this.fileDropped.emit(files);
      this.openPanel.emit();
      this.fileOver = false;
  }
}
