import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDateFnsModule, MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { IOffer, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { OffersService, SnackbarService } from '@core/services';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { THandleUpdateOffer, THandleUpdateStepsCompleted, TStepperMode, TStepsCompleted } from '@core/types';
import { IFoundation } from '@core/types/foundations';
import { IPensionFund } from '@core/types/pension-fund';
import ISector from '@core/types/sector';
import { isInputInvalid } from '@core/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { addYears, lastDayOfYear, Locale } from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import { DateFnsModule } from 'ngx-date-fns';
import { Subscription, zip, concatMap, Subject, debounceTime, map, distinctUntilChanged, switchMap } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { numberValidator } from '@core/validators/number.validator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PensionFundTypeDE, PensionFundTypeEN } from '@core/enums/pension-types.enum';
import { environment } from '@environment/environment';

export const MY_FORMATS = {
  parse: {
    dateInput: ['dd.MM.yyyy', 'dd/MM/yyyy', 'dd,MM,yyyy', 'dd-MM-yyyy'],
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@Component({
  selector: 'app-offers-step-basic-data',
  standalone: true,
  imports: [
    MatStepperModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatNativeDateModule,
    MatChipsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DateFnsModule,
    TranslateModule,
    NextBackButtonComponent,
    MatAutocompleteModule,
    MatTooltip,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(MY_FORMATS),
  ],
  templateUrl: './offers-step-basic-data.component.html',
  styleUrls: ['./offers-step-basic-data.component.scss'],
})
export class OffersStepBasicDataComponent implements OnInit, OnDestroy {
  @Input() stepper!: MatStepper;
  @Input() mode!: TStepperMode;
  @Input() offer!: IOffer;
  @Input() handleUpdateOffer!: THandleUpdateOffer;
  @Input() stepsCompleted!: TStepsCompleted;
  @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
  nextLoading = false;
  hideBackButton: boolean = true;
  newOffer: boolean = false;
  statusName: OfferStatusType;
  pensionFundType: string;
  public foundations: IFoundation[] = [];
  public sectors: ISector[] = [];
  public pensionFunds: IPensionFund[] = [];
  public pensionFundOptions: any = [];
  public selectedFoundation: IFoundation;

  newPensionFund = false;
  formSubmitted = false;
  environment = environment;

  firstStepForm = this._formBuilder.group(
    {
      foundation: [0, Validators.required],
      pensionFund: ['', Validators.required],
      pensionFundType: ['', Validators.required],
      contractStart: [new Date(2025, 0, 1), Validators.required],
      contractEnd: [new Date(2028, 11, 31), Validators.required],
      company: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      title: [null],
      firstName: [''],
      lastName: [''],
      phone: [''],
      email: ['', Validators.email],
      language: [0, Validators.required],
      brokerTitle: [null],
      brokerFirstName: [''],
      brokerLastName: [''],
      brokerPhone: [''],
      brokerEmail: ['', Validators.email],
      brokerCompanyId: [''],
      amountIVPensioner: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      fundedStatus: ['', [Validators.min(1), numberValidator, Validators.pattern(/^\d+(\.\d)?$/)]],
      sectorId: [0, Validators.required],
      activeToRetiredRatio: [false, Validators.requiredTrue],
      ageGroup: [false, Validators.requiredTrue],
      admissionGuidelines: [false, Validators.requiredTrue],
      managementApproval: [false, Validators.requiredTrue],
    },
    { validator: this.customValidator }
  );

  customValidator(group: FormGroup) {
    const pensionFund = group.get('pensionFund')?.value;

    if (!pensionFund || pensionFund?.length === 0) {
      return { isEmpty: true };
    }

    return null;
  }

  titleList = [
    { id: 1, value: this._translateService.instant('cabinet.baseDate.titleMale') },
    { id: 2, value: this._translateService.instant('cabinet.baseDate.titleFemale') },
  ];
  selectedTitle = null;

  languageList = [
    { id: 0, value: 'de' },
    { id: 1, value: 'en' },
    { id: 2, value: 'fr' },
    { id: 3, value: 'it' },
  ];

  foundationId;
  selectedPensionFundId;
  notChangedType = true;
  readOnlyPension = false;
  isPreviewMode = false;
  private offerId: string = this.route.snapshot.params['id'];
  searchPensionFunds$ = new Subject<KeyboardEvent>();
  private _subscriptions: Subscription[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _offersService: OffersService,
    private _translateService: TranslateService,
    private route: ActivatedRoute,
    private _router: Router,
    private _route: ActivatedRoute,
    private _intl: MatDatepickerIntl,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DATE_LOCALE) private _locale: Locale,
    private _adapter: DateAdapter<unknown>,
    private _authorizationService: AuthorizationService
  ) {
    const subs = this._translateService.onLangChange.subscribe((lang) => this.setLangForDatePicker());
    this._subscriptions.push(subs);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get foundation() {
    return this.firstStepForm.get('foundation');
  }

  get pensionFund() {
    return this.firstStepForm.get('pensionFund');
  }

  get pensionFundTypeControl() {
    return this.firstStepForm.get('pensionFundType').invalid;
  }

  get pensionFundTypeControlForPensionFunds() {
    return this.firstStepForm.get('pensionFundType').valid && this.pensionFunds.length === 0 && !this.newPensionFund;
  }

  get contractStart() {
    return this.firstStepForm.get('contractStart');
  }

  get contractEnd() {
    return this.firstStepForm.get('contractEnd');
  }

  get company() {
    return this.firstStepForm.get('company');
  }

  get city() {
    return this.firstStepForm.get('city');
  }

  get street() {
    return this.firstStepForm.get('street');
  }

  get postalCode() {
    return this.firstStepForm.get('postalCode');
  }

  get firstName() {
    return this.firstStepForm.get('firstName');
  }

  get lastName() {
    return this.firstStepForm.get('lastName');
  }

  get phone() {
    return this.firstStepForm.get('phone');
  }

  get brokerFirstName() {
    return this.firstStepForm.get('brokerFirstName');
  }

  get brokerLastName() {
    return this.firstStepForm.get('brokerLastName');
  }

  get brokerPhone() {
    return this.firstStepForm.get('brokerPhone');
  }

  get brokerEmail() {
    return this.firstStepForm.get('brokerEmail');
  }

  get brokerTitle() {
    return this.firstStepForm.get('brokerTitle');
  }

  get brokerCompanyId() {
    return this.firstStepForm.get('brokerCompanyId');
  }

  get amountIVPensioner() {
    return this.firstStepForm.get('amountIVPensioner');
  }

  get fundedStatus() {
    return this.firstStepForm.get('fundedStatus');
  }

  get sectorId() {
    return this.firstStepForm.get('sectorId');
  }

  get language() {
    return this.firstStepForm.get('language');
  }

  get email() {
    return this.firstStepForm.get('email');
  }

  get title() {
    return this.firstStepForm.get('title');
  }

  get ageGroup() {
    return this.firstStepForm.get('ageGroup');
  }

  get activeToRetiredRatio() {
    return this.firstStepForm.get('activeToRetiredRatio');
  }

  get admissionGuidelines() {
    return this.firstStepForm.get('admissionGuidelines');
  }

  get managementApproval() {
    return this.firstStepForm.get('managementApproval');
  }

  get isFoundationInvalid() {
    return isInputInvalid(this.foundation);
  }

  get isPensionFundInvalid() {
    return !(this.pensionFund.value?.length > 0);
  }

  get isFirstNameInvalid() {
    return isInputInvalid(this.firstName);
  }

  get isLastNameInvalid() {
    return isInputInvalid(this.lastName);
  }

  get isNextButtonDisabled() {
    return this.firstStepForm.invalid || (this.mode === 'preview' && !this.stepsCompleted.first);
  }
  isBrokerSelected() {
    let con = this.firstStepForm.controls;
    if (
      con['brokerTitle'].value !== null ||
      con['brokerFirstName'].value !== '' ||
      con['brokerLastName'].value !== '' ||
      con['brokerPhone'].value !== '' ||
      con['brokerEmail'].value !== '' ||
      con['brokerCompanyId'].value !== ''
    ) {
      this.brokerTitle.setValidators(Validators.required);
      this.brokerFirstName.setValidators(Validators.required);
      this.brokerLastName.setValidators(Validators.required);
      this.brokerTitle.updateValueAndValidity();
      this.brokerFirstName.updateValueAndValidity();
      this.brokerLastName.updateValueAndValidity();
    }
    if (
      con['brokerTitle'].value == null &&
      con['brokerFirstName'].value == '' &&
      con['brokerLastName'].value == '' &&
      con['brokerPhone'].value == '' &&
      con['brokerEmail'].value == '' &&
      con['brokerCompanyId'].value == ''
    ) {
      this.brokerTitle.clearValidators();
      this.brokerTitle.updateValueAndValidity();
      this.brokerLastName.clearValidators();
      this.brokerLastName.updateValueAndValidity();
      this.brokerFirstName.clearValidators();
      this.brokerFirstName.updateValueAndValidity();
    }
  }
  ngOnInit(): void {
    this._intl.changes.next();
    this.setLangForDatePicker();

    this.pensionFundType = this.offer?.pensionFund?.pensionFundType;

    const reqFoundation =
      this.mode === 'preview' && this.offer.pensionFund?.foundation?.id
        ? this._offersService.getFoundationById(this.offer.pensionFund.foundation.id)
        : this._offersService.getFoundations();

    const subs1 = zip(reqFoundation, this._offersService.getAllSectors()).subscribe({
      next: ([foundations, sectors]) => {
        this.populateFoundations(foundations);
        this.populateSectors(sectors);

        if (this.pensionFundType && this.foundations[0]?.id) {
          const subs = this._offersService
            .getAllPensionFunds(this.foundations[0].id, this.pensionFundType, '')
            .subscribe((pensionFunds) => {
              this.pensionFunds = pensionFunds;
              this.setPensionFundFormControl();
              this.populateFirstStepForm();
            });
          this._subscriptions.push(subs);
        } else {
          this.pensionFund.disable();
          this.populateFirstStepForm();
        }
      },
      error: (error) => console.error(error),
    });
    this._subscriptions.push(subs1);

    this.getFoundationId();
    const searchSub = this.searchPensionFunds$
      .pipe(
        map((event) => (event.target as HTMLInputElement).value),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((search) => {
          !this.pensionFundType ? this.pensionFund.setErrors({ type: true }) : this.pensionFund.setErrors(null);

          return this._offersService.getAllPensionFunds(this.foundationId, this.pensionFundType, search);
        })
      )
      .subscribe((pensionFunds) => {
        this.pensionFunds = pensionFunds;
        this.newPensionFund = this.pensionFunds.length === 0 && this.pensionFund.value.length > 0;
      });
    this._subscriptions.push(searchSub);

    if (this.offerId !== 'offer') {
      this.statusName = this.offer.offerStatus.status.name;
      this.isStepEditableByStatusAndMode();
    } else {
      this.newOffer = true;
      this.statusName = OfferStatusType.PENDING;
      this.isStepEditableByStatusAndMode();
    }

    const subs = this._offersService.requestToUpdateAccessToFields.subscribe((newStatus) => {
      this.statusName = newStatus;
      this.isStepEditableByStatusAndMode();
    });
    this._subscriptions.push(subs);

    let list =  JSON.parse(JSON.stringify(environment.config.texts.baseData.selectorPensionFund));
    list.forEach(e => {
      this.pensionFundOptions.push(e.toLowerCase());
    })
  }

  isStepEditableByStatusAndMode(): boolean {
    if (
      !this.newOffer &&
      (this.statusName !== 'PENDING' ||
        this.mode === 'preview' ||
        this._authorizationService.getDecodedToken().sub !== this.offer?.offerStatus?.owner.externalId)
    ) {
      this.firstStepForm.disable();
      this.readOnlyPension = true;
      this.isPreviewMode = true;
      return false;
    }
    this.isPreviewMode = false;
    this.firstStepForm.enable();

    if (this.pensionFunds.length > 0) {
      this.readOnlyPension = false;
    } else {
      this.readOnlyPension = true;
    }
    this.checkIsPensionTypeClient();

    return true;
  }

  setLangForDatePicker() {
    this._locale = this.getLangForDatePicker();
    this._adapter.setLocale(this._locale);
  }

  getLangForDatePicker() {
    switch (this._translateService.currentLang) {
      case 'en':
        return enGB;
      default:
        return de;
    }
  }

  populateFirstStepForm() {
    let broker = this.offer?.broker;
    let offerCompany = this.offer?.company;
    let offerAddress = this.offer?.company?.address;
    let startDate = new Date();
    startDate = new Date(startDate.getFullYear() + 1, 0, 1);
    let endDate = new Date();
    endDate = new Date(endDate.getFullYear() + 3, 11, 31);
    this.firstStepForm.patchValue({
      foundation: this.offer?.pensionFund?.foundation?.id
        ? this.offer?.pensionFund?.foundation?.id
        : this.foundation.value,
      pensionFund: this.offer?.pensionFund?.id ? this.offer?.pensionFund?.name : '',
      pensionFundType: this.offer?.pensionFund?.pensionFundType
        ? this.offer?.pensionFund?.pensionFundType.toLowerCase()
        : null,
      contractStart: this.offer ? new Date(this.offer?.insuranceStartDate) : startDate,
      contractEnd: this.offer ? new Date(this.offer?.validityDate) : endDate,
      company: offerCompany?.name,
      street: offerAddress?.street,
      postalCode: offerAddress?.postalCode,
      city: offerAddress?.city,
      title: offerAddress?.title ? offerAddress?.title : this.title.value,
      firstName: offerAddress?.firstName,
      lastName: offerAddress?.lastName,
      phone: offerAddress?.phone,
      email: offerAddress?.email,
      language: offerAddress?.language ? offerAddress?.language : this.language.value,
      brokerTitle: broker?.title ? broker?.title : null,
      brokerFirstName: broker?.firstName ? broker?.firstName : '',
      brokerLastName: broker?.lastName ? broker?.lastName : '',
      brokerPhone: broker?.phone ? broker?.phone : '',
      brokerEmail: broker?.email ? broker?.email : '',
      brokerCompanyId: broker?.finmaId ? broker?.finmaId : '',
      amountIVPensioner: offerCompany?.disabledPersons.toString(),
      sectorId: offerCompany?.sectorId ? offerCompany?.sectorId : this.sectorId.value,
      activeToRetiredRatio: this.offer?.checklistResponse?.activeRetiredRatio,
      ageGroup: this.offer?.checklistResponse?.ageGroup,
      admissionGuidelines: this.offer?.checklistResponse?.admissionGuidelines,
      managementApproval: this.offer?.checklistResponse?.managementApproval,
    });
    this.isBrokerSelected();
  }

  populateFoundations(foundations: IFoundation[]): void {
    this.foundations = foundations;
    if (this.foundations[0]?.id) {
      this.firstStepForm.controls['foundation'].setValue(this.foundations[0].id);
    }
  }

  populateSectors(sectors: ISector[]): void {
    this.sectors = sectors;
    if (this.sectors.length > 0) {
      this.firstStepForm.get('sectorId').setValue(this.sectors[0].id);
    } else {
      this.readOnlyPension = true;
    }
  }

  setPensionFundValue(foundationId?, pensionFundType?: string): void {
    this.pensionFundType = pensionFundType ? pensionFundType : this.pensionFundType;
    const subs = this._offersService
      .getAllPensionFunds(+foundationId, this.pensionFundType)
      .subscribe((pensionFunds) => {
        this.pensionFunds = pensionFunds;

        this.setPensionFundFormControl();
      });
    this._subscriptions.push(subs);
  }
  onPensionFundTypeChange(event) {
    this.notChangedType = false;
    this.newPensionFund = false;
    this.pensionFund.markAsDirty();
    this.pensionFundType = event.value ? event.value.toUpperCase() : '';

    if (!event.value) {
      this.pensionFund.setValue(null);
      this.pensionFund.disable();
      return;
    }
    this.pensionFund.enable();

    !this.pensionFundType ? this.pensionFund.setErrors({ type: true }) : this.pensionFund.setErrors(null);

    this._offersService
      .getAllPensionFunds(this.foundationId, this.pensionFundType)
      .subscribe((pensionFunds) => {
        this.pensionFunds = pensionFunds;

        this.setPensionFundFormControl();
        this.checkIsPensionTypeClient();
      });
  }

  checkIsPensionTypeClient() {
    if (this.pensionFundType == PensionFundTypeEN.BLPK) {
      this.readOnlyPension = true;
      const clientPFName = this.getClientPensionFundName();
      let client = this.pensionFunds.find((e) => e.name.toLowerCase() === clientPFName.toLowerCase());
      if (client) {
        this.selectedPensionFundId = client.id;
        this.pensionFund.setValue(client.name);
        this.newPensionFund = false;
      }
      this.pensionFund.disable();
    } else {
      this.pensionFund.enable();
      this.readOnlyPension = false;
    }
  }

  getClientPensionFundName() {
    if (environment.name.toLowerCase().includes(environment.config?.texts.titleShort)) {
      return 'Gemeinsames Vorsorgewerk der ' + environment.config?.texts.titleShort;
    }
    return 'Pensionfund ' + environment.config?.texts.titleShort.toUpperCase();
  }

  setPensionFundFormControl() {
    if (this.pensionFunds.length > 0 && this.isStepEditableByStatusAndMode()) {
      this.firstStepForm.controls['pensionFund'].setValue(this.pensionFunds[0].name);
      this.selectedPensionFundId = this.pensionFunds[0].id;
    } else {
      this.firstStepForm.controls['pensionFund'].setValue('');
    }
  }
  pensionFundSelected(event, selectedNameValue: string) {
    this.selectedPensionFundId = event.value;

    this.firstStepForm.controls['pensionFund'].setValue(selectedNameValue);
  }
  getFoundationId() {
    const subs = this.foundation.valueChanges.subscribe((data) => {
      if (data) {
        this.foundationId = data;
      }
    });
    this._subscriptions.push(subs);
  }

  convertDate(dateParam) {
    const diff = dateParam.getTimezoneOffset();
    return new Date(dateParam.getTime() - diff * 60000).toISOString().split('T')[0];
  }

  updateOffer() {
    this.selectedPensionFundId = this.selectedPensionFundId ? this.selectedPensionFundId : this.offer?.pensionFund?.id;
    const subs = this._offersService
      .updateOffer({
        id: this.offerId,
        pensionFund: {
          id: this.newPensionFund ? null : this.selectedPensionFundId,
          foundationId: this.foundationId ? this.foundationId : this.offer?.pensionFund?.foundation?.id,
          name: this.pensionFund.value,
          pensionFundType: this.pensionFundType ? this.pensionFundType : this.offer?.pensionFund?.pensionFundType,
        },
        insuranceStartDate: this.convertDate(this.contractStart.value),
        validityDate: this.convertDate(this.contractEnd.value),
        addressRequest: {
          type: 'MAIN',
          street: this.street.value,
          postalCode: this.postalCode.value,
          city: this.city.value,
          phone: this.phone.value,
          email: this.email.value,
          mobile: this.phone.value,
          language: this.language.value,
          title: this.title.value,
          firstName: this.firstName.value,
          lastName: this.lastName.value,
        },
        companyRequest: {
          name: this.company.value,
          sectorId: this.sectorId.value,
          disabledPersons: this.amountIVPensioner.value,
          fundedStatus: '',
        },
        checklistRequest: {
          activeRetiredRatio: this.activeToRetiredRatio.value,
          admissionGuidelines: this.admissionGuidelines.value,
          ageGroup: this.ageGroup.value,
          managementApproval: this.managementApproval.value,
        },
        brokerRequest: {
          finmaId: this.brokerCompanyId.value,
          title: parseInt(this.brokerTitle.value),
          firstName: this.brokerFirstName.value,
          lastName: this.brokerLastName.value,
          phone: this.brokerPhone.value,
          email: this.brokerEmail.value,
        },
      })
      .subscribe({
        next: (data) => {
          this.offer = data;
          this.firstStepForm.markAsPristine();
          this.pensionFund.markAsPristine();
          this.handleNextStep();
        },
        error: (err) => {
          this.nextLoading = false;
          if (err?.errorCode === 5) {
            this._snackbarService.handleOpenSnackbar(
              this._translateService.instant('cabinet.errors.baseData.sectorError'),
              this._translateService.instant('cabinet.navigation.close'),
              'error'
            );
          }
        },
      });
    this._subscriptions.push(subs);
  }

  handleFirstStepSubmit(): void {
    this.formSubmitted = true;
    if (this.firstStepForm.invalid) return;

    if (this.mode == 'preview' || (this.firstStepForm.pristine && this.pensionFund.pristine)) {
      this.handleNextStep();
      return;
    }

    this.nextLoading = true;

    if (this.newOffer) {
      const subs = this._offersService.createOffer().subscribe({
        next: (offer) => {
          this.offer = offer;
          this._offersService.offerCreated.emit(offer);
          this.offerId = offer.id.toString();
          this.updateOffer();
        },
      });
      this._subscriptions.push(subs);
    } else {
      this.updateOffer();
    }
  }

  handleNextStep() {
    this.stepper.selected.completed = true;
    this.nextLoading = false;
    this.handleUpdateStepsCompleted({
      ...this.stepsCompleted,
      first: true,
    });

    if (this.newOffer) {
      this.newOffer = false;
      this._router.navigate([`../`, this.offerId], {
        relativeTo: this._route,
      });
    }

    this.stepper.next();
  }

  handleContractTermChange({ value: contractTermSelectionValue }: MatChipListboxChange): void {
    const contractTermDuration = lastDayOfYear(
      addYears(new Date(this.contractStart.value), +contractTermSelectionValue)
    );
    this.contractEnd.setValue(contractTermDuration);
  }

  clearTitle(data) {
    this[data].setValue(null);
    if (data == 'brokerTitle') {
      this.firstStepForm.get('brokerTitle').clearValidators();
      this.firstStepForm.get('brokerTitle').updateValueAndValidity();
      this.firstStepForm.get('brokerLastName').clearValidators();
      this.firstStepForm.get('brokerLastName').updateValueAndValidity();
      this.firstStepForm.get('brokerFirstName').clearValidators();
      this.firstStepForm.get('brokerFirstName').updateValueAndValidity();
    }
  }

  clearPensionFund() {
    this.firstStepForm.get('pensionFund').setValue(null);
  }
}
