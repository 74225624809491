<form [formGroup]="firstStepForm" (submit)="handleFirstStepSubmit()">
  <fieldset>
    <legend>{{ "cabinet.offers.product" | translate }}</legend>
    <mat-form-field class="input--lg offer-form-item">
      <mat-label>{{ "cabinet.product.foundation" | translate }}</mat-label>
      <mat-select
        formControlName="foundation"
        (selectionChange)="setPensionFundValue($event.value)"
      >
        <mat-option
          *ngFor="let foundation of foundations"
          [value]="foundation.id"
          >{{ foundation.name }}</mat-option
        >
      </mat-select>
      @if (foundation.invalid) {
      <mat-error>{{ "auth.errors.foundation" | translate }}</mat-error
      >}
    </mat-form-field>

    <div class="offer-form-item">
      <div class="chip-listbox">
        <mat-chip-listbox
          aria-label="Chip list"
          (change)="onPensionFundTypeChange($event)"
          formControlName="pensionFundType"
        >
        @for (option of pensionFundOptions; track option){
          <mat-chip-option
            [disabled]="isPreviewMode"
            color="primary"
            [value]="option"
            >{{ "cabinet.offers.baseData." + option | translate }}</mat-chip-option
          >
        }

          <!-- <mat-chip-option
            color="primary"
            value="shared"
            [disabled]="isPreviewMode"
            >{{ "cabinet.offers.baseData.shared" | translate }}</mat-chip-option
          >
          <mat-chip-option
            [disabled]="isPreviewMode"
            color="primary"
            [value]="environment.config?.texts.titleShort"
            >{{environment.config?.texts.titleShort}}</mat-chip-option
          > -->
        </mat-chip-listbox>
        <div class="listbox-errors">
          <mat-error *ngIf="pensionFundTypeControl">{{
            "auth.errors.pensionFundTypeMissing" | translate
          }}</mat-error>
          <mat-error *ngIf="pensionFundTypeControlForPensionFunds">{{
            "auth.errors.pensionFundMissingForType" | translate
          }}</mat-error>
        </div>
      </div>
    </div>
    <!-- <mat-form-field class="input--lg offer-form-item">
      <mat-label>{{ "cabinet.product.pensionFund" | translate }}</mat-label>
      <mat-select formControlName="pensionFund">
        @for (pensionFund of pensionFunds; track pensionFund.id) {
        <mat-option [value]="pensionFund.id">{{ pensionFund.name }}</mat-option>
        }
      </mat-select>
      @if (firstStepForm.get('pensionFund').hasError('required')) {
      <mat-error>{{ "auth.errors.pensionFundMissing" | translate }}</mat-error
      >}
    </mat-form-field> -->
    <mat-form-field class="input--lg offer-form-item">
      <mat-label>{{ "cabinet.product.pensionFund" | translate }}</mat-label>
      <input
        type="text"
        aria-label="Number"
        matInput
        [readonly]="readOnlyPension"
        formControlName="pensionFund"
        (keyup)="searchPensionFunds$.next($event)"
        [matAutocomplete]="auto"
      />
      <button
        mat-icon-button
        matSuffix
        aria-label="Delete"
        *ngIf="!readOnlyPension"
        (click)="clearPensionFund()"
      >
        <mat-icon>clear</mat-icon>
      </button>
      <mat-hint align="start" class="pension-info" *ngIf="newPensionFund"
        >{{ "cabinet.product.newPensionFundWillBeCreated" | translate }}
        <mat-icon>info</mat-icon></mat-hint
      >
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="
          pensionFundSelected($event.option, $event.option.viewValue)
        "
      >
        @for (pensionFund of pensionFunds; track pensionFund.id) {
        <mat-option [value]="pensionFund.id">{{ pensionFund.name }}</mat-option>
        }
      </mat-autocomplete>
      @if (firstStepForm.get('pensionFund').hasError('required')) {
      <mat-error>{{ "auth.errors.pensionFundMissing" | translate }}</mat-error
      >}
      <mat-error *ngIf="!pensionFundType">{{
        "cabinet.offers.baseData.noPensionType" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.product.contractStart" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker1"
        formControlName="contractStart"
        min="2023-01-01"
      />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 color="primary"></mat-datepicker>
      <mat-error *ngIf="contractStart.invalid">{{
        "auth.errors.contractStart" | translate
      }}</mat-error>
    </mat-form-field>
    <div class="offer-form-item">
      <span>{{ "cabinet.profile.buttons.contractTerm" | translate }}</span>
      <mat-chip-listbox
        aria-label="Fish selection"
        (change)="handleContractTermChange($event)"
        [disabled]="firstStepForm.disabled"
      >
        <mat-chip-option color="primary" value="4">{{
          "cabinet.profile.buttons.add5Years" | translate
        }}</mat-chip-option>
        <mat-chip-option color="accent" value="2">{{
          "cabinet.profile.buttons.add3Years" | translate
        }}</mat-chip-option>
      </mat-chip-listbox>
    </div>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.product.contractEnd" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker2"
        formControlName="contractEnd"
        min="2023-01-02"
      />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2 color="primary"></mat-datepicker>
      <mat-error *ngIf="contractEnd.invalid">{{
        "auth.errors.contractEnd" | translate
      }}</mat-error>
    </mat-form-field>
  </fieldset>
  <fieldset>
    <legend>{{ "cabinet.profile.personal.clientData" | translate }}</legend>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.tableFields.company" | translate }}</mat-label>
      <input matInput formControlName="company" type="text" />
      <mat-error *ngIf="company.invalid">{{
        "auth.errors.company" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.profile.personal.street" | translate }}</mat-label>
      <input matInput formControlName="street" type="text" />
      <mat-error *ngIf="street.invalid">{{
        "auth.errors.street" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.postalCode" | translate
      }}</mat-label>
      <input matInput formControlName="postalCode" type="text" />
      <mat-error *ngIf="postalCode.invalid">{{
        "auth.errors.postalCode" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.profile.personal.city" | translate }}</mat-label>
      <input matInput id="city" formControlName="city" type="text" />
      <mat-error *ngIf="city.invalid">{{
        "auth.errors.city" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.salutation" | translate
      }}</mat-label>
      <mat-select ngDefaultControl formControlName="title">
        <mat-option *ngFor="let title of titleList" [value]="title.id">
          {{ title.value }}
        </mat-option>
      </mat-select>
      <button
        mat-icon-button
        matSuffix
        aria-label="Delete"
        *ngIf="title.value && !readOnlyPension"
        (click)="clearTitle('title')"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="input--lg" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.firstName" | translate
      }}</mat-label>
      <input matInput formControlName="firstName" />
      @if (firstName.invalid) {
      <mat-error>{{ "auth.errors.firstName" | translate }}</mat-error
      >}
    </mat-form-field>
    <mat-form-field class="input--lg" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.lastName" | translate
      }}</mat-label>
      <input matInput formControlName="lastName" />
      @if (lastName.invalid) {
      <mat-error>{{ "auth.errors.lastName" | translate }}</mat-error
      >}
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.profile.company.phone" | translate }}</mat-label>
      <input matInput formControlName="phone" type="text" />
      <mat-error *ngIf="phone.invalid">{{
        "auth.errors.phone" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.profile.company.email" | translate }}</mat-label>
      <input matInput formControlName="email" type="text" />
      <mat-error *ngIf="email.invalid">{{
        "auth.errors.email.incorrect" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.language" | translate
      }}</mat-label>
      <mat-select ngDefaultControl formControlName="language" required>
        <mat-option *ngFor="let lang of languageList" [value]="lang.id">
          {{ "cabinet.profile.personal.languages." + lang.value | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="language.invalid">{{
        "auth.errors.language" | translate
      }}</mat-error>
    </mat-form-field>
  </fieldset>
  <fieldset>
    <legend>{{ "cabinet.offers.broker" | translate }}</legend>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.salutation" | translate
      }}</mat-label>
      <mat-select
        ngDefaultControl
        formControlName="brokerTitle"
        (selectionChange)="isBrokerSelected()"
      >
        <mat-option
          *ngFor="let brokerTitle of titleList"
          [value]="brokerTitle.id"
        >
          {{ brokerTitle.value }}
        </mat-option>
      </mat-select>
      <button
        mat-icon-button
        matSuffix
        aria-label="Delete"
        *ngIf="brokerTitle.value && !readOnlyPension"
        (click)="clearTitle('brokerTitle')"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="input--lg" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.firstName" | translate
      }}</mat-label>
      <input
        matInput
        formControlName="brokerFirstName"
        (input)="isBrokerSelected()"
      />
      @if (brokerFirstName.invalid) {
      <mat-error>{{ "auth.errors.firstName" | translate }}</mat-error
      >}
    </mat-form-field>
    <mat-form-field class="input--lg" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.lastName" | translate
      }}</mat-label>
      <input
        matInput
        formControlName="brokerLastName"
        (input)="isBrokerSelected()"
      />
      @if (brokerLastName.invalid) {
      <mat-error>{{ "auth.errors.lastName" | translate }}</mat-error
      >}
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.profile.company.phone" | translate }}</mat-label>
      <input
        matInput
        formControlName="brokerPhone"
        type="text"
        (input)="isBrokerSelected()"
      />
      <mat-error *ngIf="phone.invalid">{{
        "auth.errors.phone" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{ "cabinet.profile.company.email" | translate }}</mat-label>
      <input
        matInput
        formControlName="brokerEmail"
        type="text"
        (input)="isBrokerSelected()"
      />
      <mat-error *ngIf="brokerEmail.invalid">{{
        "auth.errors.email.incorrect" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.company.companyId" | translate
      }}</mat-label>
      <input
        matInput
        formControlName="brokerCompanyId"
        type="text"
        (input)="isBrokerSelected()"
      />
      <mat-error *ngIf="email.invalid">{{
        "auth.errors.email.incorrect" | translate
      }}</mat-error>
    </mat-form-field>
  </fieldset>
  <fieldset>
    <legend>
      {{ "cabinet.profile.personal.additionalInformation" | translate }}
    </legend>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.amountIVPensioner" | translate
      }}</mat-label>
      <input matInput formControlName="amountIVPensioner" type="number" />
      <mat-error
        *ngIf="amountIVPensioner.errors && amountIVPensioner.errors['required']"
        >{{ "auth.errors.amountIVPensioner" | translate }}</mat-error
      >
      <mat-error
        *ngIf="amountIVPensioner.errors && amountIVPensioner.errors['pattern']"
        >{{ "cabinet.fileupload.errorMessages.onlyNumbersAllowed" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.coverCapital" | translate
      }}</mat-label>
      <input matInput formControlName="fundedStatus" type="text" />
      <mat-error
        *ngIf="fundedStatus.errors && fundedStatus.errors['required']"
        >{{ "auth.errors.cantBeEmpty" | translate }}</mat-error
      >
      <mat-error
        *ngIf="fundedStatus.errors && fundedStatus.errors['notANumber']"
        >{{
          "cabinet.fileupload.errorMessages.onlyNumbersAllowed" | translate
        }}</mat-error
      >
      <mat-error
        *ngIf="
          fundedStatus.errors &&
          fundedStatus.errors['pattern'] &&
          !fundedStatus.errors['notANumber']
        "
        >{{
          "cabinet.fileupload.errorMessages.notMoreThan1Fraction" | translate
        }}</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline" class="offer-form-item">
      <mat-label>{{
        "cabinet.profile.personal.industrySector" | translate
      }}</mat-label>
      <mat-select formControlName="sectorId">
        <mat-option *ngFor="let sector of sectors" [value]="sector.id">
          {{ sector.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="sectorId.hasError('required')">{{
        "auth.errors.sector" | translate
      }}</mat-error>
    </mat-form-field>
    <div class="offer-form-checklist">
      <p>{{ "cabinet.profile.personal.checklistTitle" | translate }}</p>
      <mat-checkbox formControlName="ageGroup" color="primary">
        <span
          matTooltip="{{
            'cabinet.profile.personal.checkboxHoverTexts.ageGroup' | translate
          }}"
          matTooltipPosition="above"
        >
          {{ "cabinet.profile.personal.ageStructure" | translate }} *
        </span>
      </mat-checkbox>
      <mat-checkbox formControlName="activeToRetiredRatio" color="primary">
        <span
          matTooltip="{{
            'cabinet.profile.personal.checkboxHoverTexts.activeToRetiredRatio'
              | translate
          }}"
          matTooltipPosition="above"
        >
          {{ "cabinet.profile.personal.ratioActiveToPensioner" | translate }} *
        </span>
      </mat-checkbox>
      <mat-checkbox formControlName="admissionGuidelines" color="primary">
        <span
          matTooltip="{{
            'cabinet.profile.personal.checkboxHoverTexts.admissionGuidelines'
              | translate
          }}"
          matTooltipPosition="above"
        >
          {{ "cabinet.profile.personal.isClientStatutesCompliant" | translate }}
          *
        </span>
      </mat-checkbox>
      <mat-checkbox formControlName="managementApproval" color="primary">
        <span
          matTooltip="{{
            'cabinet.profile.personal.checkboxHoverTexts.managementApproval'
              | translate
          }}"
          matTooltipPosition="above"
        >
          <b
            >{{
              "cabinet.profile.personal.hasManagementApproved" | translate
            }}
            *</b
          >
        </span>
      </mat-checkbox>
      <mat-error
        *ngIf="
          (ageGroup.invalid ||
            activeToRetiredRatio.invalid ||
            admissionGuidelines.invalid ||
            managementApproval.invalid) &&
          (ageGroup.touched ||
            activeToRetiredRatio.touched ||
            admissionGuidelines.touched ||
            managementApproval.touched ||
            formSubmitted)
        "
      >
        {{
          "cabinet.profile.personal.checklistErrorText" | translate
        }}</mat-error
      >
    </div>
  </fieldset>
  <next-back-button
    [nextLoading]="nextLoading"
    [hideBackButton]="hideBackButton"
  ></next-back-button>
</form>
