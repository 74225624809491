import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { Locale, format } from 'date-fns';
import { IFilterOffers, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { enGB, fr } from 'date-fns/locale';
import { MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MY_FORMATS } from '../../offers-step-basic-data/offers-step-basic-data.component';
import { OffersService } from '@core/services';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-search-offers',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    TranslateModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(MY_FORMATS),
  ],
  templateUrl: './search-offers.component.html',
  styleUrl: './search-offers.component.scss',
})
export class SearchOffersComponent implements OnInit {
  @Output() searchValue = new EventEmitter<IFilterOffers>();
  @Input() statusFromDashboard: string;
  searchByOfferName: FormControl = new FormControl();
  searchByOwnerName: FormControl = new FormControl();
  searchByReviewerName: FormControl = new FormControl();
  searchByStartDate: FormControl = new FormControl();
  searchByEndDate: FormControl = new FormControl();
  searchByCompanyName: FormControl = new FormControl();
  searchByStatus: FormControl = new FormControl();
  offerStatuses = Object.values(OfferStatusType);
  constructor(
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: Locale,
    private _adapter: DateAdapter<unknown>,
    private _offersService: OffersService,
    private _router: Router
  ) {}
  setupSearch = (control: FormControl): void => {
    control.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.removeQueryParams();
      this.emitSearchValues();
    });
  };
  emitSearchValues(): void {
    const startDate = this.searchByStartDate.value;
    const endDate = this.searchByEndDate.value;
    this.searchValue.emit({
      offerName: this.searchByOfferName.value?.toLowerCase(),
      ownerName: this.searchByOwnerName.value?.toLowerCase(),
      reviewerName: this.searchByReviewerName.value?.toLowerCase(),
      startDate: startDate ? format(new Date(startDate), 'dd.MM.yyyy') : '',
      endDate: endDate ? format(new Date(endDate), 'dd.MM.yyyy') : '',
      companyName: this.searchByCompanyName.value?.toLowerCase(),
      status: this.searchByStatus.value?.toUpperCase(),
    });
  }
  removeQueryParams() {
    this._router.navigate([], {
      queryParams: {
        status: null,
      },
      queryParamsHandling: 'merge',
    });
  }
  resetFilters() {
    this.searchByOfferName.setValue(null);
    this.searchByOwnerName.setValue(null);
    this.searchByReviewerName.setValue(null);
    this.searchByStartDate.setValue(null);
    this.searchByEndDate.setValue(null);
    this.searchByCompanyName.setValue(null);
    this.searchByStatus.setValue(null);
    this.removeQueryParams();
  }
  setupSearchForOfferName(): void {
    this.setupSearch(this.searchByOfferName);
  }
  setupSearchForOwnerName(): void {
    this.setupSearch(this.searchByOwnerName);
  }
  setupSearchForReviewerName(): void {
    this.setupSearch(this.searchByReviewerName);
  }
  setupSearchForStartDate(): void {
    this.setupSearch(this.searchByStartDate);
  }
  setupSearchForEndDate(): void {
    this.setupSearch(this.searchByEndDate);
  }
  setupSearchForCompanyName(): void {
    this.setupSearch(this.searchByCompanyName);
  }
  setupSearchForStatus(): void {
    if (this.statusFromDashboard) {
      this.searchByStatus.setValue(this.statusFromDashboard.toUpperCase());
      this.emitSearchValues();
    }
    this.setupSearch(this.searchByStatus);
  }

  ngOnInit(): void {
    this.setupSearchForOfferName();
    this.setupSearchForOwnerName();
    this.setupSearchForReviewerName();
    this.setupSearchForStartDate();
    this.setupSearchForEndDate();
    this.setupSearchForCompanyName();
    this.setupSearchForStatus();
    this._adapter.setLocale(this._locale);
    this._intl.changes.next();
  }
}
