import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthModule } from '@core/auth/auth.module';
import { DirectoryService } from 'clan-auth';
import { NavigationComponent } from '@shared/components';
import { RouterModule } from '@angular/router';
import { environment } from '@environment/environment';
import { CookieService } from 'ngx-cookie-service';
import { LocaleService } from 'models';
import { Locale } from '@core/enums/locale.enum';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import {EnvironmentBannerComponent} from "@shared/components/environment-banner/environment-banner.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, NavigationComponent, AuthModule, RouterModule, EnvironmentBannerComponent],
  providers: [{ provide: DirectoryService }],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  token: string;
  roles: any;

  constructor(
    private cookieService: CookieService,
    private localeService: LocaleService,
    private authorizationService: AuthorizationService
  ) {}
  ngOnInit() {
    this.authorizationService.getIsAuthenticated().subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
    let language = environment.localeData.defaultLocale as Locale;
    if (
      this.cookieService.get('lang') &&
      environment.localeData.locales.includes(this.cookieService.get('lang') as Locale)
    ) {
      language = this.cookieService.get('lang') as Locale;
    }
    this.localeService.initTranslate(language);
  }
}
