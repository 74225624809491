<div class="export-container">
  <section class="list">
    <div class="export-notification">
      <h2>
        @if(statusName === 'REJECTED') {
          {{
            "cabinet.offers.exportStep.rejectedNotification"
              | translate : { status: statusName }
          }}
        }
        @if(statusName === 'EXPORTED') {
          {{
            "cabinet.offers.exportStep.exportNotification"
              | translate : { status: statusName }
          }}
        }
        @if(statusName === 'ACCEPTED') {
          {{
            "cabinet.offers.exportStep.acceptedNotification"
              | translate : { status: statusName }
          }}
        }
      </h2>
    </div>
    <div class="list-title">
      <h2>{{ "cabinet.offers.exportStep.overview" | translate }}</h2>
    </div>
    <div class="list-wrap">
      <div class="list-data">
        <p class="list-data_text">{{ "cabinet.offers.company" | translate }}</p>
        <p class="list-data_text">{{ offer.company.name }}</p>
      </div>
      <mat-divider></mat-divider>
      <div class="list-data">
        <p class="list-data_text">{{ "cabinet.tableFields.offerName" | translate }}</p>
        <p class="list-data_text">{{ offer.name }}</p>
      </div>
      <mat-divider></mat-divider>
      <div class="list-data">
        <p class="list-data_text">
          {{ "cabinet.offers.exportStep.address" | translate }}
        </p>
        <p class="list-data_text">
          {{ offer.company.address.street }},
          {{ offer.company.address.postalCode }},
          {{ offer.company.address.country }}
        </p>
      </div>
      <mat-divider></mat-divider>
      <div class="list-data">
        <p class="list-data_text">
          {{ "cabinet.offers.exportStep.externalId" | translate }}
        </p>
        <p class="list-data_text">{{ offer.company.externalId }}</p>
      </div>
    </div>

    <div class="fieldset-wrap">
      <div class="fieldset">
        <div class="fieldset__title_wrap">
          <p class="title">
            {{ "auth.userManagement.clientFeedback" | translate }}
          </p>
        </div>
        <div class="form-groups">
          <mat-form-field>
            <textarea
              matInput
              rows="5"
              [formControl]="remarkControl"
            ></textarea>
            <mat-error *ngIf="remarkControl.hasError('required')">
              {{ "auth.errors.fieldRequired" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </div>
    <next-back-button
      (nextButton)="handleStatus(nextButtonId)"
      (backButton)="handleBackStep()"
      (middleButton)="handleStatus(middleButtonId)"
      [hideMiddleButton]="hideMiddleButton"
      [middleButtonText]="middleButtonText"
      [nextButtonText]="nextButtonText"
      [nextLoading]="nextLoading"
      [styleType]="styleType"
      [hiddenIcon]="hiddenIcon"
      [isNextButtonDisabled]="nextLoading"
      [isMiddleButtonDisabled]="nextLoading"
    ></next-back-button>
  </section>
</div>
