<div class="users-container">
  <div>
    <h2 class="main-title">{{ "cabinet.profile.title" | translate }} - {{ "cabinet.profile.newUser" | translate }}</h2>
  </div>
  <hr />
  <form
    [formGroup]="userForm"
    (submit)="handleCreateUser()"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="fieldset">
      <div class="fieldset__title_wrap">
        <p class="title">
          {{ "auth.userManagement.personalInformation" | translate }}
        </p>
      </div>
      <div class="form-groups">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "auth.userManagement.firstName" | translate
          }}</mat-label>
          <input type="text" matInput formControlName="firstName" />
          <mat-error
            *ngIf="firstName.touched && firstName.hasError('required')"
          >
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            "auth.userManagement.lastName" | translate
          }}</mat-label>
          <input type="text" matInput formControlName="lastName" />
          <mat-error *ngIf="lastName.touched && lastName.hasError('required')">
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ "auth.userManagement.phone" | translate }}</mat-label>
          <input type="text" matInput formControlName="phone" />
          <mat-error *ngIf="phone.touched && phone.hasError('required')">
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "auth.userManagement.address" | translate }}</mat-label>
          <input type="text" matInput formControlName="address" />
          <mat-error *ngIf="address.touched && address.hasError('required')">
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            "auth.userManagement.position" | translate
          }}</mat-label>
          <input type="text" matInput formControlName="position" />
          <mat-error *ngIf="position.touched && position.hasError('required')">
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="fieldset">
      <div class="fieldset__title_wrap"><p class="title">Email</p></div>
      <div class="form-groups">
        <mat-form-field appearance="outline">
          <mat-label>{{ "auth.userManagement.email" | translate }}</mat-label>
          <input type="text" matInput formControlName="email" />
          <mat-error *ngIf="email.touched && email.hasError('required')">
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="email.touched && email.errors?.['email']">
            {{ "auth.errors.notCorrectEmail" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="fieldset">
      <div class="fieldset__title_wrap">
        <p class="title">{{ "auth.userManagement.password" | translate }}</p>
      </div>
      <div class="form-groups">
        <mat-form-field appearance="outline" class="password-input">
          <mat-label>{{
            "auth.userManagement.passwordLabel" | translate
          }}</mat-label>
          <input
            matInput
            [type]="typePassword"
            formControlName="password"
            [required]="mode === 'new'"
            id="userPasswordId"
          />
          @if(typePassword == 'password'){
          <mat-icon (click)="toggleVisibility('password', 'passwordControl')"
            >visibility_off</mat-icon
          >}@else {
          <mat-icon (click)="toggleVisibility('text', 'passwordControl')"
            >visibility</mat-icon
          >
          }
          <mat-error
            *ngIf="password.touched && password.errors?.['containsFirstName']"
          >
            {{ "auth.errors.passwordContainsFirstName" | translate }}
          </mat-error>
          <mat-error
            *ngIf="password.touched && password?.errors?.['containsLastName']"
          >
            {{ "auth.errors.passwordContainsLastName" | translate }}
          </mat-error>
          <mat-error
            *ngIf="password.touched && password?.errors?.['containsEmail']"
          >
            {{ "auth.errors.passwordContainsEmail" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              password.touched &&
              !password?.value?.toString().match('^(?=.*[A-Z])')
            "
          >
            {{ "auth.errors.passwordOneUppercaseError" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              password.touched &&
              !password?.value?.toString().match('(?=.*[a-z])')
            "
          >
            {{ "auth.errors.passwordOneLowercaseError" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              password.touched &&
              !password?.value?.toString().match('(?=.*[!@#$%^&*])')
            "
          >
            {{ "auth.errors.passwordOneSpecialCharError" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              password.touched && !password?.value?.toString().match('.{8,}')
            "
          >
            {{ "auth.errors.passwordEightCharLongError" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              password.touched &&
              !password?.value?.toString().match('(.*[0-9].*)')
            "
          >
            {{ "auth.errors.passwordOneDigitError" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="password-input">
          <mat-label>{{
            "auth.userManagement.confirmPasswordLabel" | translate
          }}</mat-label>
          <input
            matInput
            [type]="typeConfirmPassword"
            formControlName="confirmPassword"
            id="userConfirmPasswordId"
          />
          @if(typeConfirmPassword == 'password'){
          <mat-icon
            (click)="toggleVisibility('password', 'confirmPasswordControl')"
            >visibility_off</mat-icon
          >}@else {
          <mat-icon (click)="toggleVisibility('text', 'confirmPasswordControl')"
            >visibility</mat-icon
          >
          }
          <mat-error
            *ngIf="
              confirmPassword.touched && confirmPassword.hasError('required')
            "
          >
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="confirmPassword.errors?.['confirmedValidator']">
            {{ "auth.errors.passwordNotMatched" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="fieldset">
      <div class="fieldset__title_wrap">
        <p class="title">{{ "auth.userManagement.roles" | translate }}</p>
      </div>
      <section class="role-section">
        <mat-form-field appearance="outline">
          <mat-label>{{ "auth.userManagement.roles" | translate }}</mat-label>
          @if(mode ==='edit'){
          <mat-select formControlName="role" multiple>
            @for (role of roles; track role.id) {
            <mat-option [value]="role.id">{{
              changeRoleName(role.name) | translate
            }}</mat-option>
            }
          </mat-select>
          }@else{
          <mat-select formControlName="role" multiple>
            @for (role of roles; track role.id) {
            <mat-option [value]="role">{{
              changeRoleName(role.name) | translate
            }}</mat-option>
            }
          </mat-select>
          }
          <mat-error *ngIf="role.touched && role.hasError('required')">
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>
      </section>
    </div>
    <div class="foundation-wrap">
      <div class="foundation-wrap__title_wrap">
        <p class="title">{{ "auth.userManagement.foundations" | translate }}</p>
      </div>
      <div class="form-groups">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "auth.userManagement.foundations" | translate
          }}</mat-label>
          @if(mode === 'edit'){
          <mat-select formControlName="foundation" multiple>
            @for (foundation of foundations; track foundation.id) {
            <mat-option [value]="foundation.id">{{
              foundation.name
            }}</mat-option>
            }
          </mat-select>
          }@else{
          <mat-select formControlName="foundation" multiple>
            @for (foundation of foundations; track foundation.id) {
            <mat-option [value]="foundation">{{ foundation.name }}</mat-option>
            }
          </mat-select>
          }
          <mat-error
            *ngIf="foundation.touched && foundation.hasError('required')"
          >
            {{ "auth.errors.fieldRequired" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <next-back-button
      backButtonText="auth.userManagement.close"
      (backButton)="returnToUsersList()"
      nextButtonText="auth.userManagement.save"
      [nextLoading]="saveLoading"
    ></next-back-button>
  </form>
</div>
