<div class="search">
  <div class="search_controls">
    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label>{{ "cabinet.tableFields.offerName" | translate }}</mat-label>
      <input
        matInput
        [formControl]="searchByOfferName"
        placeholder="{{ 'cabinet.tableFields.offerName' | translate }}"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label>{{ "cabinet.tableFields.ownerName" | translate }}</mat-label>
      <input
        matInput
        [formControl]="searchByOwnerName"
        placeholder="{{ 'cabinet.tableFields.ownerName' | translate }}"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label>{{
        "cabinet.tableFields.reviewerName" | translate
      }}</mat-label>
      <input
        matInput
        [formControl]="searchByReviewerName"
        placeholder="{{ 'cabinet.tableFields.reviewerName' | translate }}"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label>{{ "cabinet.tableFields.startDate" | translate }}</mat-label>
      <input
        matInput
        [formControl]="searchByStartDate"
        [matDatepicker]="startDatePicker"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label>{{ "cabinet.tableFields.endDate" | translate }}</mat-label>
      <input
        matInput
        [formControl]="searchByEndDate"
        [matDatepicker]="endDatePicker"
      />

      <mat-datepicker-toggle
        matIconSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label
        >{{ "cabinet.tableFields.companyName" | translate }}
      </mat-label>
      <input
        matInput
        [formControl]="searchByCompanyName"
        placeholder="{{ 'cabinet.tableFields.companyName' | translate }}"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="custom-outline">
      <mat-label>{{ "cabinet.tableFields.status" | translate }} </mat-label>
      <mat-select ngDefaultControl [formControl]="searchByStatus">
        @for (status of offerStatuses; track status) {
        <mat-option [value]="status">
          {{ "cabinet.offers.offerStatus." + status | translate }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button class="reset-filters" (click)="resetFilters()">
      {{ "cabinet.tableFields.resetFilters" | translate }}
    </button>
  </div>
</div>
