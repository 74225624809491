@if(!tableLoading) {
<div class="mat-elevation-z8 benefits-table">
    <table mat-table class="full-width-table" matSort aria-label="Elements" [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('fullName')">
                {{ 'cabinet.tableFields.names' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.firstName }} {{ row?.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="birthday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('dateOfBirth')">
                {{ 'cabinet.tableFields.birthday' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.dateOfBirth | date : 'dd.MM.yyyy' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('sex')">
                {{ 'cabinet.tableFields.sex' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ 'cabinet.profile.personal.genderCode.' + row.sex | translate }}
            </td>
        </ng-container>
        <ng-container matColumnDef="maritalStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('maritalStatus')">
                {{ 'cabinet.tableFields.martialStatus' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ 'cabinet.profile.personal.maritalStatusCode.' + row?.maritalStatus | translate }}
            </td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('collective')">
                {{ 'cabinet.tableFields.category' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.collective }}</td>
        </ng-container>
        <ng-container matColumnDef="savingPlan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('savingPlan')">
                {{ 'cabinet.tableFields.savingPlan' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.savingPlan }}</td>
        </ng-container>
        <ng-container matColumnDef="bvgAge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('bvgAge')">
                {{ 'cabinet.tableFields.bvgAge' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.bvgAge }}</td>
        </ng-container>
        <ng-container matColumnDef="degreeOfEmployment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('levelOfEmployment')">
                {{ 'cabinet.tableFields.degreeEmployment' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.levelOfEmployment }}</td>
        </ng-container>
        <ng-container matColumnDef="grossSalary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('grossSalary')">
                {{ 'cabinet.tableFields.grossSalary' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.grossSalary | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="insuredSalary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('insuredSalary')">
                {{ 'cabinet.tableFields.insuredSalary' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.insuredSalary | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="disabilityPension">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('disabilityPension')">
                {{ 'cabinet.offers.plan.calculationResults.disabilityPensions' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.disabilityPension | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="spousePension">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('spousePension')">
                {{ 'cabinet.home.infoField.spousePension' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.spousePension | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="orphansPension">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('orphansPension')">
                {{ 'cabinet.home.infoField.orphansPension' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.orphansPension | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="oldAgePension">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('oldAgePension')">
                {{ 'cabinet.home.infoField.oldAgePension' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.oldAgePension | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="childsPension">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('childPension')">
                {{ 'cabinet.home.infoField.childPensions' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.childPension | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="projectedRetirementAssets">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                (click)="handleSortChange('projectedRetirementAssets')"
            >
                {{ 'cabinet.home.infoField.projectedRetirementAssets' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row?.projectedRetirementAssets | money }}
            </td>
        </ng-container>
        <ng-container matColumnDef="savingAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('savingAmount')">
                {{ 'cabinet.home.infoField.savingAmount' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.savingAmount | money }}</td>
        </ng-container>
        <ng-container matColumnDef="savingAmountBvg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="handleSortChange('savingAmountBvg')">
                {{ 'cabinet.home.infoField.savingAmountBVG' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.savingAmountBvg | money }}</td>
        </ng-container>

        @if(totalElements === 0){
        <ng-container>
            <tr class="mat-row empty-table" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    <p class="empty-table_message">
                        {{ 'cabinet.tableFields.noData' | translate }}
                    </p>
                </td>
            </tr>
        </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
        #paginator
        [length]="totalElements"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[5, 10, 20]"
        (page)="handlePageEvent($event)"
        aria-label="Select page"
    >
    </mat-paginator>
</div>
} @else {
<div class="spinner-box">
    <mat-spinner diameter="90" style="margin: 0 auto"></mat-spinner>
</div>
}
