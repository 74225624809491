export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  DISSOLVED_PARTNERSHIP = 'DISSOLVED_PARTNERSHIP',
  REGISTERED_PARTNERSHIP = 'REGISTERED_PARTNERSHIP',
  UNKNOWN = 'UNKNOWN',
}
