import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'clan-auth';
import { AuthorizationService } from '@core/services/authorization/authorization.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authorizationService.setIsAuthenticated(this.authenticationService.isValid());

    return next.handle(request);
  }
}
