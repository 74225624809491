import { BreakpointObserver } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule, StepperOrientation } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AsyncPipe } from '@angular/common';
import { DialogService, OffersService } from '@core/services';
import {
  IOffersData,
  THandleUpdateOffer,
  THandleUpdateOffersData,
  THandleUpdateStepper,
  THandleUpdateStepsCompleted,
  TStepperMode,
  TStepsCompleted,
} from '@core/types';
import { ConfirmationDialogComponent } from '@shared/components';
import { OffersStepInsuredComponent } from '../../components/offers-step-insured/offers-step-insured.component';
import { OffersStepBasicDataComponent } from '../../components/offers-step-basic-data/offers-step-basic-data.component';
import { OffersStepGenerateDocumentsComponent } from '../../components/offers-step-generate-documents/offers-step-generate-documents.component';
import { OffersStepReviewerComponent } from '../../components/offers-step-reviewer/offers-step-reviewer.component';
import { OffersStepFinalDocumentsComponent } from '../../components/offers-step-final-documents/offers-step-final-documents.component';
import { Locale, LocaleService } from 'models';
import { IDocument } from '@core/interfaces/documents/document.interface';
import { OffersStepCalculationComponent } from '../../components/offers-step-calculation/offers-step-calculation.component';
import { OffersProductComponent } from '../../components/offers-product/offers-product.component';
import { Subscription, forkJoin } from 'rxjs';
import { IOffer, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { OffersStepExportComponent } from '../../components/offers-step-export/offers-step-export.component';
import { environment } from '@environment/environment';
@Component({
  selector: 'app-create-offer',
  standalone: true,
  imports: [
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    AsyncPipe,
    OffersStepBasicDataComponent,
    OffersStepInsuredComponent,
    OffersStepGenerateDocumentsComponent,
    OffersStepReviewerComponent,
    OffersStepFinalDocumentsComponent,
    OffersStepCalculationComponent,
    OffersProductComponent,
    OffersStepExportComponent,
    TranslateModule,
  ],
  templateUrl: './create-offer.component.html',
  styleUrl: './create-offer.component.css',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CreateOfferComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  @Input() id!: number;
  @Input() mode!: TStepperMode;
  @Input() completionMessage!: string;
  @Input() offer!: IOffer;
  stepsCompleted: TStepsCompleted = {
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
    seventh: false,
    eight: false,
  };
  currentLocale: Locale;
  stepNextLoading = false;
  stepBackLoading = false;
  statusName: OfferStatusType;
  private _subscriptions: Subscription[] = [];
  stepperOrientation: Observable<StepperOrientation>;
  private _root: HTMLElement;
  fullScreen = false;
  isEditable = false;
  newOffer = false;
  private offerId = this._route.snapshot.params['id'];
  private stepGenerateDocuments = 'cabinet.offers.stepGenerateDocuments';
  private typePDF = 'application/pdf';
  private typeDOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  documentList: IDocument[] = [];

  handleUpdateOffer: THandleUpdateOffer = (updatedOffer) => {
    this.offer = updatedOffer;
  };

  handleUpdateStepsCompleted: THandleUpdateStepsCompleted = (updatedStepsCompleted) => {
    this.stepsCompleted = updatedStepsCompleted;
  };

  constructor(
    private _router: Router,
    breakpointObserver: BreakpointObserver,
    private _dialogService: DialogService,
    private localeService: LocaleService,
    private _route: ActivatedRoute,
    private _offersService: OffersService,
    private _translateService: TranslateService,
    private _authorizationService: AuthorizationService
  ) {
    
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this._root = document.documentElement;
    this.localeService.$currentLocale.subscribe((locale) => {
      this.currentLocale = locale;
    });
  }
  
  filterDocumentConfiguration() {
    const foundationName = this.offer?.pensionFund.foundation.name;
    let documents:any = Object.assign({}, environment.config?.documentConfiguration);
    let documentResponse = JSON.parse(JSON.stringify(documents["default"].config))

    if(documents[foundationName]) {
        const foundationConfig = documents[foundationName];
        if(!foundationConfig.showDefault) {
            return foundationConfig.config;
        }

        foundationConfig.config.forEach(e => documentResponse.push(e))
    }
    return documentResponse;
  } 

  resyncDocuments() {

    let documents:any = this.filterDocumentConfiguration();
    this.documentList = []
    Object.values(documents).forEach(docConfig => {
        let docConfigCopy:any = Object.assign({}, docConfig);
        
        docConfigCopy.title = this._translateService.instant(docConfigCopy.title);
        let types = [];
        docConfigCopy.types.forEach(type => {
          let typeCopy:any = Object.assign({}, type);
          typeCopy.title = this._translateService.instant(typeCopy.title);
          types.push(typeCopy)
        })
        docConfigCopy.types = types;
        
        this.documentList.push(docConfigCopy)
    })
  }

  ngOnInit(): void {
    this.statusName = OfferStatusType.PENDING;

    if (this.offerId !== 'offer') {
      this.updateOfferData();
    } else {
      this.resyncDocuments();
      this.newOffer = true;
    }
    const routeSubscription = this._route.url.subscribe((segments) => {
      this.mode = segments[0].path as TStepperMode;
    });
    const statusChangedSubscription = this._offersService.statusChangeMade.subscribe((value) => {
      this._offersService.getOffer(Number(this.offerId)).subscribe((response) => {
        this.offer = response;
        this.statusName = this.offer.offerStatus.status.name;
        this._offersService.requestToUpdateAccessToFields.emit(this.statusName);
      });
    });

    const offerCreated = this._offersService.offerCreated.subscribe((offer) => {
      this.offer = offer;
      this.offerId = offer.id;
    });

    this._subscriptions.push(statusChangedSubscription);
    this._subscriptions.push(routeSubscription);
    this._subscriptions.push(offerCreated);
  }
  ngAfterViewInit(): void {
    this.switchToStepByStatus();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  isFullScreenEnabled = () => document.fullscreenEnabled;

  toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.fullScreen = false;
    } else {
      this._root.requestFullscreen();
      this.fullScreen = true;
    }
  };
  updateOfferData() {
    this._offersService.getOffer(Number(this.offerId)).subscribe((response) => {
      this.offer = response;
      this.statusName = this.offer.offerStatus.status.name;
      this.resyncDocuments();
      this.switchToStepByStatus();
    });
  }

  switchToStepByStatus() {
    if (this.stepper === undefined || this.offer === undefined) {
      return;
    }
    switch (this.offer.offerStatus.status.name) {
      case OfferStatusType.PENDING:
        if (!this.offer.company) {
          this.stepper.selectedIndex = 0;
        } else if (this.offer.numberOfPersons === 0 || !this.offer.categoryNamesValid) {
          this.handleUpdateStepsCompleted({ ...this.stepsCompleted, first: true });
          this.stepper.selectedIndex = 1;
        } else {
          this.handleUpdateStepsCompleted({ ...this.stepsCompleted, first: true, second: true });
          this.stepper.selectedIndex = 2;
        }
        break;
      case OfferStatusType.CALCULATED:
        this.handleUpdateStepsCompleted({ ...this.stepsCompleted, first: true, second: true, third: true , fourth: true});
        this.stepper.selectedIndex = 3;
        break;
      case OfferStatusType.IN_REVIEW:
        this.handleUpdateStepsCompleted({
          ...this.stepsCompleted,
          first: true,
          second: true,
          third: true,
          fourth: true,
          fifth: true,
        });
        this.stepper.selectedIndex = 5;
        break;
      case OfferStatusType.REVIEW_ACCEPTED:
      case OfferStatusType.REVIEW_REJECTED:
        this.handleUpdateStepsCompleted({
          ...this.stepsCompleted,
          first: true,
          second: true,
          third: true,
          fourth: true,
          fifth: true,
        });
        this.stepper.selectedIndex = 5;
        break;
      case OfferStatusType.READY:
        this.handleUpdateStepsCompleted({
          ...this.stepsCompleted,
          first: true,
          second: true,
          third: true,
          fourth: true,
          fifth: true,
          sixth: true,
        });
        this.stepper.selectedIndex = 6;
        break;
      case OfferStatusType.ACCEPTED:
      case OfferStatusType.REJECTED:
      case OfferStatusType.EXPORTED:
        this.handleUpdateStepsCompleted({
          ...this.stepsCompleted,
          first: true,
          second: true,
          third: true,
          fourth: true,
          fifth: true,
          sixth: true,
          seventh: true,
          eight: true,
        });
        this.stepper.selectedIndex = 7;
        break;
    }
  }
  handleCloseStepper = () => {
    if (this.mode !== 'preview') {
      this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
        data: {
          title: this._translateService.instant(`${this.stepGenerateDocuments}.confirmLeavingStepper`),
          message: this._translateService.instant(`${this.stepGenerateDocuments}.messageLeaveStepper`),
        },
        width: '300px',
      });
      const onDialogClose = (confirmed: boolean) => {
        if (confirmed) {
          this._router.navigate(['/offers/table']);
        }
      };
      this._dialogService.handleDialogClose(onDialogClose);
    } else {
      this._router.navigate(['/offers/table']);
    }
  };

  statusChanged(statusName) {
    this.statusName = statusName;
  }
}
