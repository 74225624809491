import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { OffersService, SnackbarService, DialogService } from '@core/services';
import { ConfirmationDialogComponent } from '@shared/components';
import {
  TConigurationStepsCompleted,
  THandleUpdateConfigurationStepsCompleted,
  THandleUpdateOffer,
  TStepperMode,
} from '@core/types';
import { IPerson, Gender, SavingPlan } from '@core/types/person';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DragAndDropDirective } from '@core/directives/drag-and-drop.directive';
import { MaritalStatus } from '@core/types/maritial-status';
import { AddInsurantComponent } from './add-insurant/add-insurant.component';
import { InsurantsTableComponent } from './insurants-table/insurants-table.component';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { Subscription } from 'rxjs';
import { IOffer } from '@core/interfaces/offers/offers.interface';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'app-offers-step-insured',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    TranslateModule,
    MatDatepickerModule,
    CommonModule,
    InsurantsTableComponent,
    DragAndDropDirective,
    NextBackButtonComponent,
  ],
  templateUrl: './offers-step-insured.component.html',
  styleUrl: './offers-step-insured.component.scss',
})
export class OffersStepInsuredComponent implements OnInit, OnDestroy {
  @Input() stepper!: MatStepper;
  @Input() mode!: TStepperMode;
  @Input() offer!: IOffer;
  @Input() configurationStepper!: MatStepper;
  @Input() handleUpdateStepsCompleted!: THandleUpdateConfigurationStepsCompleted;
  @Input() configurationStepsCompleted!: TConigurationStepsCompleted;
  @Input() handleUpdateOffer!: THandleUpdateOffer;
  @ViewChild('csvReader') csvReader: any;

  personArray = [];
  nextLoading = false;
  isEditable = true;
  files = [];
  errorLines = [];
  isFileCorrectFormate = false;
  isFileToBig = false;
  isFileCorrectFormateBeforeCheck = true;
  dataFromExampleFile;

  categoryNames: FormGroup;
  savingPlanNames: FormGroup;

  // entspricht YYYY-MM-DD
  private readonly regexFormat1 = /^\d{4}-\d{2}-\d{2}$/;
  // entspricht DD.MM.YYYY
  private readonly regexFormat2 = /^\d{2}\.\d{2}\.\d{4}$/;

  private savingPlanUploadOptions: Map<string, SavingPlan> = new Map([
    ['0', SavingPlan.STANDARD],
    ['1', SavingPlan.MINUS],
    ['2', SavingPlan.PLUS],
    ['standard', SavingPlan.STANDARD],
    ['minus', SavingPlan.MINUS],
    ['plus', SavingPlan.PLUS],
  ]);

  private savingPlanUploadOptionsKeys: Map<string, string> = new Map([
    ['standard', '0'],
    ['minus', '1'],
    ['plus', '2'],
  ]);

  private genderUploadOptions: Map<string, Gender> = new Map([
    ['1', Gender.MALE],
    ['2', Gender.FEMALE],
    ['maennlich', Gender.MALE],
    ['weiblich', Gender.FEMALE],
    ['male', Gender.MALE],
    ['female', Gender.FEMALE],
  ]);

  private maritalStatusUploadOptions: Map<string, MaritalStatus> = new Map([
    ['0', MaritalStatus.SINGLE],
    ['1', MaritalStatus.MARRIED],
    ['2', MaritalStatus.DIVORCED],
    ['3', MaritalStatus.WIDOWED],
    ['4', MaritalStatus.SEPARATED],
    ['5', MaritalStatus.UNKNOWN],
    ['6', MaritalStatus.REGISTERED_PARTNERSHIP],
    ['7', MaritalStatus.DISSOLVED_PARTNERSHIP],
    ['ledig', MaritalStatus.SINGLE],
    ['verheiratet', MaritalStatus.MARRIED],
    ['geschieden', MaritalStatus.DIVORCED],
    ['verwitwet', MaritalStatus.WIDOWED],
    ['getrennt', MaritalStatus.SEPARATED],
    ['unbekannt', MaritalStatus.UNKNOWN],
    ['eingetragene_partnerschaft', MaritalStatus.REGISTERED_PARTNERSHIP],
    ['eingetragene partnerschaft', MaritalStatus.REGISTERED_PARTNERSHIP],
    ['aufgeloeste_partnerschaft', MaritalStatus.DISSOLVED_PARTNERSHIP],
    ['aufgeloeste partnerschaft', MaritalStatus.DISSOLVED_PARTNERSHIP],
    ['single', MaritalStatus.SINGLE],
    ['married', MaritalStatus.MARRIED],
    ['divorced', MaritalStatus.DIVORCED],
    ['widowed', MaritalStatus.WIDOWED],
    ['separated', MaritalStatus.SEPARATED],
    ['unknown', MaritalStatus.UNKNOWN],
    ['registered_partnership', MaritalStatus.REGISTERED_PARTNERSHIP],
    ['registered partnership', MaritalStatus.REGISTERED_PARTNERSHIP],
    ['dissolved_partnership', MaritalStatus.DISSOLVED_PARTNERSHIP],
    ['dissolved partnership', MaritalStatus.DISSOLVED_PARTNERSHIP]
  ]);

  private offerId: string = this.route.snapshot.params['id'];
  loadingProcessDef = false;
  insurantsTableData = {};
  isPreviewMode = false;
  isAddButtonDisabled = false;
  isDeleteAllButtonDisabled = false;
  public savingPlanValues;
  private _subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private _offerService: OffersService,
    private route: ActivatedRoute,
    private _snackbarService: SnackbarService,
    private _authorizationService: AuthorizationService,
    private _dialogService: DialogService,
    private translationService: TranslateService
  ) {}

  get isNextButtonDisabled() {
    return (
      this.insurantsTableData['empty'] === undefined || this.insurantsTableData['empty'] || this.categoryNames.invalid
    );
  }

  openAddInsurantDialog(): void {
    const dialogRef = this.dialog.open(AddInsurantComponent, {
      minWidth: '90vw',
      maxHeight: '90vh',
      data: {
        offerId: this.offerId,
      },
    });

    const subs = dialogRef.afterClosed().subscribe(() => {
      this.getAllPersons(0);
    });

    this._subscriptions.push(subs);
  }

  get category1() {
    return this.categoryNames.get('category1');
  }

  get category2() {
    return this.categoryNames.get('category2');
  }

  get category3() {
    return this.categoryNames.get('category3');
  }

  chooseFile() {
    document.getElementById('file-input-form').click();
  }

  async handleFileInput(target) {
    this.handleFileInputProcessData(target);
    this.resetInputFileValue();
  }

  async handleFileInputProcessData(target) {
    this.isFileCorrectFormate = true;

    if (target?.srcElement?.files.length == 0) {
      return;
    }
    if (target?.srcElement?.files[0].size > 102400) {
      this.isFileToBig = true;
      return;
    }

    this.files = target?.srcElement?.files;
    this.isFileToBig = false;
    this.isFileCorrectFormateBeforeCheck = true;

    if (!this.files[this.files.length - 1].name.endsWith('.xlsx')) {
      this.showIncorrectFileTypePopup();
      return;
    }

    let data;

    await this.extractData().then((x) => {
      data = x;
    });

    if (await this.isFileCorrect(data)) {
      this.readData(data.data);
      return;
    }
    this.showFileIncorrectPopup();
  }

  resetInputFileValue() {
    this.files = [];
    let inputFile: any = document.getElementById('file-input-form');
    inputFile.value = '';
  }

  ngOnInit(): void {
    this.isPreviewMode = this.mode == 'preview';
    this.getAllPersons(0);
    this._offerService.getSavingsPlan(parseInt(this.offerId)).subscribe((data) => this.fillForm(data));
    this.savingPlanNames = this.fb.group({
      category1: '',
      category2: '',
      category3: '',
    });

    const subs = this._offerService.downloadExampleCsv().subscribe((data) => (this.dataFromExampleFile = data));

    const subs1 = this._offerService.requestToUpdateAccessToFields.subscribe((statusName) =>
      this.isStepEditableByStatusAndMode(statusName)
    );
    this._subscriptions.push(subs, subs1);
    const categories = this.offer.categories;
    this.categoryNames = this.fb.group({
      category1: [categories.category1Name ?? ''],
      category2: [categories.category2Name ?? ''],
      category3: [categories.category3Name ?? ''],
    });
    this.isStepEditableByStatusAndMode(this.offer.offerStatus.status.name);
    this.getUsedCategories();
    this.categoryNames.markAllAsTouched()
  }
  fillForm(data) {
    this.savingPlanNames.controls['category1'].setValue(data?.savingPlan1Name ? data.savingPlan1Name : 'Standard');
    this.savingPlanNames.controls['category2'].setValue(data?.savingPlan2Name ? data.savingPlan2Name : '');
    this.savingPlanNames.controls['category3'].setValue(data?.savingPlan3Name ? data.savingPlan3Name : '');
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  isStepEditableByStatusAndMode(statusName: string): boolean {
    if (
      statusName !== 'PENDING' ||
      this.mode === 'preview' ||
      this._authorizationService.getDecodedToken().sub !== this.offer.offerStatus.owner.externalId
    ) {
      this.categoryNames.disable();
      this.savingPlanNames.disable();
      this.isAddButtonDisabled = true;
      this.isDeleteAllButtonDisabled = true;
      this.isEditable = false;
      return false;
    }
    this.categoryNames.enable();
    this.savingPlanNames.enable();
    this.isAddButtonDisabled = false;
    this.isDeleteAllButtonDisabled = false;
    this.isEditable = true;
    return true;
  }
  getAllPersons(page?, column?, direction?) {
    this.loadingProcessDef = false;
    const subs = this._offerService.getAllPersons(this.offerId, page, column, direction).subscribe({
      next: (data: any) => {
        this.insurantsTableData = data;
        if (this.insurantsTableData['content'].length === 0 && this.isEditable) {
          const defaultCategoryValue = this.translationService.instant(
            'cabinet.offers.plan.generalTab.defaultCategoryValue'
          );
          this.category1.setValue(defaultCategoryValue);
        }
        this.getUsedCategories();
        this.categoryNames.markAllAsTouched()
      },
      error: (err) => {
        console.warn(err.message);
      },
    });
    this._subscriptions.push(subs);
    return subs;
  }
  insertPersons(persons, id) {
    const subs = this._offerService.insertPersons(persons, id).subscribe({
      next: () => {
        this.getAllPersons();
      },
      error: (err) => {
        console.error(err);
      },
    });
    this._subscriptions.push(subs);
  }

  private getUsedCategories() {
    return this._offerService.getUsedCategories(this.offerId).subscribe({
      next: (categories) => {
        this.clearAllCategoryValidators();
        if (categories.includes(1)) {
          this.updateCategoryValidator('category1');
        }
        if (categories.includes(2)) {
          this.updateCategoryValidator('category2');
        }
        if (categories.includes(3)) {
          this.updateCategoryValidator('category3');
        }
      },
      error: () => {
        this.clearAllCategoryValidators();
        this.updateCategoryValidator('category1');
      },
    });
  }

  private clearAllCategoryValidators() {
    this.category1.clearValidators();
    this.category2.clearValidators();
    this.category3.clearValidators();
    this.category1.updateValueAndValidity();
    this.category2.updateValueAndValidity();
    this.category3.updateValueAndValidity();
  }

  private updateCategoryValidator(fieldName: string) {
    const validator = this.categoryNames.get(fieldName);
    validator.setValidators([Validators.required, Validators.minLength(1)]);
    validator.updateValueAndValidity();
  }

  async readData(data) {
    this.personArray = this.extractPersonsFromData(data);
    this.insertPersons(this.personArray, this.offerId);
  }

  getDateDependentOnFormat(dateString: string) {
    let date = new Date(dateString);
    dateString = date.toISOString().substring(0, 10);
    if (this.regexFormat1.test(dateString)) {
      return new Date(dateString);
    } else if (this.regexFormat2.test(dateString)) {
      const [day, month, year] = dateString.split('.');
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return undefined;
  }
  async extractData() {
    let text = [];
    let data;
    let headerExist = false;

    await readXlsxFile(this.files[0]).then((rows) => {
      return new Promise((resolve) => {

        if (!this.isNumber(rows[0][4])) {
          rows.splice(0, 1);
          headerExist = true;
        }

        rows.forEach((element) => {
          text.push(element.join(';').replace(/, ([^,]*)$/, ' and $1'));
        });

        data = text.join('\r\n');
        return resolve({data: data, headerExist: headerExist});
      });
    });
    return {data: data, headerExist: headerExist};
  }

  extractPersonsFromData(data) {
    return data
      .split(/\r\n|\n/)
      .map((personData) => personData.split(';'))
      .map((personData) => {
        return {
          firstName: personData[0],
          lastName: personData[1],
          dateOfBirth: this.getDateDependentOnFormat(personData[2].trim()),
          ssn: personData[3],
          collective: personData[4],
          sex: this.getGenderUploadOptions(personData[5].trim()),
          maritalStatus: this.getMaritalStatusUploadOptions(personData[6].trim()),
          levelOfEmployment: parseFloat(personData[7]).toFixed(2),
          levelOfDisability: parseFloat(personData[8]).toFixed(2),
          salary: parseFloat(personData[9]).toFixed(2),
          savingsTotal: parseFloat(personData[10]).toFixed(2),
          savingsCapitalBvg: parseFloat(personData[11]).toFixed(2),
          savingPlan: this.getSavingPlanUploadOptionsKey(personData[12].trim()),
        };
      });
  }

  getSavingPlanUploadOptionsKey(value) {
    return this.savingPlanUploadOptionsKeys.get(this.getSavingPlanUploadOptions(value).toLocaleLowerCase());
  }

  getSavingPlanUploadOptions(value) {
    if (this.savingPlanUploadOptions.has(value.toLocaleLowerCase())) {
      return this.savingPlanUploadOptions.get(value.toLocaleLowerCase());
    }
    return Object.values(SavingPlan).at(Object.values(SavingPlan).indexOf(value.toUpperCase()));
  }

  getMaritalStatusUploadOptions(value) {
    if (this.maritalStatusUploadOptions.has(value.toLocaleLowerCase())) {
      return this.maritalStatusUploadOptions.get(value.toLocaleLowerCase());
    }
    return Object.values(MaritalStatus).at(Object.values(MaritalStatus).indexOf(value.toUpperCase()));
  }

  getGenderUploadOptions(value) {
    if (this.genderUploadOptions.has(value.toLocaleLowerCase())) {
      return this.genderUploadOptions.get(value.toLocaleLowerCase());
    }
    return Object.values(Gender).at(Object.values(Gender).indexOf(value.toUpperCase()));
  }

  async onFileDropped($event) {
    this.onFileDroppedProcessData($event);
    this.resetInputFileValue();
  }

  async onFileDroppedProcessData($event) {
    for (const item of $event) {
      this.files.push(item);
    }
    if (this.files.length === 0) {
      return;
    }

    if (!this.files[this.files.length - 1].name.endsWith('.xlsx')) {
      this.showIncorrectFileTypePopup();
      return;
    }

    let data;

    await this.extractData().then((x) => {
      data = x;
    });

    if (await this.isFileCorrect(data)) {
      this.readData(data.data);
      return;
    }
    this.showFileIncorrectPopup();
  }

  showIncorrectFileTypePopup() {
    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        title: this.translationService.instant('cabinet.fileupload.errorMessages.inputFileInsurantErrorFileType'),
        message: this.translationService.instant('cabinet.fileupload.errorMessages.inputFileInsurantErrorFileTypeMsg'),
        showOnlyOkBtn: true,
      },
      width: '300px',
    });

    const onDialogClose = (confirmed: boolean) => {
      this.errorLines = [];
    };

    this._dialogService.handleDialogClose(onDialogClose);
  }

  showFileIncorrectPopup() {
    let text = '';
    this.errorLines.forEach(
      (error) => (text += this.translationService.instant(error.error, { index: error.index }) + '<br>')
    );

    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        title: this.translationService.instant('cabinet.fileupload.errorMessages.inputFileInsurantError'),
        message: text,
        showOnlyOkBtn: true,
      },
      width: '800px',
    });

    const onDialogClose = (confirmed: boolean) => {
      this.errorLines = [];
    };

    this._dialogService.handleDialogClose(onDialogClose);
  }

  isNumber(str) {
    return !isNaN(Number(str));
  }

  isFileCorrect(csvData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.errorLines = [];
      const csvdata: any = csvData.data;
      const rowData = csvdata.split('\n');
      const incrementBy = csvData.headerExist ? 2 : 1;

      if (rowData.length == 0) {
        this.errorLines.push({ index: 0, error: 'cabinet.fileupload.errorMessages.emptyFile' });
      }

      for (let i = 0; i < rowData.length; i++) {
        //skip any empty last lines
        if (rowData[i].trim() === '' && i === rowData.length - 1) {
          continue;
        }

        //skip header if present
        const rowColData = rowData[i].split(';');

        let dateInput = null;
        const line = i + incrementBy;

        try {
          let date = new Date(rowColData[2].trim());
          dateInput = date.toISOString().substring(0, 10);
        } catch (error) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.dateFormatIncorrect',
          });
        }

        if (i === 0 && dateInput === null) {
          continue;
        }

        if (rowColData.length !== 13) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.incorrectColumnCount',
          });
          continue;
        }

        if (!/\w+/.test(rowColData[0].trim())) {
          this.errorLines.push({ index: line, error: 'cabinet.fileupload.errorMessages.firstnameTooShort' });
        }

        if (!/\w+/.test(rowColData[1].trim())) {
          this.errorLines.push({ index: line, error: 'cabinet.fileupload.errorMessages.lastnameTooShort' });
        }

        if (dateInput != null && !this.regexFormat1.test(dateInput) && !this.regexFormat2.test(dateInput)) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.dateFormatIncorrect',
          });
        }
        
        if (
          !this.genderUploadOptions.has(rowColData[5].trim().toLocaleLowerCase()) &&
          !Object.values(Gender).includes(rowColData[5].trim().toUpperCase())
        ) {
          this.errorLines.push({ index: line, error: 'cabinet.fileupload.errorMessages.genderIncorrect' });
        }

        if (
          !this.maritalStatusUploadOptions.has(rowColData[6].trim().toLocaleLowerCase()) &&
          !Object.values(MaritalStatus).includes(rowColData[6].trim().toUpperCase())
        ) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.maritalStatusIncorrect',
          });
        }

        if (!this.isNumber(rowColData[7].trim())) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.levelOfEmploymentFormatIncorrect',
          });
        }

        if (!this.isNumber(rowColData[8].trim())) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.salaryFormatIncorrect',
          });
        }

        if (!this.isNumber(rowColData[9].trim())) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.regulatorySavingsFormatIncorrect',
          });
        }

        if (!this.isNumber(rowColData[10].trim())) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.bvgSavingsFormatIncorrect',
          });
        }

        if (!this.isNumber(rowColData[11].trim())) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.bvgSavingsCapitalFormatIncorrect',
          });
        }

        if (
          !this.savingPlanUploadOptions.has(rowColData[12].trim().toLocaleLowerCase()) &&
          !Object.values(SavingPlan).includes(rowColData[12].trim().toUpperCase())
        ) {
          this.errorLines.push({
            index: line,
            error: 'cabinet.fileupload.errorMessages.maritalStatusIncorrect',
          });
        }
      }

      resolve(this.errorLines.length === 0);
    });
  }

  handleEditInsurant(insurantId: number) {
    const subs = this._offerService.getPerson(this.offerId, `${insurantId}`).subscribe((insurantData: IPerson) => {
      const dialogRef = this.dialog.open(AddInsurantComponent, {
        minWidth: '90vw',
        maxHeight: '90vh',
        data: {
          insurantData,
          insurantId,
          offerId: this.offerId,
        },
      });
      const subs1 = dialogRef.afterClosed().subscribe(() => {
        this.getAllPersons();
      });
      this._subscriptions.push(subs1);
    });
    this._subscriptions.push(subs);
  }

  handleTableChange({ pageIndex, pageSize }: any): void {
    this.getAllPersons(
      {
        pageIndex,
        pageSize,
      },
      '',
      ''
    );
  }

  handleDeleteInsurant(event: { insurantId: number; insurantFirstName: string; insurantLastName: string }) {
    const subs = this._offerService.deletePerson(this.offerId, event.insurantId).subscribe((id) => {
      this._snackbarService.handleOpenSnackbar(
        this.translationService.instant('cabinet.notifications.insurantDeleteSuccess', {
          name: event.insurantFirstName + ' ' + event.insurantLastName,
        }),
        'Close',
        'success'
      );
      this.getAllPersons();
    });

    this._subscriptions.push(subs);
  }

  handleDeleteAllInsurants() {
    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        title: this.translationService.instant('cabinet.navigationWizzard.deleteInsurant'),
        message: this.translationService.instant('cabinet.navigationWizzard.deleteAllInsurants'),
      },
      width: '300px',
    });
    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        const subs = this._offerService.deleteAllPersons(this.offerId).subscribe(() => {
          this._snackbarService.handleOpenSnackbar(
            this.translationService.instant('cabinet.notifications.insurantDeleteAllSuccess'),
            'Close',
            'success'
          );
          this.getAllPersons();
        });
        this._subscriptions.push(subs);
      }
    };
    this._dialogService.handleDialogClose(onDialogClose);
  }

  downloadExampleFile() {
    if (!this.isEditable || this.isPreviewMode) {
      return;
    }
    const blob = new Blob([this.dataFromExampleFile], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = this.translationService.instant('cabinet.fileupload.exampleFileDownload');
    anchor.href = url;
    anchor.click();
  }

  handleNextStep() {
    const savingPlanNames = {
      savingPlan1Name: this.savingPlanNames.controls['category1'].value,
      savingPlan2Name: this.savingPlanNames.controls['category2'].value,
      savingPlan3Name: this.savingPlanNames.controls['category3'].value,
    };
    this._offerService.setSavingsPlan(savingPlanNames, this.offer.id).subscribe((_) => {});
    const categories = {
      category1Name: this.category1.value,
      category2Name: this.category2.value,
      category3Name: this.category3.value,
    };
    this._offerService.renameCategories(categories, this.offerId).subscribe((_) => {});
    this.stepper.selected.completed = true;
    this.nextLoading = false;
    this.handleUpdateStepsCompleted({
      ...this.configurationStepsCompleted,
      second: true,
    });
    this.stepper.next();
  }

  handleBackStep() {
    this.stepper.previous();
  }

  showCorrectFileUploadTextPart1(): string {
    return this.isEditable && !this.isPreviewMode ? 
        'cabinet.fileupload.selectFileTextPart1' : 
        'cabinet.fileupload.selectFileTextPart1Disabled';
  }

  showCorrectFileUploadTextPart2(): string {
    return this.isEditable && !this.isPreviewMode ? 
        'cabinet.fileupload.selectFileTextPart2' : 
        'cabinet.fileupload.selectFileTextPart2Disabled';
  }
}

