<div class="stepper-container">
    <mat-stepper #stepper class="stepper" linear [orientation]="(stepperOrientation | async)!">
        @if(offer || newOffer){
        <mat-step
            [label]="'cabinet.navigationWizzard.baseData' | translate"
            [state]="stepsCompleted.first ? 'visibility' : 'create'"
            [completed]="stepsCompleted.first"
        >
            <ng-template matStepContent>
                <app-offers-step-basic-data
                    [stepper]="stepper"
                    [mode]="mode"
                    [stepsCompleted]="stepsCompleted"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [offer]="offer"
                    (offerCreated)="(offer)"
                    [handleUpdateOffer]="handleUpdateOffer"
                ></app-offers-step-basic-data>
            </ng-template>
        </mat-step>
        <mat-step
            [label]="'cabinet.navigationWizzard.addInsurant' | translate"
            [state]="stepsCompleted.second ? 'visibility' : 'create'"
            [completed]="stepsCompleted.second"
        >
            <ng-template matStepContent>
                <app-offers-step-insured
                    [stepper]="stepper"
                    [mode]="mode"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [configurationStepsCompleted]="stepsCompleted"
                    [offer]="offer"
                    [handleUpdateOffer]="handleUpdateOffer"
                ></app-offers-step-insured>
            </ng-template>
        </mat-step>
        <mat-step
            [label]="'cabinet.navigationWizzard.product' | translate"
            [state]="stepsCompleted.third ? 'visibility' : 'create'"
            [completed]="stepsCompleted.third"
        >
            <ng-template matStepContent>
                <app-offers-product
                    [stepper]="stepper"
                    [mode]="mode"
                    [stepsCompleted]="stepsCompleted"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [offer]="offer"
                    [handleUpdateOffer]="handleUpdateOffer"
                ></app-offers-product>
            </ng-template>
        </mat-step>
        <mat-step
            [label]="'cabinet.navigationWizzard.calculationResults' | translate"
            [state]="stepsCompleted.fourth ? 'visibility' : 'create'"
            [completed]="stepsCompleted.fourth"
        >
            <ng-template matStepContent>
                <offers-step-calculation
                    [stepper]="stepper"
                    [mode]="mode"
                    [offer]="offer"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [stepsCompleted]="stepsCompleted"
                ></offers-step-calculation>
            </ng-template>
        </mat-step>
        <mat-step
            [label]="'cabinet.navigationWizzard.generateDocuments' | translate"
            [state]="stepsCompleted.fifth ? 'visibility' : 'create'"
            [completed]="stepsCompleted.fifth"
        >
            <ng-template matStepContent>
                <app-offers-step-generate-documents
                    [stepper]="stepper"
                    [mode]="mode"
                    [offer]="offer"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [stepsCompleted]="stepsCompleted"
                    [currentLocale]="currentLocale"
                >
                </app-offers-step-generate-documents>
            </ng-template>
        </mat-step>

        <mat-step
            [label]="'cabinet.navigationWizzard.review' | translate"
            [state]="stepsCompleted.sixth ? 'visibility' : 'create'"
            [completed]="stepsCompleted.sixth"
        >
            <ng-template matStepContent>
                <app-offers-step-reviewer
                    [mode]="mode"
                    [stepper]="stepper"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [stepsCompleted]="stepsCompleted"
                    [offer]="offer"
                ></app-offers-step-reviewer>
            </ng-template>
        </mat-step>
        <mat-step
            [label]="'cabinet.navigationWizzard.documents' | translate"
            [state]="stepsCompleted.seventh ? 'visibility' : 'create'"
            [completed]="stepsCompleted.seventh"
        >
            <ng-template matStepContent>
                <app-offers-step-final-documents
                    [stepper]="stepper"
                    [mode]="mode"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [stepsCompleted]="stepsCompleted"
                    [documentList]="documentList"
                    [statusName]="statusName"
                    [offer]="offer"
                ></app-offers-step-final-documents>
            </ng-template>
        </mat-step>
        <mat-step
            [label]="'cabinet.navigationWizzard.export' | translate"
            [state]="stepsCompleted.eight ? 'visibility' : 'create'"
            [completed]="stepsCompleted.eight"
        >
            <ng-template matStepContent>
                <app-offers-step-export
                    [stepper]="stepper"
                    [mode]="mode"
                    [handleUpdateStepsCompleted]="handleUpdateStepsCompleted"
                    [stepsCompleted]="stepsCompleted"
                    [statusName]="statusName"
                    [offer]="offer"
                ></app-offers-step-export>
            </ng-template>
        </mat-step>
        }
    </mat-stepper>
    @if(fullScreen && (stepperOrientation | async) === 'horizontal'){<button
        class="full-screen-button"
        mat-icon-button
        aria-label="Close full screen button"
        [matTooltip]="'cabinet.home.infoField.closeFullScreen' | translate"
        (click)="toggleFullScreen()"
    >
        <mat-icon>close_fullscreen</mat-icon>
    </button>
    } @else if ((stepperOrientation | async) === 'horizontal' && isFullScreenEnabled()) {
    <button
        class="full-screen-button"
        mat-icon-button
        aria-label="Open stepper in full screen button"
        [matTooltip]="'cabinet.home.infoField.openSteperFullScreen' | translate"
        (click)="toggleFullScreen()"
    >
        <mat-icon>fullscreen</mat-icon>
    </button>
    }
    <button
        class="close-stepper-button"
        mat-icon-button
        aria-label="Close stepper button"
        [matTooltip]="'cabinet.home.infoField.closeStepper' | translate"
        (click)="handleCloseStepper()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
