import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, OffersService, ProductService } from '@core/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { THandleUpdateOffer, THandleUpdateStepsCompleted, TStepperMode, TStepsCompleted } from '@core/types';
import { isInputInvalid } from '@core/utils';
import { ConfigurePlanComponent } from './configure-plan/configure-plan.component';
import PlanFromOffer from '@core/types/plan';
import { ConfirmationDialogComponent } from '@shared/components';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { IOffer } from '@core/interfaces/offers/offers.interface';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { Subscription, Subject, take } from 'rxjs';
import { SaveEventName } from '@core/enums/save-event-name.enum';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-offers-product',
    standalone: true,
    imports: [
        MatStepperModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        ConfigurePlanComponent,
        TranslateModule,
        NextBackButtonComponent,
    ],
    templateUrl: './offers-product.component.html',
    styleUrl: './offers-product.component.css',
})
export class OffersProductComponent implements OnInit, OnDestroy {
    @Input() stepper!: MatStepper;
    @Input() mode!: TStepperMode;
    @Input() offer!: IOffer;
    @Input() handleUpdateOffer!: THandleUpdateOffer;
    nextLoading = false;
    currentPlan: PlanFromOffer;
    @Input() stepsCompleted!: TStepsCompleted;
    @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
    isEditable: boolean = true;
    public templates = [];
    public showConfigurePlanForm = false;
    selectedPlan;
    isPreviewMode = false;
    isPlanInitilized = false;
    savePlanOnNextBtn: Subject<SaveEventName> = new Subject();
    private _subscriptions: Subscription[] = [];

    offerId: number;
    constructor(
        private _formBuilder: FormBuilder,
        private _dialogService: DialogService,
        private _route: ActivatedRoute,
        private _productService: ProductService,
        private _offersService: OffersService,
        private _translateService: TranslateService,
        private _authorizationService: AuthorizationService,
        private _router: Router
    ) {}

    secondStepForm = this._formBuilder.group({
        retirementPlan: [null],
        planTemplateSelected: [false],
    });

    get retirementPlan() {
        return this.secondStepForm.get('retirementPlan');
    }
    get planTemplateSelected() {
        return this.secondStepForm.get('planTemplateSelected');
    }
    get isRetirementPlanInvalid() {
        return isInputInvalid(this.retirementPlan);
    }
    get isNextButtonDisabled() {
        if (this.mode === 'edit') {
            if (!this.isPlanInitilized) {
                return true;
            }
            return false;
        } else if (this.mode === 'new') {
            if (this.isPlanInitilized) {
                return false;
            }
            return !(this.planTemplateSelected.value && this.showConfigurePlanForm);
        } else if (this.mode === 'preview') {
            if (!this.isPlanInitilized || !this.stepsCompleted.second) {
                return true;
            }
        }

        return this.secondStepForm.invalid;
    }

    handlePlanSelect($event: MatSelectChange) {
        if ($event.value) {
            this.selectedPlan = $event.value;
            this.planTemplateSelected?.setValue(true);
        }
    }

    handleSecondStepSubmit(): void {
        if (
            this.secondStepForm.valid &&
            (this.mode === 'new' || this.mode === 'edit') &&
            !this.secondStepForm.disabled
        ) {
            this.nextLoading = false;
            this.openProductConfirmationDialog(this._productService.getProductDialogUpdates());
        } else if (this.offer?.offerStatus?.status?.name === 'PENDING' && this.mode === 'preview') {
            this._router.navigate(['/offers/table']);
        } else if (
            this.offer?.offerStatus?.status?.name !== 'PENDING' ||
            (this.offer?.offerStatus?.status?.name === 'PENDING' && this.mode !== 'preview')
        ) {
            this.switchToNextStep();
        }
    }
    updateComponentMessage(component) {
        let missingRiskComponentMessage: string;
        let missingSavingConMessage: string;
        let missingSeparator: string;
        component.riskBenefit.updated
            ? (missingRiskComponentMessage = '')
            : (missingRiskComponentMessage = `-${this._translateService.instant('cabinet.offers.plan.riskBenefit')}`);
        component.savingContribution.updated
            ? (missingSavingConMessage = '')
            : (missingSavingConMessage = `-${this._translateService.instant(
                  'cabinet.offers.plan.savingContribution'
              )}`);
        !component.riskBenefit.updated && !component.savingContribution.updated
            ? (missingSeparator = '<br>')
            : (missingSeparator = '');
        if (!component.riskBenefit.updated || !component.savingContribution.updated) {
            return {
                data: {
                    title: this._translateService.instant('cabinet.product.dialog.confirmPlan'),
                    message: `${this._translateService.instant('cabinet.product.dialog.noChangesMade', {
                        riskBenefit: missingRiskComponentMessage,
                        separator: missingSeparator,
                        savingContribution: missingSavingConMessage,
                    })} ${this._translateService.instant('cabinet.product.dialog.updatedMessageQuestion')}`,
                },
            };
        }
        return {
            data: {
                title: this._translateService.instant('cabinet.product.dialog.confirmPlan'),
                message: this._translateService.instant('cabinet.product.dialog.updatedMessageQuestion'),
            },
        };
    }
    private openProductConfirmationDialog(updatedComponent): void {
        this._dialogService.handleDialogOpen(
            ConfirmationDialogComponent,
            this.updateComponentMessage(updatedComponent)
        );

        const onDialogClose = (confirmed: boolean) => {
            if (confirmed) {
                this.savePlanOnNextBtn.next(SaveEventName.NEXT_BTN_SAVE);
                this.switchToNextStep();
            }
        };
        this._dialogService.handleDialogClose(onDialogClose);
    }
    ngOnInit(): void {
        this.isPreviewMode = this.mode == 'preview';
        this.isStepEditableByStatusAndMode(this.offer.offerStatus.status.name);
        this.offerId = this.offer.id;
        this.nextLoading = true;
        this.getPlanByOffer();

        const subs1 = this._productService.getUpdatedFormSubs().subscribe((value) => {
            if (value === SaveEventName.SWITCH_TAB_SAVE) {
                this.getPlanByOffer();
            }
        });

        const subs2 = this._productService.getTemplates().subscribe((templates) => {
            this.templates = templates;
        });

        const subs = this._offersService.requestToUpdateAccessToFields.subscribe((statusName) =>
            this.isStepEditableByStatusAndMode(statusName)
        );
        this._subscriptions.push(subs, subs1, subs2);
    }

    switchToNextStep() {
        this.handleUpdateStepsCompleted({
            ...this.stepsCompleted,
            second: true,
            third: true,
        });
        this._offersService.calculationRequested.emit(true);
        this._productService.resetProductDialogUpdates();
        this.stepper.selected.completed = true;
        this.stepper.next();
    }

    getPlanByOffer() {
        const subs = this._productService.getPlanByOffer(this.offerId).subscribe({
            next: (data) => {
                if (Object.values(data).length > 0) {
                    this.currentPlan = data;
                    this.showConfigurePlanForm = true;
                    this.isPlanInitilized = true;
                }
                this.nextLoading = false;
            },
            error: (err) => {
                this.nextLoading = false;
                console.warn(err);
            },
        });
        this._subscriptions.push(subs);
    }
    isStepEditableByStatusAndMode(statusName: string): boolean {
        if (
            statusName !== 'PENDING' ||
            this.mode === 'preview' ||
            this._authorizationService.getDecodedToken().sub !== this.offer.offerStatus.owner.externalId
        ) {
            this.secondStepForm.disable();
            this.isEditable = false;
            return false;
        }
        this.secondStepForm.enable();
        this.isEditable = true;
        return true;
    }

    ngOnDestroy(): void {
        this._productService.resetProductDialogUpdates();
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
        this._dialogService.handleDialogUnsubscribe();
    }

    handleConfigurePlan = () => {
        if (this.isPlanInitilized) {
            this.dialogReinitializePlan();
            return;
        }
        this.initializePlan();
    };

    dialogReinitializePlan() {
        this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
            data: {
                title: this._translateService.instant('cabinet.offers.plan.planReinitialiseTitle'),
                message: this._translateService.instant('cabinet.offers.plan.planReinitialiseDescription'),
            },
            width: '300px',
        });

        const onDialogClose = (confirmed: boolean) => {
            if (confirmed) {
                this.showConfigurePlanForm = false;
                this.initializePlan();
            }
        };
        this._dialogService.handleDialogClose(onDialogClose);
    }

    initializePlan() {
        this.nextLoading = true;
        const subs = this._productService
            .initializePlan(this._route.snapshot.params['id'], this.selectedPlan.externalId)
            .subscribe({
                next: () => {
                    const req1 = this._productService.getPlanByOffer(this.offerId);
                    const req2 = this._offersService.getPlanEditableById(this.offerId);

                    const subs1 = forkJoin([req1, req2]).subscribe({
                        next: ([req1, req2]) => {
                            this.currentPlan = req1;
                            this.showConfigurePlanForm = true;
                            this.isPlanInitilized = true;
                            this.nextLoading = false;
                            this.offer.planEditable = req2;
                        },
                        error: (err) => {
                            this.nextLoading = false;
                            console.warn(err);
                        },
                    });
                    this._subscriptions.push(subs1);
                },
                error: (err) => {
                    this.nextLoading = false;
                    console.warn(err);
                },
            });

        this._subscriptions.push(subs);
    }

    handleBackStep() {
        this.stepper.previous();
    }
}
