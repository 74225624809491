import { Directive, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appArrowNavigation]',
    standalone: true,
})
export class ArrowNavigationDirective implements AfterViewInit {
    private elements: HTMLElement[] = [];
    private columnsCount: number = 1;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.updateElements();
        this.updateColumnsCount();
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const { target, key, shiftKey } = event;
        if (!(target instanceof HTMLElement)) return;
        const currentElement = target as HTMLElement;
        const currentTabIndex = this.elements.indexOf(currentElement);

        if (currentTabIndex === -1) return;

        let nextTabIndex = currentTabIndex;

        switch (event.key) {
            case 'ArrowRight':
                nextTabIndex = currentTabIndex + 1;
                break;
            case 'ArrowLeft':
                nextTabIndex = currentTabIndex - 1;
                break;
            case 'ArrowDown':
                nextTabIndex = this.getVerticalIndex(currentTabIndex, 1);
                break;
            case 'ArrowUp':
                nextTabIndex = this.getVerticalIndex(currentTabIndex, -1);
                break;
            case 'Tab':
                if (shiftKey) {
                    nextTabIndex = currentTabIndex - 1;
                } else {
                    nextTabIndex = currentTabIndex + 1;
                }
                break;
            default:
                return;
        }

        if (nextTabIndex >= 0 && nextTabIndex < this.elements.length) {
            event.preventDefault();
            this.elements[nextTabIndex].focus();
            let element = this.elements[nextTabIndex] as HTMLInputElement;
            element.select()
        }
    }

    @HostListener('focusin', ['$event'])
    onFocusIn(event: FocusEvent): void {
        this.updateElements();
    }

    private updateElements() {
        this.elements = Array.from(document.querySelectorAll('[appArrowNavigation]')) as HTMLElement[];
        this.elements.forEach((element, index) => element.setAttribute('tabindex', '-1'));
    }

    private updateColumnsCount() {
        if (this.elements.length > 0) {
            const firstElementRect = this.elements[0].getBoundingClientRect();
            this.columnsCount = this.elements.filter((element) => {
                return element.getBoundingClientRect().top === firstElementRect.top;
            }).length;
        }
    }

    private getVerticalIndex(currentIndex: number, rowChange: number): number {
        const nextRowIndex = currentIndex + rowChange * this.columnsCount;

        if (nextRowIndex < 0 || nextRowIndex >= this.elements.length) {
            return currentIndex;
        }
        return nextRowIndex;
    }
}
