<ng-container>
  <mat-stepper
    #stepperReviewer
    class="stepper"
    linear
    orientation="vertical"
    [selectedIndex]="selectedIndex"
  >
    <mat-step
      [label]="'cabinet.offers.plan.reviewStep.assignToReviewer' | translate"
      [state]="stepsCompletedReviewer.first ? 'visibility' : 'create'"
      [completed]="stepsCompletedReviewer.first"
      [editable]="selectedIndex === 0"
    >
      @if(loggedInUserId !== offer.offerStatus.owner.externalId){

      <div>
        <div class="width100">
          {{ "cabinet.offers.plan.reviewStep.userIsNotCreator" | translate }}
        </div>
      </div>

      } @else {

      <div class="padding10">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "cabinet.offers.plan.reviewStep.reviewer" | translate
          }}</mat-label>

          <mat-select [formControl]="reviewerControl">
            @for(reviewer of reviewerList; track reviewer.id){
            <mat-option [value]="reviewer.id">
              {{ reviewer.firstName + " " + reviewer.lastName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      }
    </mat-step>

    <mat-step
      [label]="'cabinet.offers.plan.reviewStep.inReview' | translate"
      [state]="stepsCompletedReviewer.second ? 'visibility' : 'create'"
      [completed]="stepsCompletedReviewer.second"
      [editable]="selectedIndex === 1"
    >
      <div class="width100 labelText">
        <mat-label
          >{{ "cabinet.offers.plan.reviewStep.creator" | translate }}:
          {{ offer.offerStatus.owner.firstName }}
          {{ offer.offerStatus.owner.lastName }}</mat-label
        >
      </div>

      <div class="width100 labelText">
        <mat-label
          >{{ "cabinet.offers.plan.reviewStep.reviewer" | translate }}:
          {{ offer.offerStatus.reviewer?.firstName }}
          {{ offer.offerStatus.reviewer?.lastName }}</mat-label
        >
      </div>
      @if(showRemarkInput) {
      <mat-form-field class="comment-section">
        <mat-label>{{
          "cabinet.offers.plan.reviewStep.leaveACommentForCreator" | translate
        }}</mat-label>
        <textarea matInput [formControl]="remarkControl" required></textarea>
      </mat-form-field>
      }
    </mat-step>

    <mat-step
      [label]="reviewApprovedRejected | translate"
      [state]="stepsCompletedReviewer.third ? 'visibility' : 'create'"
      [completed]="stepsCompletedReviewer.third"
      [editable]="selectedIndex === 2"
    >
      @if(offerReviewed) {
      <div
        class="width100 labelText"
        [class.green]="offerReviewed.status.name === 'REVIEW_ACCEPTED'"
        [class.red]="offerReviewed.status.name === 'REVIEW_REJECTED'"
      >
        <mat-label
          >{{ offerApprovedRejected | translate }}
          {{ offerReviewed.reviewer?.firstName }}
          {{ offerReviewed.reviewer?.lastName }}</mat-label
        >
      </div>
      <div class="width100 labelText">
        <mat-label
          >{{
            "cabinet.offers.plan.reviewStep.messageFromReviewer" | translate
          }}
          {{ offerReviewed.remark }}</mat-label
        >
      </div>

      }
    </mat-step>
  </mat-stepper>
  <next-back-button
    [nextLoading]="nextLoading"
    (nextButton)="handleNextStep(nextButtonId)"
    (backButton)="handleBackStep()"
    [hideMiddleButton]="hideMiddleButton"
    [middleButtonText]="middleButtonText"
    (middleButton)="handleNextStep(middleButtonId)"
    [isMiddleButtonDisabled]="isMiddleButtonDisabled"
    [nextButtonText]="nextButtonText"
    [hideNextButton]="hideNextButton"
    [styleType]="styleType"
    [isNextButtonDisabled]="isNextButtonDisabled"
  ></next-back-button>
</ng-container>
