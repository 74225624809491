import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, switchMap } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { CalcStepOneComponent } from './calc-step-one/calc-step-one.component';
import { CalcStepTwoComponent } from './calc-step-two/calc-step-two.component';
import { CalcStepThreeComponent } from './calc-step-three/calc-step-three.component';
import { MatStepper } from '@angular/material/stepper';
import { THandleUpdateStepper, THandleUpdateStepsCompleted, TStepperMode, TStepsCompleted } from '@core/types';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogService, OffersService, ProductService } from '@core/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { IOffer, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { ConfirmationDialogComponent } from '@shared/components';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { MoneyPipe } from '@core/pipes/money/money.pipe';

@Component({
    selector: 'offers-step-calculation',
    standalone: true,
    imports: [
        MatTabsModule,
        CalcStepOneComponent,
        CalcStepTwoComponent,
        CalcStepThreeComponent,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        NextBackButtonComponent,
        MoneyPipe,
    ],
    templateUrl: './offers-step-calculation.component.html',
    styleUrls: ['./offers-step-calculation.component.scss'],
})
export class OffersStepCalculationComponent implements OnInit, OnDestroy {
    @Input() stepper!: MatStepper;
    @Input() mode: TStepperMode;
    statusName: string;
    @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
    @Input() stepsCompleted!: TStepsCompleted;
    @Input() offer!: IOffer;
    public offerId: number;
    categories = 2;
    tableEmpty = false;
    nextLoading = false;
    calculations: any;
    isPreviewMode: boolean;
    isEditable: boolean = true;

    private _subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private _offersService: OffersService,
        private _dialogService: DialogService,
        private _translateService: TranslateService,
        private _authorizationService: AuthorizationService,
        private _productService: ProductService
    ) {}

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngOnInit() {
        this.isPreviewMode = this.mode === 'preview';
        this.offerId = this.offer.id;

        this.statusName = this.offer.offerStatus.status.name;
        this.executeCalculationIfNeeded();
        const subs = this._offersService.calculationRequested.subscribe((data) => this.executeCalculationIfNeeded());
        this._subscriptions.push(subs);
    }

    isStepEditableByStatusAndMode() {
        if (
            this._authorizationService.getDecodedToken().sub !== this.offer.offerStatus.owner.externalId ||
            this.isPreviewMode ||
            this.statusName !== OfferStatusType.CALCULATED
        ) {
            this.isEditable = false;
            return false;
        }
        this.isEditable = true;
        return true;
    }

    executeCalculationIfNeeded() {
        this.statusName = this.offer.offerStatus.status.name;
        if (this.nextLoading) {
            return;
        }

        if (this.statusName == OfferStatusType.PENDING) {
            this.nextLoading = true;
            const subs = this._offersService.proceedToStatus(this.offerId, OfferStatusType.CALCULATED).subscribe({
                next: (data) => {
                    this.getCalculationResultsFromDB();
                },
                error: (error) => {
                    console.error(error);
                },
            });
            this._subscriptions.push(subs);
        } else {
            if (!this.calculations) {
                this.nextLoading = true;
                const subs = this._offersService.getOffersCalculation(this.offerId).subscribe({
                    next: (calculations) => {
                        this.calculations = calculations;
                        this.nextLoading = false;
                        this.isStepEditableByStatusAndMode();
                    },
                    error: (err) => {
                        this.nextLoading = false;
                        this.isStepEditableByStatusAndMode();
                        console.error(err);
                    },
                });
                this._subscriptions.push(subs);
            }
        }
    }

    getCalculationResultsFromDB() {
        const subs = this._offersService.getOffersCalculation(this.offerId).subscribe({
            next: (calculations) => {
                this.calculations = calculations;
                this.nextLoading = false;
                this._offersService.statusChangeMade.emit(OfferStatusType.CALCULATED);
                this.statusName = OfferStatusType.CALCULATED;
                this.isStepEditableByStatusAndMode();
            },
            error: (err) => {
                this.nextLoading = false;
                this.isStepEditableByStatusAndMode();
                console.error(err);
            },
        });
        this._subscriptions.push(subs);
    }

    handleNumberOfCategories(categories: number) {
        this.categories = categories;
    }
    handleNextStep() {
        this.stepper.selected.completed = true;
        this.nextLoading = false;
        this.handleUpdateStepsCompleted({
            ...this.stepsCompleted,
            fourth: true,
        });
        this.stepper.next();
    }

    handleBackStep() {
        this.handleUpdateStepsCompleted({
            ...this.stepsCompleted,
            third: false,
        });
        this.stepper.selected.completed = false;
        this.stepper.previous();
    }

    handleBackStepResetCalculation() {
        this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
            data: {
                title: this._translateService.instant(
                    'cabinet.offers.plan.calculationResults.calculationResetQuestionTitle'
                ),
                message: this._translateService.instant(
                    'cabinet.offers.plan.calculationResults.calculationResetQuestion'
                ),
            },
            width: '300px',
        });

        const onDialogClose = (confirmed: boolean) => {
            if (confirmed) {
                this.nextLoading = true;
                const subs = this._offersService.proceedToStatus(this.offerId, OfferStatusType.PENDING).subscribe({
                    next: (data) => {
                        this.statusName = OfferStatusType.PENDING;
                        this.isStepEditableByStatusAndMode();
                        this._offersService.statusChangeMade.emit(OfferStatusType.PENDING);
                        this._offersService.reloadDocumentDataRequested.emit(true);
                        this.nextLoading = false;
                        this.handleBackStep();
                    },
                    error: (err) => {
                        this.nextLoading = false;
                        console.error(err);
                    },
                });
                this._subscriptions.push(subs);
            }
        };
        this._dialogService.handleDialogClose(onDialogClose);
    }
}
