export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum SavingPlan {
  STANDARD = "STANDARD",
  MINUS = "MINUS",
  PLUS = "PLUS"
}


export interface IPerson {
  id?: number;
  offer_id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  sex: unknown;
  collective: string;
  ssn: string;
  maritalStatus: unknown;
  levelOfEmployment: number;
  salary: number;
  savingsTotal: number;
  savingPlan: SavingPlan;
  savingsCapitalBvg: number;
  levelOfDisability: number;
}
