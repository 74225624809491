<mat-tab-group dynamicHeight (selectedTabChange)="onTabChange()">
    @if(currentPlan) {
    <mat-tab [label]="'cabinet.offers.plan.general' | translate">
        <ng-template matTabContent>
            <app-general-info
                [isPreviewMode]="isPreviewMode"
                [isEditable]="isEditable"
                [isPlanEditable]="isPlanEditable"
                [currentPlan]="currentPlan"
                [saveSubject]="saveSubject"
                (selectedCategoryChange)="handleNumberOfCategories($event)"
            ></app-general-info>
        </ng-template>
    </mat-tab>
    <mat-tab [label]="'cabinet.offers.plan.riskBenefit' | translate">
        <ng-template matTabContent>
            <app-risk-benefits
                [isPreviewMode]="isPreviewMode"
                [isEditable]="isEditable"
                [isPlanEditable]="isPlanEditable"
                [currentPlan]="currentPlan"
                [categories]="categories"
                [saveSubject]="saveSubject"
            ></app-risk-benefits>
        </ng-template>
    </mat-tab>
    <mat-tab [label]="'cabinet.offers.plan.savingContribution' | translate">
        <ng-template matTabContent>
            <app-saving-contributions-step
                [categories]="categories"
                [offerId]="offerId"
                [isPreviewMode]="isPreviewMode"
                [isEditable]="isEditable"
                [isPlanEditable]="isPlanEditable"
                [saveSubject]="saveSubject"
            ></app-saving-contributions-step>
        </ng-template>
    </mat-tab>
    }
</mat-tab-group>
