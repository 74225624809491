import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { IOffer, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { THandleUpdateStepsCompleted, TStepsCompleted, TStepperMode } from '@core/types';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { OffersService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { switchMap, Subscription } from 'rxjs';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-offers-step-export',
  standalone: true,
  imports: [
    MatDividerModule,
    MatListModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NextBackButtonComponent,
    CommonModule
  ],
  templateUrl: './offers-step-export.component.html',
  styleUrl: './offers-step-export.component.scss',
})
export class OffersStepExportComponent implements OnInit, OnDestroy {
  @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
  @Input() stepsCompleted!: TStepsCompleted;
  @Input() stepper!: MatStepper;
  @Input() statusName: string;
  @Input() offer: IOffer;
  @Input() mode: TStepperMode;
  remarkControl = new FormControl();
  hideMiddleButton: boolean = false;
  middleButtonId = 'middleButton';
  nextButtonId = 'nextButton';
  nextText: string = 'cabinet.profile.buttons.next';
  styleType: number = 0;
  exportButtonText: string = 'cabinet.offers.exportStep.offerExport';
  middleButtonText: string = 'cabinet.offers.exportStep.cancelDecision';
  nextButtonText = 'cabinet.offers.exportStep.offerExport';
  closeText = 'cabinet.profile.buttons.close';
  nextLoading = false;
  hiddenIcon = true;
  exported = false;
  private offerId: number = this._route.snapshot.params['id'];
  loggedInUserId: string;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _offersService: OffersService, 
    private _route: ActivatedRoute, 
    private _router: Router,
    private _authorizationService: AuthorizationService,
    ) {}
  ngOnInit(): void {
    this.statusName = this.offer.offerStatus.status.name;
    this.loggedInUserId = this._authorizationService.getDecodedToken().sub;
    const subs = this._offersService.requestToUpdateAccessToFields.subscribe(
      (statusName) => {
        this.statusName = statusName;
        this.getOfferReviewedStatus();
        this.isStepEditableByStatusAndMode();
      }
    );
    this._subscriptions.push(subs);

    this.getOfferReviewedStatus();
    this.isStepEditableByStatusAndMode();
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  isStepEditableByStatusAndMode() {
    let ownerId = this.offer.offerStatus.owner?.externalId;

    if(this.mode === 'preview' || ownerId !== this.loggedInUserId) {
      this.hiddenIcon = false;
      this.hideMiddleButton = true;
      this.exported = false;
      this.nextButtonText = this.closeText;
    }
    else if (this.statusName === OfferStatusType.EXPORTED) {
      this.hiddenIcon = false;
      this.hideMiddleButton = true;
      this.exported = true;
      this.nextButtonText = this.nextText;
    }else if (this.statusName === OfferStatusType.REJECTED) {
      this.hiddenIcon = false;
      this.hideMiddleButton = true;
      this.exported = false;
      this.nextButtonText = this.middleButtonText;
    } else {
      this.hiddenIcon = true;
      this.hideMiddleButton = false;
      this.exported = false;
      this.nextButtonText = this.exportButtonText;
    }
  }

  changeStatusToExport() {
    this.nextLoading = true;
    this._offersService.proceedToStatus(this.offerId, OfferStatusType.EXPORTED).subscribe({
      next: () => {
        this.statusName = OfferStatusType.EXPORTED;
        this._offersService.statusChangeMade.emit(OfferStatusType.EXPORTED);
        this.nextLoading = false;
        if (this.stepper) {
          this.handleNextStep();
        }
      },
      error: (err) => {
        this.nextLoading = false;
        console.error(err);
      },
    });
  }
  getOffer() {
    this._offersService.getOffer(this.offerId).subscribe({
      next: (offer) => {
        this.statusName = offer?.offerStatus?.status?.name;
        this.remarkControl.patchValue(offer?.offerStatus?.remark);
        this.remarkControl.updateValueAndValidity();
      },
      error: (err) => console.warn(err),
    });
  }
  changeStatusToReady() {
    this._offersService.proceedToStatus(this.offerId, OfferStatusType.READY).subscribe({
      next: () => {
        this.statusName = OfferStatusType.READY;
        this._offersService.statusChangeMade.emit(OfferStatusType.READY);
        this.stepper.previous();
      },
      error: (err) => console.error(err),
    });
  }
  getOfferReviewedStatus() {
    this._offersService.getOfferStatuses(this.offerId).subscribe({
      next: (data) => {
        for (let index = 0; index < data.length; index++) {
          const elem = data[index];
          if(elem.status.name === OfferStatusType.ACCEPTED || elem.status.name === OfferStatusType.REJECTED) {
            this.remarkControl.patchValue(elem.remark);
            this.remarkControl.disable();
            break;
          }
        }
      },
      error: (err) => {
        console.warn(err);
      },
    });
  }

  handleStatus(buttonPosition: string) {
    let ownerId = this.offer.offerStatus.owner?.externalId;

    if (this.exported || this.mode === 'preview' || ownerId !== this.loggedInUserId) {
      this._router.navigate(['offers/table']);
      return;
    }else if (buttonPosition === this.middleButtonId || (buttonPosition === this.nextButtonId && this.statusName === OfferStatusType.REJECTED)) {
      this.changeStatusToReady();
    }else if (buttonPosition === this.nextButtonId) {
      this.changeStatusToExport();
    }
  }

  handleBackStep() {
    this.stepper.previous();
  }

  handleNextStep() {
    this.stepper.selected.completed = true;
    this.nextLoading = false;
    this.handleUpdateStepsCompleted({
      ...this.stepsCompleted,
      eight: true,
    });
    this._router.navigate(['offers/table']);
  }
}
