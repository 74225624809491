import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { OffersService } from '@core/services';
import { isInputInvalid } from '@core/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { de, id } from 'date-fns/locale';
import { MaritalStatus } from '../../../../core/types/maritial-status';

export const MY_FORMATS = {
  parse: {
    dateInput: ['dd.MM.yyyy', 'dd/MM/yyyy', 'dd,MM,yyyy', 'dd-MM-yyyy'],
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@Component({
  selector: 'app-add-insurant',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    TranslateModule,
    MatDatepickerModule,
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: de },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(MY_FORMATS),
  ],
  templateUrl: './add-insurant.component.html',
  styleUrl: './add-insurant.component.css',
})
export class AddInsurantComponent implements OnDestroy {
  nextLoading = false;
  titleList = [
    { id: 1, value: this._translateService.instant('cabinet.baseDate.titleMale'), sex: 'MALE' },
    { id: 2, value: this._translateService.instant('cabinet.baseDate.titleFemale'), sex: 'FEMALE' },
  ];
  selectedTitle = this.titleList[0];
  private maritalStatusUploadOptions: Map<string, MaritalStatus> = new Map([
    ['0', MaritalStatus.SINGLE],
    ['1', MaritalStatus.MARRIED],
    ['2', MaritalStatus.DIVORCED],
    ['3', MaritalStatus.WIDOWED],
    ['4', MaritalStatus.SEPARATED],
    ['5', MaritalStatus.UNKNOWN],
    ['6', MaritalStatus.REGISTERED_PARTNERSHIP],
    ['7', MaritalStatus.DISSOLVED_PARTNERSHIP],
  ]);
  public maritalStatusList = Object.entries(MaritalStatus);
  collectiveList = ['1', '2', '3'];
  savingPlanList = [
    { value: 0, text: this._translateService.instant('cabinet.offers.plan.savingContributionTab.default') },
    { value: 1, text: this._translateService.instant('cabinet.offers.plan.savingContributionTab.minus') },
    { value: 2, text: this._translateService.instant('cabinet.offers.plan.savingContributionTab.plus') },
  ];
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  private _subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  get firstName() {
    return this.insuranceStepForm.get('firstName');
  }

  get lastName() {
    return this.insuranceStepForm.get('lastName');
  }

  get socialSecurityNo() {
    return this.insuranceStepForm.get('socialSecurityNo');
  }

  get salary() {
    return this.insuranceStepForm.get('salary');
  }

  get title() {
    return this.insuranceStepForm.get('title');
  }

  get collective() {
    return this.insuranceStepForm.get('collective');
  }
  get savingPlan() {
    return this.insuranceStepForm.get('savingPlan');
  }
  get isFirstNameInvalid() {
    return isInputInvalid(this.firstName);
  }

  get isLastNameInvalid() {
    return isInputInvalid(this.lastName);
  }

  get isSalaryInvalid() {
    return isInputInvalid(this.salary);
  }

  get isBvgSavingsInvalid() {
    return isInputInvalid(this.savingsCapitalBvg);
  }

  get isLevelOfEmploymentInvalid() {
    return isInputInvalid(this.levelOfEmployment);
  }

  get isLevelOfDisabilityInvalid() {
    return isInputInvalid(this.levelOfDisability);
  }

  get dateOfBirth() {
    return this.insuranceStepForm.get('dateOfBirth');
  }

  get levelOfDisability() {
    return this.insuranceStepForm.get('levelOfDisability');
  }

  get savingsTotal() {
    return this.insuranceStepForm.get('savingsTotal');
  }

  get levelOfEmployment() {
    return this.insuranceStepForm.get('levelOfEmployment');
  }

  get savingsCapitalBvg() {
    return this.insuranceStepForm.get('savingsCapitalBvg');
  }

  get maritalStatus() {
    return this.insuranceStepForm.get('maritalStatus');
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _offersService: OffersService,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<AddInsurantComponent>,
    @Inject(MAT_DIALOG_DATA) private _dialogData: any
  ) {}

  insuranceStepForm = this._formBuilder.group({
    firstName: [this._dialogData.insurantData ? this._dialogData.insurantData.firstName : '', Validators.required],
    lastName: [this._dialogData.insurantData ? this._dialogData.insurantData.lastName : '', Validators.required],
    dateOfBirth: [
      this._dialogData.insurantData ? this._dialogData.insurantData.dateOfBirth : null,
      Validators.required,
    ],
    socialSecurityNo: [this._dialogData.insurantData ? this._dialogData.insurantData.ssn : ''],
    maritalStatus: [
      this._dialogData.insurantData
        ? this.maritalStatusList.find((s) => s[0] === this._dialogData.insurantData.maritalStatus)?.[0]
        : MaritalStatus.UNKNOWN,
    ],
    title: [
      this._dialogData.insurantData ? this.titleList.find((t) => t.sex === this._dialogData.insurantData.sex).id : 1,
      Validators.required,
    ],
    salary: [
      this._dialogData.insurantData ? this._dialogData.insurantData.salary : '',
      [Validators.pattern(/^(?:[0-9]{1,3}(?:'[0-9]{3})*(?:\.[0-9]+)?|\d+(?:\.\d+)?)$/), Validators.required],
    ],
    savingsCapitalBvg: [
      this._dialogData.insurantData ? this._dialogData.insurantData.savingsCapitalBvg : '',
      [Validators.pattern(/^(?:[0-9]{1,3}(?:'[0-9]{3})*(?:\.[0-9]+)?|\d+(?:\.\d+)?)$/), Validators.required],
    ],
    levelOfEmployment: [
      this._dialogData.insurantData ? this._dialogData.insurantData.levelOfEmployment : '',
      [Validators.pattern(/^(?:[0-9]{1,3}(?:'[0-9]{3})*(?:\.[0-9]+)?|\d+(?:\.\d+)?)$/), Validators.required, Validators.max(100)],
    ],
    levelOfDisability: [
      this._dialogData.insurantData ? this._dialogData.insurantData.levelOfDisability : '',
      [Validators.required, Validators.max(100)],
    ],
    savingsTotal: [
      this._dialogData.insurantData ? this._dialogData.insurantData.savingsTotal : null,
      [Validators.pattern(/^[0-9]{1,3}(?:'?[0-9]{3})*(?:\.[0-9]+)?$/), Validators.required],
    ],
    collective: [this._dialogData.insurantData ? this._dialogData.insurantData.collective : '1'],
    savingPlan: [this._dialogData.insurantData ? this._dialogData.insurantData.savingPlan : 0],
  });

  isEditMode = false;

  ngOnInit(): void {
    this.isEditMode = !!this._dialogData.insurantData;

    if (this._dialogData.insurantData !== undefined) {
      this.insuranceStepForm.controls.savingPlan.setValue(parseInt(this._dialogData.insurantData.savingPlan));
    }
  }

  convertDate(dateParam) {
    return new Date(dateParam.getTime() - dateParam.getTimezoneOffset() * 60000);
  }

  handleInsuranceSubmit(): void {
    if (this.insuranceStepForm.invalid) return;

    this.nextLoading = true;
    this.insuranceStepForm.disable();

    const formValues = {
      savingsCapitalBvg: Number(
        parseFloat(
          typeof this.savingsCapitalBvg?.value === 'string' && this.savingsCapitalBvg?.value.indexOf("'") > -1
            ? this.savingsCapitalBvg?.value.replace(/'/g, '')
            : this.savingsCapitalBvg?.value
        ).toFixed(2)
      ),
      collective: this.collective.value,
      savingPlan: this.savingPlan.value,
      dateOfBirth: this.convertDate(this.dateOfBirth.value),
      levelOfEmployment: +this.levelOfEmployment.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      maritalStatus: this.maritalStatus?.value,
      savingsTotal: Number(
        parseFloat(
          typeof this.savingsTotal?.value === 'string' && this.savingsTotal?.value.indexOf("'") > -1
            ? this.savingsTotal?.value.replace(/'/g, '')
            : this.savingsTotal?.value
        ).toFixed(2)
      ),
      salary: Number(
        parseFloat(
          typeof this.salary?.value === 'string' && this.salary?.value.indexOf("'") > -1
            ? this.salary?.value.replace(/'/g, '')
            : this.salary.value
        ).toFixed(2)
      ),
      sex: this.titleList.find((value) => this.title.value == value.id).sex,
      ssn: this.socialSecurityNo?.value,
      levelOfDisability: +this.levelOfDisability.value,
    };

    if (this._dialogData.insurantData) {
      const subs = this._offersService
        .updatePerson(this._dialogData.offerId, { ...formValues, id: this._dialogData.insurantId })
        .subscribe(() => {
          this.nextLoading = false;
          this.dialogRef.close();
        });
      this._subscriptions.push(subs);
    } else {
      const subs = this._offersService.createPerson(this._dialogData.offerId, formValues).subscribe(() => {
        this.nextLoading = false;
        this.dialogRef.close();
      });
      this._subscriptions.push(subs);
    }
  }

  handleCloseDialog(event: any): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  showEditText(isEditMode: boolean): string {
    return isEditMode ?
      'cabinet.profile.buttons.editInsurant' :
      'cabinet.profile.buttons.createNewInsurant';
  }
}
