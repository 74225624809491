<div class="container">
    <div class="documents">
        @for(config of documentListCopy; track config; let index = $index ){
        <div [ngClass]="index === documentListCopy.length - 1 ? 'no-document' : 'document'">
            <div class="document-header">
                <div class="title">
                    {{ config.title }}
                </div>
                <div class="actions">
                    <button
                        mat-raised-button
                        *ngIf="config.types[0]?.sanitizedUrl === undefined"
                        [ngClass]="isPreviewMode || !isEditable ? 'disabled-gen-btn' : 'generate-btn'"
                        (click)="generatePDF(config, index)"
                        [class.disabled-btn]="isPreviewMode || !isEditable || nextLoading"
                        [disabled]="isPreviewMode || !isEditable"
                    >
                        {{ 'cabinet.profile.buttons.generate' | translate }}
                    </button>
                    <button
                        mat-raised-button
                        *ngIf="config.types[0]?.sanitizedUrl !== undefined"
                        [ngClass]="isPreviewMode || !isEditable ? 'disabled-gen-btn' : 'remove-btn'"
                        [class.disabled-btn]="isPreviewMode || !isEditable"
                        [disabled]="isPreviewMode || !isEditable || nextLoading"
                        (click)="removeGeneratedDocuments(config)"
                    >
                        {{ 'cabinet.profile.buttons.remove' | translate }}
                    </button>
                </div>
            </div>
            @if(config.types[0]?.nextLoading) {
            <div class="spinner">
                <app-spinner [spinnerSize]="'30'"></app-spinner>
            </div>
            }

            <div *ngIf="config.types[0]?.sanitizedUrl !== undefined" class="generated-docs">
                <div>
                    @for(file of config.types; track file){
                    <div class="generated-box">
                        <div class="generated-box--title">
                            @if(file.docType === 'pdf'){
                            <img class="doc-icon" src="../../../../assets/img/pdf_icon.svg" alt="" />
                            }@else if (file.docType == 'xlsx'){
                            <img class="doc-icon" alt="excel" src="../../../../assets/img/xls.svg" />
                            }@else {
                            <img class="doc-icon" src="../../../../assets/img/doc_icon.svg" alt="" />
                            }

                            <p class="generated-box--title-txt">
                                {{ file.title }}
                            </p>
                        </div>
                        <div class="generated-box--actions">
                            <button mat-button class="document-look shortcuts" (click)="download(file, $event)">
                                <mat-icon class="action-icon">download</mat-icon>
                                {{ 'cabinet.profile.buttons.download' | translate }}
                            </button>
                            <button
                                mat-button
                                class="document-look shortcuts"
                                (click)="openPdfWithWatermark(file, $event)"
                                *ngIf="file.docType === 'pdf'"
                            >
                                <mat-icon class="action-icon">visibility</mat-icon>
                                {{ 'cabinet.profile.buttons.preview' | translate }}
                            </button>
                            <!-- <p *ngIf="file.docType !== 'pdf'" style="width: 30%"></p> -->
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>

        }
    </div>
    <next-back-button
        [nextLoading]="nextLoading"
        (nextButton)="handleNextStep()"
        (backButton)="handleBackStep()"
        [isNextButtonDisabled]="isNextButtonDisabled"
        [isMiddleButtonDisabled]="isMiddleButtonDisabled"
        (middleButton)="documentListDownload()" 
        [hideMiddleButton]="false"
        [styleType]= 2
        [hiddenIcon]="hiddenIcon"
        [middleButtonText]="'cabinet.profile.buttons.donwloadAllDocuments'"
  ></next-back-button>
</div>
