<fieldset class="cat-fieldset">
  <legend>{{ 'cabinet.profile.employment.collectiv' | translate }}</legend>
  <div>
    <form [formGroup]="categoryNames">
      <div class="categoryNames">
        <mat-form-field appearance="outline" class="plan-form-item" fxFill>
          <mat-label class="fieldLabel">{{"cabinet.offers.plan.generalTab.categoryNames" | translate: {category: 1} }}</mat-label>
          <input matInput formControlName="category1" type="text" />
          <mat-error *ngIf="category1.errors?.['required']">{{"cabinet.offers.plan.generalTab.categoryRequired" | translate: {category: 1} }}</mat-error>
          <mat-error *ngIf="category1.errors?.['minLength']">{{ "cabinet.offers.plan.generalTab.endOfContributionError" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="plan-form-item" fxFill>
          <mat-label class="fieldLabel">{{"cabinet.offers.plan.generalTab.categoryNames" | translate: {category: 2} }}</mat-label>
          <input matInput formControlName="category2" type="text"/>
          <mat-error *ngIf="category2.errors?.['required']">{{"cabinet.offers.plan.generalTab.categoryRequired" | translate: {category: 2} }}</mat-error>
          <mat-error *ngIf="category2.errors?.['minLength']">{{ "cabinet.offers.plan.generalTab.endOfContributionError" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="plan-form-item" fxFill>
          <mat-label class="fieldLabel">{{"cabinet.offers.plan.generalTab.categoryNames" | translate: {category: 3} }}</mat-label>
          <input matInput formControlName="category3" type="text" />
          <mat-error *ngIf="category3.errors?.['required']">{{"cabinet.offers.plan.generalTab.categoryRequired" | translate: {category: 3} }}</mat-error>
          <mat-error *ngIf="category3.errors?.['minLength']">{{ "cabinet.offers.plan.generalTab.endOfContributionError" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</fieldset>
<fieldset class="cat-fieldset">
  <legend>{{ 'cabinet.profile.employment.savingPlan' | translate }}</legend>
  <div>
    <form [formGroup]="savingPlanNames">
      <div class="categoryNames">
        <mat-form-field appearance="outline" class="plan-form-item">
          <mat-label class="fieldLabel">{{"cabinet.offers.plan.savingContributionTab.standardPlan" | translate }}</mat-label>
          <input matInput formControlName="category1" type="text" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="plan-form-item">
          <mat-label class="fieldLabel">{{"cabinet.offers.plan.savingContributionTab.minusPlan" | translate }}</mat-label>
          <input matInput formControlName="category2" type="text" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="plan-form-item">
          <mat-label class="fieldLabel">{{"cabinet.offers.plan.savingContributionTab.plusPlan" | translate }}</mat-label>
          <input matInput formControlName="category3" type="text" />
        </mat-form-field>
      </div>
    </form>
  </div>
</fieldset>
  <div class="insurant-button-containter">
    <button class="medium-action-button" type="button" color="accent" mat-raised-button (click)="openAddInsurantDialog()"
      [disabled]="isAddButtonDisabled">
      {{"cabinet.navigationWizzard.addInsurant" | translate}}
    </button>
    <button class="medium-action-button" type="button" mat-raised-button (click)="handleDeleteAllInsurants()"
    [disabled]="isDeleteAllButtonDisabled">
    {{"cabinet.navigationWizzard.deleteInsurants" | translate}}
    </button>
  </div>
  <div class="dragAndDropField" appDragAndDrop (fileDropped)="onFileDropped($event)" [disabled]="!isEditable || isPreviewMode">
    <p class="dragAndDropText">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="upload"></mat-icon>
        <a (click)="chooseFile()" [class.link]="isEditable" [class.link-disabled]="isPreviewMode || !isEditable">
            {{ showCorrectFileUploadTextPart1() | translate }}
        </a>
        <span> {{ showCorrectFileUploadTextPart2() | translate }}</span>
    </p>
    <input type="file" #csvReader multiple style="display: none" id="file-input-form" (change)="handleFileInput($event)"
        accept=".xlsx" [disabled]="!isEditable || isPreviewMode"/>
  </div>
  <div>
    <mat-error *ngIf="insurantsTableData?.['empty']" class="alignTextRight">{{ "cabinet.offers.plan.insurantTab.insurantListEmptyError" | translate }}</mat-error>
  </div>
  <p class="exampleDownloadText">
    <a (click)="downloadExampleFile()" class="donwloadExample">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-download">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
      </svg>
    </a>
    {{ "cabinet.fileupload.downloadExampleFileText" | translate }}
  </p>
  <app-insurants-table [isEditable]="isEditable" [insurantsTableData]="insurantsTableData" [isPreviewMode]="isPreviewMode" [savingPlanNames]="savingPlanNames"
    (onEditInsurant)="handleEditInsurant($event)" (tableChange)="handleTableChange($event)"
    (deleteInsurant)="handleDeleteInsurant($event)"></app-insurants-table>
  <next-back-button
    [nextLoading]="nextLoading"
    [isNextButtonDisabled]="isNextButtonDisabled"
    (nextButton)="handleNextStep()"
    (backButton)="handleBackStep()"
  ></next-back-button>