@if(nextLoading) {
<div class="spinner-box">
  <mat-spinner diameter="90" style="margin: 0 auto"></mat-spinner>
</div>
} @else {
<div class="table-flow">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.tableFields.title" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <p class="reportDetails3">
          @if(row.docType === 'pdf'){
          <img
            class="doc-icon"
            src="../../../../assets/img/pdf_icon.svg"
            alt=""
          />
          }@else if (row.docType == 'xlsx'){
          <img
            class="doc-icon"
            alt="excel"
            src="../../../../assets/img/xls.svg"
          />
          }@else {
          <img
            class="doc-icon"
            src="../../../../assets/img/doc_icon.svg"
            alt=""
          />
          }
          {{ row.title }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.tableFields.dateCreated" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.createdDate }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.tableFields.actions" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-style">
          <a
            class="reportDetails3 shortcuts"
            [href]="row.sanitizedUrl"
            download
          >
            <mat-icon
              src="assets/img/download-icon.png"
              alt="Download Icon"
              class="action-icon"
              >download</mat-icon
            >
            {{ "cabinet.profile.buttons.download" | translate }}
          </a>
          @if(row.docType === 'pdf'){
          <a
            class="reportDetails3 shortcuts"
            href=""
            (click)="preview(row, $event)"
          >
            <mat-icon alt="Download Icon" class="action-icon"
              >visibility</mat-icon
            >
            {{ "cabinet.profile.buttons.preview" | translate }}
          </a>
          }
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<!-- Feedback -->
<div class="fieldset-wrap">
  <div class="fieldset">
    <div class="fieldset__title_wrap">
      <p class="title">
        {{ "auth.userManagement.clientFeedback" | translate }}
      </p>
    </div>
    <div class="form-groups">
      <mat-form-field>
        <textarea matInput [formControl]="remarkControl" rows="5"></textarea>
        <mat-error *ngIf="remarkControl.hasError('required')">
          {{ "auth.errors.fieldRequired" | translate }}</mat-error
        >
      </mat-form-field>
    </div>
  </div>
</div>
<next-back-button
  (nextButton)="handleStatus(nextButtonId)"
  (backButton)="handleBackStep()"
  (middleButton)="handleStatus(middleButtonId)"
  [hideMiddleButton]="hideMiddleButton"
  [middleButtonText]="middleButtonText"
  [nextButtonText]="nextButtonText"
  [nextLoading]="nextLoading"
  [styleType]="styleType"
  [hiddenIcon]="hiddenIcon"
></next-back-button>
}
