import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IOffer, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { OffersService, DialogService } from '@core/services';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { ConfirmationDialogComponent } from '@shared/components';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-change-owner-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    TranslateModule,
    FormsModule,
    AsyncPipe,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  templateUrl: './change-owner-dialog.component.html',
  styleUrl: './change-owner-dialog.component.css',
})
export class ChangeOwnerDialogComponent implements OnInit {

  userList = [];
  ownerControl = new FormControl(null, Validators.required);

  constructor(
    private _offersService: OffersService, 
    private _router: Router,
    public dialogRef: MatDialogRef<ChangeOwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { offer: IOffer }
  ) {}

  ngOnInit(): void {
      this.getAllUsers();
  }

  onConfirm(): void {
    this._offersService.changeOwner(this.data.offer.id, this.data.offer.offerStatus.status.name, this.ownerControl.value).subscribe({
      next: data => {
        this.dialogRef.close(this.ownerControl.value);
        this._router.navigate(['/offers/table']);
      },
      error: err => console.error(err)
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  getAllUsers() {
    this._offersService.getAllReviewers(this.data.offer.id).subscribe({
      next: (res) => {
        this.userList = res.filter(elem => elem.id !== this.data.offer.offerStatus.owner.id && elem.id !== this.data.offer.offerStatus.reviewer?.id);
        this.ownerControl.setValue(this.userList[0]?.id);
      },
      error: (err) => {
        console.warn(err);
      },
    });
  }
}
