<div class="plan-form upper-form">
  <form [formGroup]="codesFormBSTFK" class="plan-form-item">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.riskPerformanceTab.typeOfCalculationOfDeathCapital"
          | translate
      }}</mat-label>
      <mat-select formControlName="BSTFK" [value]="BSTFK.value">
        <mat-option
          *ngFor="let value of typeOfCalculationDeathCapital"
          [value]="value.value"
        >
          {{ value.description }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="BSTFK?.invalid">{{
        "cabinet.offers.plan.riskPerformanceTab.typeOfCalculationOfDeathCapital"
          | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div class="plan-form">
  <form [formGroup]="inflationFundTariffForm" class="plan-form-item">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.riskPerformanceTab.inflationFundRates" | translate
      }}</mat-label>
      <input matInput formControlName="employerShare" type="number" /><mat-icon
        matSuffix
        >%</mat-icon
      >
      <mat-error *ngIf="employerShare?.invalid">{{
        "cabinet.offers.plan.riskPerformanceTab.inflationFundRatesError"
          | translate
      }}</mat-error>
    </mat-form-field>
  </form>
  <form [formGroup]="levyTariffForm" class="plan-form-item">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.riskPerformanceTab.tariffLevyContribution"
          | translate
      }}</mat-label>
      <input matInput formControlName="total" type="number" /><mat-icon
        matSuffix
        >%</mat-icon
      >
      <mat-error *ngIf="total?.invalid">{{
        "cabinet.offers.plan.riskPerformanceTab.tariffLevyContributionError"
          | translate
      }}</mat-error>
    </mat-form-field>
  </form>
  <form [formGroup]="codesFormBSWARTEF" class="plan-form-item">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.financingTab.waitingTimeForAdminsitrativeCosts"
          | translate
      }}</mat-label>
      <mat-select ngDefaultControl formControlName="BSWARTEF" required>
        <mat-option
          *ngFor="let value of waitingTimesForAdministrationCost"
          [value]="value.value"
        >
          {{ value.description }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="BSWARTEF?.invalid">{{
        "cabinet.offers.plan.financingTab.waitingTimeForAdminsitrativeCostsError"
          | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div class="title-plan">
  {{ "cabinet.offers.plan.riskPerformanceTab.riskRates" | translate }}
</div>

<div class="tariffRows">
  <div class="form-wrapper">
    <div class="cat-title">
      {{ "cabinet.offers.plan.category1" | translate }}
    </div>
    <form [formGroup]="generalForm" class="planFieldColumn plan-form-item">
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapital"
            | translate
        }}</mat-label>
        <input matInput formControlName="BSTFKPROK1" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="BSTFKPROK1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapitalError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
    <form
      [formGroup]="riskTariffCat1Form"
      class="planFieldColumn plan-form-item"
    >
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployer" | translate
        }}</mat-label>
        <input matInput formControlName="employer" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="riskRateEmployerCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployerError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployee" | translate
        }}</mat-label>
        <input matInput formControlName="employee" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="riskRateEmployeeCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployeeError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateTotal" | translate
        }}</mat-label>
        <input
          matInput
          formControlName="riskRateTotal"
          type="number"
          readonly
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="riskRateTotalCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateTotalError"
            | translate
        }}</mat-error>
      </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfDisabilitypension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="invalidityPensionRate"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="rateOfDisabilitypensionCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfDisabilitypensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfPartnerpension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="spousalPension"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="spousalPensionCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfPartnerpensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.disabilityChildPension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="disabilityChildPension"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="disabilityChildPensionCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.disabilityChildPensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.administrativeCostsContribution"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="administrativeRate"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="administrativeCostsContributionCat1?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.administrativeCostsContributionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.financingTab.shareOfAdministrationCostsEmployer"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="shareOfAdministrativeCostsEmployer"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="shareOfAdministrativeCostsEmployerCat1?.invalid">{{
          "cabinet.offers.plan.financingTab.shareOfAdministrationCostsEmployerError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  @if (categories === 2 || categories === 3) {
  <div class="form-wrapper">
    <div class="cat-title">
      {{ "cabinet.offers.plan.category2" | translate }}
    </div>
    <form [formGroup]="generalForm" class="planFieldColumn plan-form-item">
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapital"
            | translate
        }}</mat-label>
        <input matInput formControlName="BSTFKPROK2" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="BSTFKPROK2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapitalError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
    <form
      [formGroup]="riskTariffCat2Form"
      class="planFieldColumn plan-form-item"
    >
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">
          {{
            "cabinet.offers.plan.riskPerformanceTab.riskRateEmployer"
              | translate
          }}</mat-label
        >
        <input matInput formControlName="employer" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="riskRateEmployerCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapitalError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployee" | translate
        }}</mat-label>
        <input matInput formControlName="employee" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="riskRateEmployeeCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployeeError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateTotal" | translate
        }}</mat-label>
        <input
          matInput
          formControlName="riskRateTotal"
          type="number"
          readonly
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="riskRateTotalCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateTotalError"
            | translate
        }}</mat-error>
      </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfDisabilitypension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="invalidityPensionRate"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="rateOfDisabilitypensionCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfDisabilitypensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfPartnerpension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="spousalPension"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="spousalPensionCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfPartnerpensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.disabilityChildPension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="disabilityChildPension"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="disabilityChildPensionCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.disabilityChildPensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.administrativeCostsContribution"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="administrativeRate"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="administrativeCostsContributionCat2?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.administrativeCostsContributionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.financingTab.shareOfAdministrationCostsEmployer"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="shareOfAdministrativeCostsEmployer"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="shareOfAdministrativeCostsEmployerCat2?.invalid">{{
          "cabinet.offers.plan.financingTab.shareOfAdministrationCostsEmployerError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  } @if (categories === 3) {
  <div class="form-wrapper">
    <div class="cat-title">
      {{ "cabinet.offers.plan.category3" | translate }}
    </div>
    <form [formGroup]="generalForm" class="planFieldColumn plan-form-item">
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapital"
            | translate
        }}</mat-label>
        <input matInput formControlName="BSTFKPROK3" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="BSTFKPROK3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.lumpSumDeathCapitalError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
    <form
      [formGroup]="riskTariffCat3Form"
      class="planFieldColumn plan-form-item"
    >
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployer" | translate
        }}</mat-label>
        <input matInput formControlName="employer" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="riskRateEmployerCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployerError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployee" | translate
        }}</mat-label>
        <input matInput formControlName="employee" type="number" /><mat-icon
          matSuffix
          >%</mat-icon
        >
        <mat-error *ngIf="riskRateEmployeeCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateEmployeeError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateTotal" | translate
        }}</mat-label>
        <input
          matInput
          formControlName="riskRateTotal"
          type="number"
          readonly
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="riskRateTotalCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.riskRateTotalError"
            | translate
        }}</mat-error>
      </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfDisabilitypension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="invalidityPensionRate"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="rateOfDisabilitypensionCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfDisabilitypensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfPartnerpension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="spousalPension"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="spousalPensionCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.rateOfPartnerpensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.disabilityChildPension"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="disabilityChildPension"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="disabilityChildPensionCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.disabilityChildPensionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.riskPerformanceTab.administrativeCostsContribution"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="administrativeRate"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="administrativeCostsContributionCat3?.invalid">{{
          "cabinet.offers.plan.riskPerformanceTab.administrativeCostsContributionError"
            | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.financingTab.shareOfAdministrationCostsEmployer"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="shareOfAdministrativeCostsEmployer"
          type="number"
        /><mat-icon matSuffix>%</mat-icon>
        <mat-error *ngIf="shareOfAdministrativeCostsEmployerCat3?.invalid">{{
          "cabinet.offers.plan.financingTab.shareOfAdministrationCostsEmployerError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  }
</div>