import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const loginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const swarmAuthToken = 'swarm-auth-token';
  const swarmRefreshToken = 'swarm-refresh-token';

  if (localStorage.getItem(swarmAuthToken) && localStorage.getItem(swarmRefreshToken)) {
    router.navigate(['offers']);
    return false;
  }

  return true;
};
