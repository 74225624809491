import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatPaginatorIntlDe } from '@core/helpers/paginator-translate';
import { IUser } from '@core/interfaces/users/user.interface';
import { DialogService, SnackbarService } from '@core/services';
import { UserService } from '@core/services/user/user.service';
import { TStepperMode } from '@core/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, SpinnerComponent } from '@shared/components';
import { Subscription, switchMap } from 'rxjs';
@Component({
  selector: 'app-list-users',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule,
    SpinnerComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe }],
  templateUrl: './list-users.component.html',
  styleUrl: './list-users.component.scss',
})
export class ListUsersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  dataSource: MatTableDataSource<IUser[]> = new MatTableDataSource<IUser[]>([]);
  displayedColumns = ['email', 'firstName', 'lastName', 'roles', 'foundations', 'actions'];
  tenant: string;
  pageNumber: number = 0;
  pageSize: number = 10;
  tableEmpty = false;
  totalElements: number;
  pageEvent: PageEvent;
  page;
  userData = [];
  sortTrack;
  private _subscriptions: Subscription[] = [];
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _router: Router,
    private _dialogService: DialogService,
    private translate: TranslateService,
    private _snackbarService: SnackbarService
  ) {}
  ngOnInit(): void {
    this.tenant = localStorage.getItem('tenant');
    this.getAllUsers();
  }

  getAllUsers(page?) {
    this.isLoading = true;
    this.dataSource = null;
    this.tableEmpty = false;
    this._userService.getUsers(page).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(data.content);
        this.totalElements = data.totalElements;
        this.pageSize = data.pageable.pageSize;
        this.pageNumber = data.pageable.pageNumber;
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }
  showArrayValues(arr) {
    return arr.join(',');
  }
  handleEditUsers(id: string) {
    this._router.navigate([`../edit/${id}`], {
      relativeTo: this._activatedRoute,
    });
  }
  presentArrayAsString(arr) {
    let newArr = arr.map((data) => {
      if (data.name === 'OFFERTOOL_ADMIN') {
        return this.translate.instant('auth.userManagement.admin');
      }
      if (data.name === 'OFFERTOOL_READ_ONLY') {
        return this.translate.instant('auth.userManagement.viewer');
      }
      if (data.name === 'OFFERTOOL_EDIT') {
        return this.translate.instant('auth.userManagement.user');
      }
      return data.name;
    });
    return newArr.join(', ');
  }
  handleDeleteUser(email: string, id: string) {
    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('cabinet.tableFields.deleteUserInfo', { email: email }),
      },
      width: '300px',
    });

    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        const deleteUserSubscription = this._userService
          .deleteUser(id, this.tenant)
          .pipe(switchMap(() => this._userService.getUsers(this.page)))
          .subscribe((response) => {
            this._snackbarService.handleOpenSnackbar(
              this.translate.instant('cabinet.notifications.userDeleteSuccess', { id: email }),
              'Close',
              'success'
            );
            this.getAllUsers();
          });

        this._subscriptions.push(deleteUserSubscription);
      }
    };
    this._dialogService.handleDialogClose(onDialogClose);
  }
  getServices(event?: PageEvent) {
    this.page = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    };

    this.getAllUsers(this.page);
    return event;
  }
  sortDirectionCheck(sort: MatSort) {
    return (sort.direction = sort.direction === '' ? 'asc' : sort.direction);
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());

    this._dialogService.handleDialogUnsubscribe();
  }
}
