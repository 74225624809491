<div class="users table-container">
  <h1 class="title">{{ "cabinet.profile.title" | translate }}</h1>
  <div class="create-btn-wrap">
    <a routerLink="/users/new">
      <button
        mat-button
        color="primary"
        class="major-action-button"
        mat-raised-button
      >
        {{ "cabinet.profile.buttons.createNewUser" | translate }}
      </button>
    </a>
  </div>
  <div class="example-container mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table responsive-lg"
      aria-label="Elements"
      class="table"
    >
      <!-- Position Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          {{ "auth.userManagement.email" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>
          {{ "auth.userManagement.firstName" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>
          {{ "auth.userManagement.lastName" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef>
          {{ "auth.userManagement.roles" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ presentArrayAsString(element?.role) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="foundations">
        <th mat-header-cell *matHeaderCellDef>
          {{ "auth.userManagement.foundations" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ presentArrayAsString(element.foundation) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="edit-button"
            mat-icon-button
            (click)="handleEditUsers(element.externalId)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            class="delete-button"
            mat-icon-button
            (click)="handleDeleteUser(element.email, element.externalId)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    @if (isLoading) {
    <app-spinner></app-spinner>
    }
    <mat-paginator
      class="paginator"
      [length]="totalElements"
      [pageSize]="pageSize"
      [pageIndex]="pageNumber"
      [pageSizeOptions]="[10, 20, 30, 50, 100]"
      (page)="pageEvent = getServices($event)"
    ></mat-paginator>
  </div>
</div>
