import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'clan-auth';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _snackbarService: SnackbarService,
    private _translateService: TranslateService,
    private _authService: AuthenticationService,
    private _router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.translateErrorCode(error.status);

        if (error instanceof HttpErrorResponse && request.url.includes('/refresh') && error.status === 401) {
          this._snackbarService.handleOpenSnackbar(
            this._translateService.instant('auth.errors.sessionExpired', { message: errorMessage }),
            this._translateService.instant('cabinet.navigation.close'),
            'error'
          );
          this._authService.logout().subscribe(() => {
            this._router.navigate(['/auth']);
          });
        }
        let jsonError = error.error;
        if (!error.error?.errorCode) {
          jsonError = JSON.parse(error.error)
        }
        if (jsonError?.errorCode >= 0) {
          const errorMessage = this.translateErrorCode(jsonError.errorCode);
          if (jsonError.errorCode === 5) {
            this._snackbarService.handleOpenSnackbar(
              this._translateService.instant('cabinet.errors.baseData.sectorError'),
              this._translateService.instant('cabinet.navigation.close'),
              'error'
            );
          } else {
            this._snackbarService.handleOpenSnackbar(
              this._translateService.instant('cabinet.notifications.errorOccured', { message: errorMessage }),
              'Close',
              'error'
            );
          }
        }
        return throwError(() => error);
      })
    );
  }

  translateErrorCode(code) {
    const key = `auth.errorCodes.${code}`;
    const translation = this._translateService.instant(key);

    if (translation.includes(key)) {
      return this._translateService.instant(`auth.errorCodes.0`);
    }
    return translation;
  }
}
