import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { OffersService } from '@core/services';
import { SpinnerComponent } from '@shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { MoneyPipe } from '@core/pipes/money/money.pipe';
import { MatPaginatorIntlDe } from '@core/helpers/paginator-translate';

@Component({
  selector: 'app-calc-step-two',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule,
    SpinnerComponent,
    CommonModule,
    TranslateModule,
    MoneyPipe,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe }],
  templateUrl: './calc-step-two.component.html',
  styleUrls: ['./calc-step-two.component.css', './calc-step-two.component.css'],
})
export class CalcStepTwoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private _calculations: any;
  @Input() offerId;
  totalElements: number = 0;
  originalData: any[] = []; // Store the original data
  paginatedData: any[] = []; // Store the data for the current page
  dataSource: MatTableDataSource<any>;
  pageNumber: number = 0;
  pageSize: number = 20;
  tableLoading = true;
  direction;
  column;
  displayedColumns = [
    'name',
    'birthday',
    'gender',
    'maritalStatus',
    'category',
    'savingPlan',
    'bvgAge',
    'degreeOfEmployment',
    'grossSalary',
    'insuredSalary',
    'disabilityPension',
    'spousePension',
    'orphansPension',
    'oldAgePension',
    'childsPension',
    'projectedRetirementAssets',
    'savingAmount',
    'savingAmountBvg',
  ];

  constructor(private _offersService: OffersService) {}

  ngOnInit(): void {
    this.handlePageEvent({ pageIndex: this.pageNumber, pageSize: this.pageSize } as PageEvent);
  }
  get calculations(): any {
    return this._calculations;
  }
  handleSortChange(column) {
    if (this.direction == 'desc') {
      this.direction = 'asc';
    } else {
      this.direction = 'desc';
    }
    this.column = column;
    this._offersService
      .getBenefits(this.offerId, { pageNumber: this.pageNumber, pageSize: this.pageSize }, this.column, this.direction)
      .subscribe((data) => {
        this.renderTableData(data);
      });
  }
  renderTableData({ content, totalElements, pageable: { pageSize, pageNumber } }) {
    this.tableLoading = true;
    this.dataSource = new MatTableDataSource(content);
    this.totalElements = totalElements;
    this.tableLoading = false;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
  paginate(array, page_size, page_number) {
    const startIndex = page_number * page_size;
    const endIndex = Math.min(startIndex + page_size, array.length);

    return array.slice(startIndex, endIndex);
  }
  handlePageEvent(event: PageEvent): void {
    this._offersService
      .getBenefits(this.offerId, { pageNumber: event.pageIndex, pageSize: event.pageSize }, this.column, this.direction)
      .subscribe((data) => {
        this.renderTableData(data);
      });
  }
}
