import {Component, Input} from '@angular/core';
import {MatCard} from "@angular/material/card";

@Component({
  selector: 'app-colored-status-box',

  standalone: true,
  imports: [
    MatCard
  ],
  templateUrl: './colored-status-box.component.html',
  styleUrl: './colored-status-box.component.css'
})
export class ColoredStatusBoxComponent {
  @Input() text: string
  @Input() textColor: string
  @Input() backgroundColor: string

}
