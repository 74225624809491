import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { IOffer, IOfferStatus, OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { IUser } from '@core/interfaces/users/user.interface';
import { DialogService, OffersService } from '@core/services';
import {
    THandleUpdateStepper,
    THandleUpdateStepsCompleted,
    TStepperMode,
    TStepsCompleted,
    TStepsReviewerCompleted,
} from '@core/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ConfirmationDialogComponent } from '@shared/components';
import { MatInputModule } from '@angular/material/input';
import { Status } from 'models';

@Component({
    selector: 'app-offers-step-reviewer',
    standalone: true,
    imports: [
        MatStepperModule,
        FormsModule,
        AsyncPipe,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        NextBackButtonComponent,
        MatInputModule,
    ],
    templateUrl: './offers-step-reviewer.component.html',
    styleUrl: './offers-step-reviewer.component.scss',
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
})
export class OffersStepReviewerComponent implements OnInit, OnDestroy {
    @Input() stepper!: MatStepper;
    @Input() mode!: TStepperMode;
    @Input() stepsCompleted!: TStepsCompleted;
    @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
    @ViewChild('stepperReviewer') stepperReviewer: MatStepper;
    @Input() offer: IOffer;
    reviewerControl = new FormControl(null, Validators.required);
    remarkControl = new FormControl(null, Validators.required);
    reviewerName: string;
    nextLoading = false;
    reviewerList = [];
    offerId;
    isPreviewMode: boolean;
    statusName: OfferStatusType;
    hideMiddleButton: boolean = true;
    showRemarkInput: boolean = false;
    hideNextButton: boolean = false;
    loggedInUserId: string;
    selectedIndex: number = 0;
    styleType: number = 0;
    offerReviewed: IOfferStatus;

    middleButtonId = 'middleButton';
    nextButtonId = 'nextButton';

    editOfferText: string = 'cabinet.offers.plan.reviewStep.editOffer';
    approveText: string = 'cabinet.offers.plan.reviewStep.approve';
    rejectText: string = 'cabinet.offers.plan.reviewStep.reject';
    assignText: string = 'cabinet.offers.plan.reviewStep.assign';
    nextText: string = 'cabinet.profile.buttons.next';
    closeText: string = 'cabinet.profile.buttons.close';
    reviewApprovedText: string = 'cabinet.offers.plan.reviewStep.reviewApproved';
    reviewRejectedText: string = 'cabinet.offers.plan.reviewStep.reviewRejected';
    offerApprovedText: string = 'cabinet.offers.plan.reviewStep.offerApproved';
    offerRejectedText: string = 'cabinet.offers.plan.reviewStep.offerRejected';

    reviewApprovedRejected: string = 'cabinet.offers.plan.reviewStep.reviewApprovedRejectedTitle';
    offerApprovedRejected: string = 'cabinet.offers.plan.reviewStep.offerApproved';

    middleButtonText: string = this.assignText;
    nextButtonText: string = this.nextText;
    closeButtonText: string = this.closeText;
    private _subscriptions: Subscription[] = [];

    stepsCompletedReviewer: TStepsReviewerCompleted = {
        first: false,
        second: false,
        third: false,
    };

    constructor(
        private _offersService: OffersService,
        private _router: Router,
        private _authorizationService: AuthorizationService,
        private _dialogService: DialogService,
        private _translateService: TranslateService
    ) {}

    ngOnInit() {
        this.isPreviewMode = this.mode == 'preview';
        this.statusName = this.offer.offerStatus.status.name;
        this.offerId = this.offer.id;
        this.loggedInUserId = this._authorizationService.getDecodedToken().sub;
        this.isStepEditableByStatusAndMode(this.statusName);
        this.switchToStepByStatus();

        const subs = this._offersService.reloadReviewStepRequested.subscribe((data) => {
            this.statusName = this.offer.offerStatus.status.name;
            this.isStepEditableByStatusAndMode(this.statusName);
            this.switchToStepByStatus();
        });
        this._subscriptions.push(subs);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    get isNextButtonDisabled() {
        let reviewerId = this.offer.offerStatus.reviewer?.externalId;
        let ownerId = this.offer.offerStatus.owner?.externalId;

        switch (this.statusName) {
            case 'CALCULATED':
                if (this.loggedInUserId !== ownerId) {
                    return true;
                }
                return !this.reviewerControl.value;

            case 'IN_REVIEW':
                if (this.loggedInUserId !== reviewerId || this.mode === 'preview') {
                    return false;
                }
                return !this.remarkControl.value;
        }
        return false;
    }

    get isMiddleButtonDisabled() {
        let reviewerId = this.offer.offerStatus.reviewer?.externalId;

        if (this.statusName === 'IN_REVIEW') {
            if (this.loggedInUserId !== reviewerId) {
                return true;
            }
            return !this.remarkControl.value;
        }

        return false;
    }

    isStepEditableByStatusAndMode(statusName: string): boolean {
        let reviewerId = this.offer.offerStatus.reviewer?.externalId;
        let ownerId = this.offer.offerStatus.owner?.externalId;

        switch (this.statusName) {
            case OfferStatusType.CALCULATED:
                if (this.loggedInUserId !== ownerId || this.mode === 'preview') {
                    this.nextButtonText = this.closeText;
                    this.reviewerControl.disable();
                    return false;
                }
                this.nextButtonText = this.assignText;
                this.reviewerControl.enable();
                return true;

            case OfferStatusType.IN_REVIEW:
                if (this.loggedInUserId !== reviewerId || this.mode === 'preview') {
                    this.hideMiddleButton = true;
                    this.nextButtonText = this.closeText;
                    this.showRemarkInput = false;
                    this.styleType = 0;
                    this.remarkControl.disable();
                    return false;
                }
                this.remarkControl.enable();
                this.styleType = 1;
                this.hideMiddleButton = false;
                this.showRemarkInput = true;
                this.middleButtonText = this.rejectText;
                this.nextButtonText = this.approveText;
                return true;

            case OfferStatusType.REVIEW_ACCEPTED:
                this.getOfferReviewedStatus(OfferStatusType.REVIEW_ACCEPTED);
                this.reviewApprovedRejected = this.reviewApprovedText;
                this.offerApprovedRejected = this.offerApprovedText;

                if (this.loggedInUserId !== ownerId) {
                    this.nextButtonText = this.closeText;
                    return false;
                }
                this.nextButtonText = this.nextText;
                return true;

            case OfferStatusType.REVIEW_REJECTED:
                this.getOfferReviewedStatus(OfferStatusType.REVIEW_REJECTED);
                this.reviewApprovedRejected = this.reviewRejectedText;
                this.offerApprovedRejected = this.offerRejectedText;

                if (this.loggedInUserId !== ownerId || this.mode === 'preview') {
                    this.nextButtonText = this.closeText;
                    return false;
                }
                this.nextButtonText = this.editOfferText;
                return true;

            case OfferStatusType.READY:
            case OfferStatusType.ACCEPTED:
            case OfferStatusType.REJECTED:
            default:
                this.getOfferReviewedStatus(OfferStatusType.REVIEW_ACCEPTED);
                this.reviewApprovedRejected = this.reviewApprovedText;
                this.offerApprovedRejected = this.offerApprovedText;
                this.nextButtonText = this.nextText;
        }
        return true;
    }

    getOfferReviewedStatus(statusName: OfferStatusType) {
        this._offersService.getOfferStatuses(this.offerId).subscribe({
            next: (data) => {
                for (let index = 0; index < data.length; index++) {
                    const elem = data[index];
                    if (elem.status.name === statusName) {
                        this.offerReviewed = elem;
                        break;
                    }
                }
            },
            error: (err) => {
                console.warn(err);
            },
        });
    }

    switchToStepByStatus() {
        switch (this.offer.offerStatus.status.name) {
            case 'CALCULATED':
                this.getAllReviewers();
                return;

            case 'IN_REVIEW':
                this.stepsCompletedReviewer.first = true;
                this.selectedIndex = 1;
                return;

            case 'REVIEW_ACCEPTED':
            case 'REVIEW_REJECTED':
            case 'READY':
            case 'ACCEPTED':
            case 'REJECTED':
            case 'EXPORTED':
                this.stepsCompletedReviewer.first = true;
                this.stepsCompletedReviewer.second = true;
                this.selectedIndex = 2;
                return;
        }
    }

    getAllReviewers() {
        this.nextLoading = true;
        this._offersService.getAllReviewers(this.offer.id).subscribe({
            next: (res) => {
                this.reviewerList = res.filter((elem) => elem.externalId !== this.loggedInUserId);
                // this.reviewerControl.setValue(this.reviewerList[0]?.id);
                this.nextLoading = false;
                this.selectedIndex = 0;
            },
            error: (err) => {
                console.warn(err);
            },
        });
    }

    setReviewer(statusName, reviewerId) {
        this._offersService.proceedToStatus(this.offerId, statusName, this.remarkControl.value, reviewerId).subscribe({
            next: (data) => {
                this.statusName = statusName;
                this.stepperReviewer.selected.completed = true;
                this.nextLoading = false;
                this.stepsCompletedReviewer = {
                    first: true,
                    second: false,
                    third: false,
                };
                this.isStepEditableByStatusAndMode(this.statusName);
                this.selectedIndex = 1;
                this.styleType = 0;
                this._router.navigate(['/offers/table']);
            },
            error: (err) => {
                console.error(err);
                this._router.navigate(['/offers/table']);
            },
        });
    }

    handleNextStep(buttonPosition: string) {
        switch (this.offer.offerStatus.status.name) {
            case 'CALCULATED':
                if (
                    this.loggedInUserId !== this.offer.offerStatus.owner.externalId ||
                    !this.reviewerControl.value ||
                    this.isPreviewMode
                ) {
                    this._router.navigate(['/offers/table']);
                    return;
                }

                let reviewer = this.reviewerList.find((e) => e.id === this.reviewerControl.value);
                this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
                    data: {
                        title: this._translateService.instant('cabinet.offers.plan.reviewStep.assignToReviewer'),
                        message:
                            this._translateService.instant('cabinet.offers.plan.reviewStep.assignToQuestion') +
                            `${reviewer.firstName} ${reviewer.lastName}`,
                    },
                    width: '300px',
                });

                const onDialogCloseCalc = (confirmed: boolean) => {
                    if (confirmed) {
                        this._offersService
                            .proceedToStatus(this.offerId, OfferStatusType.IN_REVIEW, null, this.reviewerControl.value)
                            .subscribe({
                                next: (data) => {
                                    this._router.navigate(['/offers/table']);
                                },
                                error: (err) => console.error(err),
                            });
                    }
                };
                this._dialogService.handleDialogClose(onDialogCloseCalc);
                return;

            case 'IN_REVIEW':
                let reviewerExternalId = this.offer.offerStatus.reviewer?.externalId;
                let reviewerId = this.offer.offerStatus.reviewer?.id;

                if (this.loggedInUserId !== reviewerExternalId || this.isPreviewMode) {
                    this._router.navigate(['/offers/table']);
                    return;
                }

                let statusName;
                let title;
                let question;
                if (buttonPosition === this.middleButtonId) {
                    statusName = OfferStatusType.REVIEW_REJECTED;
                    title = 'cabinet.offers.plan.reviewStep.rejectOfferTitle';
                    question = 'cabinet.offers.plan.reviewStep.rejectOfferQuestion';
                }
                if (buttonPosition === this.nextButtonId) {
                    statusName = OfferStatusType.REVIEW_ACCEPTED;
                    title = 'cabinet.offers.plan.reviewStep.acceptOfferTitle';
                    question = 'cabinet.offers.plan.reviewStep.acceptOfferQuestion';
                }

                this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
                    data: {
                        title: this._translateService.instant(title),
                        message: this._translateService.instant(question),
                    },
                    width: '300px',
                });

                const onDialogReview = (confirmed: boolean) => {
                    if (confirmed) {
                        this.setReviewer(statusName, reviewerId);
                    }
                };
                this._dialogService.handleDialogClose(onDialogReview);

                return;

            case 'REVIEW_ACCEPTED':
                if (this.loggedInUserId !== this.offer.offerStatus.owner.externalId || this.isPreviewMode) {
                    this._router.navigate(['/offers/table']);
                    return;
                }

                this.stepper.selected.completed = true;
                this.nextLoading = false;
                this.handleUpdateStepsCompleted({
                    ...this.stepsCompleted,
                    fifth: true,
                });
                this.stepper.next();
                return;

            case 'REVIEW_REJECTED':
                if (this.loggedInUserId !== this.offer.offerStatus.owner.externalId || this.isPreviewMode) {
                    this._router.navigate(['/offers/table']);
                    return;
                }

                this._offersService.proceedToStatus(this.offerId, OfferStatusType.PENDING).subscribe({
                    next: (data) => {
                        this.statusName = OfferStatusType.PENDING;
                        this._offersService.statusChangeMade.emit(OfferStatusType.PENDING);
                        this._offersService.reloadDocumentDataRequested.emit(true);
                        this.stepsCompletedReviewer = {
                            first: false,
                            second: false,
                            third: false,
                        };
                        this.stepperReviewer.reset();
                        this.statusName = OfferStatusType.PENDING;
                        this.isStepEditableByStatusAndMode(this.statusName);
                        this.switchToStepByStatus();
                        this.handleUpdateStepsCompleted({
                            first: false,
                            second: false,
                            third: false,
                            fourth: false,
                            fifth: false,
                            sixth: false,
                            seventh: false,
                            eight: false,
                        });

                        this.stepper.selectedIndex = 0;
                    },
                    error: (err) => console.error(err),
                });
                return;

            case 'READY':
            case 'ACCEPTED':
            case 'REJECTED':
                this.stepper.selected.completed = true;
                this.nextLoading = false;
                this.handleUpdateStepsCompleted({
                    ...this.stepsCompleted,
                    fifth: true,
                });
                this.stepper.next();
                return;
            default:
                this._router.navigate(['/offers/table']);
        }
    }

    handleBackStep() {
        this.stepper.previous();
    }
}
