import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { SaveEventName } from '@core/enums/save-event-name.enum';
import { OffersService, ProductService } from '@core/services';
import InflationFundTariff from '@core/types/InflationFundTariff';
import LevyTariff from '@core/types/LevyTariff';
import RiskTariff from '@core/types/RiskTariff';
import PlanFromOffer, { CODE_NAMES } from '@core/types/plan';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, forkJoin, zip, Subject, filter, pairwise, map, Observable, startWith, scan, take } from 'rxjs';
import { numberValidator } from '@core/validators/number.validator';

@Component({
    selector: 'app-risk-benefits',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
    ],
    templateUrl: './risk-benefits.component.html',
    styleUrl: './risk-benefits.component.scss',
})
export class RiskBenefitsComponent implements OnInit, OnDestroy {
    @Input() categories: number;
    @Input() isPreviewMode: boolean;
    @Input() isEditable: boolean;
    @Input() isPlanEditable: boolean;
    @Input() currentPlan: PlanFromOffer;
    @Input() saveSubject: Subject<SaveEventName>;

    riskTariffCat1Form = this.fb.group({
        employer: [0, [Validators.required, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        employee: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        invalidityPensionRate: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        disabilityChildPension: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        administrativeRate: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        spousalPension: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        shareOfAdministrativeCostsEmployer: [
            0,
            [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)],
        ],
    });

    riskTariffCat2Form = this.fb.group({
        employer: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        employee: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        invalidityPensionRate: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        disabilityChildPension: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        administrativeRate: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        spousalPension: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        shareOfAdministrativeCostsEmployer: [
            0,
            [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)],
        ],
    });

    riskTariffCat3Form = this.fb.group({
        employer: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        employee: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        invalidityPensionRate: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        disabilityChildPension: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        administrativeRate: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,5})?$/)]],
        spousalPension: [0, [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)]],
        shareOfAdministrativeCostsEmployer: [
            0,
            [Validators.required, numberValidator, Validators.pattern(/^\d*(\.\d{0,2})?$/)],
        ],
    });

    codesFormBSTFK = this.fb.group({
        BSTFK: ['', [Validators.required]],
    });

    codesFormBSWARTEF = this.fb.group({
        BSWARTEF: ['', [Validators.required, Validators.min(0)]],
    });

    levyTariffForm = this.fb.group({
        total: [0, [Validators.required, Validators.min(0)]],
    });

    inflationFundTariffForm = this.fb.group({
        employerShare: [0, [Validators.required, Validators.min(0)]],
    });

    generalForm = this.fb.group({
        BSTFKPROK1: [0, [Validators.required, numberValidator, Validators.pattern(/^\d+(\.\d)?$/)]],
        BSTFKPROK2: [0, [Validators.required, numberValidator, Validators.pattern(/^\d+(\.\d)?$/)]],
        BSTFKPROK3: [0, [Validators.required, numberValidator, Validators.pattern(/^\d+(\.\d)?$/)]],
    });

    offerId = this._route.snapshot.params['id'];
    tariffRiskData;

    constructor(
        private fb: FormBuilder,
        private _productService: ProductService,
        private _offersService: OffersService,
        private _route: ActivatedRoute
    ) {}

    typeOfCalculationDeathCapital = [];
    waitingTimesForAdministrationCost = [];
    riskCat1Tariff: RiskTariff;
    riskCat2Tariff: RiskTariff;
    riskCat3Tariff: RiskTariff;
    levyTariff: LevyTariff;
    inflationFundTariff: InflationFundTariff;
    hasChange = false;
    areFormsFilled = false;
    statusReset = false;
    private _subscriptions: Subscription[] = [];

    varsToUpdate = {
        generalForm: {},
        levyTariffForm: {},
        inflationFundForm: {},
        riskTariffCat1Form: {},
        riskTariffCat2Form: {},
        riskTariffCat3Form: {},
        codesFormBSTFK: {},
        codesFormBSWARTEF: {},
    };

    ngOnInit() {
        if (this.isPreviewMode || !this.isEditable || !this.isPlanEditable) {
            this.disableForms();
        }
        const statusChangeSubscription = this._offersService.statusChangeMade.subscribe((status) => {
            if (status === 'PENDING') {
                this._productService.resetProductDialogUpdates();
                this.getPlan();
            }
        });
        this.getPlan();

        this._subscriptions.push(statusChangeSubscription);

        const subs = this._offersService.requestToUpdateAccessToFields.subscribe((statusName) =>
            this.isStepEditableByStatusAndMode(statusName)
        );
        this._subscriptions.push(subs);
        const sub = this.saveSubject.subscribe((value) => {
            this.save(value);
        });
        this._subscriptions.push(sub);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    enableForms() {
        this.generalForm.enable();
        this.codesFormBSTFK.enable();
        this.codesFormBSWARTEF.enable();
        this.riskTariffCat1Form.enable();
        this.riskTariffCat2Form.enable();
        this.riskTariffCat3Form.enable();
        this.levyTariffForm.enable();
        this.inflationFundTariffForm.enable();
    }

    disableForms() {
        this.generalForm.disable();
        this.codesFormBSTFK.disable();
        this.codesFormBSWARTEF.disable();
        this.riskTariffCat1Form.disable();
        this.riskTariffCat2Form.disable();
        this.riskTariffCat3Form.disable();
        this.levyTariffForm.disable();
        this.inflationFundTariffForm.disable();
    }

    isStepEditableByStatusAndMode(statusName: string): boolean {
        if (statusName !== 'PENDING' || this.isPreviewMode || !this.isPlanEditable) {
            this.isEditable = false;
            this.disableForms();
            return false;
        }
        this.isEditable = true;
        this.enableForms();
        return true;
    }

    getPlan() {
        const req1 = this._productService.getCodesByName(CODE_NAMES.BSTFK);
        const req2 = this._productService.getCodesByName(CODE_NAMES.BSWARTEF);
        const req3 = this._productService.getTarrifs(this.offerId, 'riskcat1');
        const req4 = this._productService.getTarrifs(this.offerId, 'riskcat2');
        const req5 = this._productService.getTarrifs(this.offerId, 'riskcat3');
        const req6 = this._productService.getTarrifs(this.offerId, 'payAsYouGo');
        const req7 = this._productService.getTarrifs(this.offerId, 'inflationFund');

        forkJoin([req1, req2, req3, req4, req5, req6, req7]).subscribe({
            next: (results) => {
                this.typeOfCalculationDeathCapital = results[0][CODE_NAMES.BSTFK];
                this.waitingTimesForAdministrationCost = results[1][CODE_NAMES.BSWARTEF];
                let list: any = results[2];
                this.riskCat1Tariff = list.riskcat1[0];
                list = results[3];
                this.riskCat2Tariff = list.riskcat2[0];
                list = results[4];
                this.riskCat3Tariff = list.riskcat3[0];
                list = results[5];
                this.levyTariff = list.payAsYouGo[0];
                list = results[6];
                this.inflationFundTariff = list.inflationFund[0];
                this.fillForm();
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    fillForm() {
        let levyTariffControls = this.levyTariffForm.controls;
        let inflationFundTariffControls = this.inflationFundTariffForm.controls;
        let codesControls = this.codesFormBSTFK.controls;
        let codesControlsBSWARTEF = this.codesFormBSWARTEF.controls;

        codesControls['BSTFK'].setValue(this.currentPlan?.BSTFK?.value);
        codesControlsBSWARTEF['BSWARTEF'].setValue(this.currentPlan?.BSWARTEF?.value);
        this.generalForm.patchValue({
            BSTFKPROK1: parseInt(this.currentPlan?.BSTFKPROK1?.value),
            BSTFKPROK2: parseInt(this.currentPlan?.BSTFKPROK2?.value),
            BSTFKPROK3: parseInt(this.currentPlan?.BSTFKPROK3?.value),
        });
        this.riskTariffCat1Form.patchValue({
            employer: this.convertToPercent(this.riskCat1Tariff?.employer),
            employee: this.convertToPercent(this.riskCat1Tariff?.employee),
            invalidityPensionRate: this.convertToPercent(this.riskCat1Tariff?.invalidityPensionRate),
            spousalPension: this.convertToPercent(this.riskCat1Tariff?.spousalPension),
            disabilityChildPension: this.convertToPercent(this.riskCat1Tariff?.disabilityChildPension),
            administrativeRate: this.convertToPercent(this.riskCat1Tariff?.administrativeRate),
            shareOfAdministrativeCostsEmployer: this.riskCat1Tariff?.shareOfAdministrativeCostsEmployer,
        });

        this.riskTariffCat2Form.patchValue({
            employer: this.convertToPercent(this.riskCat2Tariff?.employer),
            employee: this.convertToPercent(this.riskCat2Tariff?.employee),
            invalidityPensionRate: this.convertToPercent(this.riskCat2Tariff?.invalidityPensionRate),
            spousalPension: this.convertToPercent(this.riskCat2Tariff?.spousalPension),
            disabilityChildPension: this.convertToPercent(this.riskCat2Tariff?.disabilityChildPension),
            administrativeRate: this.convertToPercent(this.riskCat2Tariff?.administrativeRate),
            shareOfAdministrativeCostsEmployer: this.riskCat2Tariff?.shareOfAdministrativeCostsEmployer,
        });

        this.riskTariffCat3Form.patchValue({
            employer: this.convertToPercent(this.riskCat3Tariff?.employer),
            employee: this.convertToPercent(this.riskCat3Tariff?.employee),
            invalidityPensionRate: this.convertToPercent(this.riskCat3Tariff?.invalidityPensionRate),
            spousalPension: this.convertToPercent(this.riskCat3Tariff?.spousalPension),
            disabilityChildPension: this.convertToPercent(this.riskCat3Tariff?.disabilityChildPension),
            administrativeRate: this.convertToPercent(this.riskCat3Tariff?.administrativeRate),
            shareOfAdministrativeCostsEmployer: this.riskCat3Tariff?.shareOfAdministrativeCostsEmployer,
        });

        levyTariffControls['total'].setValue(this.convertToPercent(this.levyTariff?.total));
        inflationFundTariffControls['employerShare'].setValue(this.inflationFundTariff?.employerShare);

        if (this.isPreviewMode || !this.isEditable || !this.isPlanEditable) {
            return;
        }

        Object.entries(this.generalForm.controls).forEach((value) => {
            const [key1, control1] = value;
            control1.valueChanges.subscribe((val) => {
                const [key2, control2] = value;
                this.varsToUpdate.generalForm[key2] = val;
            });
        });

        Object.entries(this.codesFormBSWARTEF.controls).forEach((value) => {
            const [key1, control1] = value;
            control1.valueChanges.subscribe((val) => {
                const [key2, control2] = value;
                this.varsToUpdate.codesFormBSWARTEF[key2] = val;
            });
        });

        Object.entries(this.codesFormBSTFK.controls).forEach((value) => {
            const [key1, control1] = value;
            control1.valueChanges.subscribe((val) => {
                const [key2, control2] = value;
                this.varsToUpdate.codesFormBSTFK[key2] = val;
            });
        });
        if (!this.statusReset) {
            const forms = [
                this.riskTariffCat1Form,
                this.riskTariffCat2Form,
                this.riskTariffCat3Form,
                this.codesFormBSTFK,
                this.codesFormBSWARTEF,
                this.levyTariffForm,
                this.inflationFundTariffForm,
                this.generalForm,
            ];

            forms.forEach((form: FormGroup) => {
                const initialValue = form.value;
                const formSubscription = form.valueChanges.pipe(take(1)).subscribe((value) => {
                    this.hasChange = Object.keys(initialValue).some((key) => value[key] != initialValue[key]);
                    if (this.hasChange) {
                        this._productService.setProductDialogUpdates({
                            riskBenefit: { component: 'Risk Benefits', updated: this.hasChange },
                        });
                    }
                });
                this._subscriptions.push(formSubscription);
            });
        }
    }

    get BSTFK() {
        return this.codesFormBSTFK.get('BSTFK');
    }

    get BSWARTEF() {
        return this.codesFormBSWARTEF.get('BSWARTEF');
    }

    get total() {
        return this.levyTariffForm.get('total');
    }

    get employerShare() {
        return this.inflationFundTariffForm.get('employerShare');
    }

    get BSTFKPROK1() {
        return this.generalForm.get('BSTFKPROK1');
    }

    get BSTFKPROK2() {
        return this.generalForm.get('BSTFKPROK2');
    }

    get BSTFKPROK3() {
        return this.generalForm.get('BSTFKPROK3');
    }

    get riskRateEmployerCat1() {
        return this.riskTariffCat1Form.get('employer');
    }

    get riskRateEmployerCat2() {
        return this.riskTariffCat2Form.get('employer');
    }

    get riskRateEmployerCat3() {
        return this.riskTariffCat3Form.get('employer');
    }

    get riskRateEmployeeCat1() {
        return this.riskTariffCat1Form.get('employee');
    }

    get riskRateEmployeeCat2() {
        return this.riskTariffCat2Form.get('employee');
    }

    get riskRateEmployeeCat3() {
        return this.riskTariffCat3Form.get('employee');
    }

    get rateOfDisabilitypensionCat1() {
        return this.riskTariffCat1Form.get('invalidityPensionRate');
    }

    get rateOfDisabilitypensionCat2() {
        return this.riskTariffCat2Form.get('invalidityPensionRate');
    }

    get rateOfDisabilitypensionCat3() {
        return this.riskTariffCat3Form.get('invalidityPensionRate');
    }

    get disabilityChildPensionCat1() {
        return this.riskTariffCat1Form.get('disabilityChildPension');
    }

    get disabilityChildPensionCat2() {
        return this.riskTariffCat2Form.get('disabilityChildPension');
    }

    get disabilityChildPensionCat3() {
        return this.riskTariffCat3Form.get('disabilityChildPension');
    }

    get administrativeCostsContributionCat1() {
        return this.riskTariffCat1Form.get('administrativeRate');
    }

    get administrativeCostsContributionCat2() {
        return this.riskTariffCat2Form.get('administrativeRate');
    }

    get administrativeCostsContributionCat3() {
        return this.riskTariffCat3Form.get('administrativeRate');
    }

    get spousalPensionCat1() {
        return this.riskTariffCat1Form.get('spousalPension');
    }

    get spousalPensionCat2() {
        return this.riskTariffCat2Form.get('spousalPension');
    }

    get spousalPensionCat3() {
        return this.riskTariffCat3Form.get('spousalPension');
    }

    get shareOfAdministrativeCostsEmployerCat1() {
        return this.riskTariffCat1Form.get('shareOfAdministrativeCostsEmployer');
    }

    get shareOfAdministrativeCostsEmployerCat2() {
        return this.riskTariffCat2Form.get('shareOfAdministrativeCostsEmployer');
    }

    get shareOfAdministrativeCostsEmployerCat3() {
        return this.riskTariffCat3Form.get('shareOfAdministrativeCostsEmployer');
    }

    save(saveEventName) {
        if (
            this.generalForm.invalid ||
            this.levyTariffForm.invalid ||
            this.inflationFundTariffForm.invalid ||
            this.riskTariffCat1Form.invalid ||
            this.riskTariffCat2Form.invalid ||
            this.riskTariffCat3Form.invalid ||
            this.codesFormBSTFK.invalid ||
            this.codesFormBSWARTEF.invalid
        ) {
            return;
        }

        let planVarsToUpdate = [];
        let riskTariffCat1ValuesToUpdate = [];
        let riskTariffCat2ValuesToUpdate = [];
        let riskTariffCat3ValuesToUpdate = [];
        let levyValuesToUpdate = [];
        let inflationFundValuesToUpdate = [];

        if (this.codesFormBSWARTEF.dirty) {
            Object.entries(this.varsToUpdate.codesFormBSWARTEF).forEach((item) => {
                const [key, value] = item;

                let code = this.waitingTimesForAdministrationCost.find((item) => item.value === value);

                planVarsToUpdate.push({
                    name: key,
                    value: code.id,
                });
            });
        }

        if (this.codesFormBSTFK.dirty) {
            Object.entries(this.varsToUpdate.codesFormBSTFK).forEach((item) => {
                const [key, value] = item;

                let code = this.typeOfCalculationDeathCapital.find((item) => item.value === value);

                planVarsToUpdate.push({
                    name: key,
                    value: code.id,
                });
            });
        }
        if (this.generalForm.dirty) {
            Object.entries(this.varsToUpdate.generalForm).forEach((item) => {
                const [key, value] = item;

                planVarsToUpdate.push({
                    name: key,
                    value: value,
                });
            });
        }

        if (this.riskTariffCat1Form.dirty) {
            let totalRiskRateCat1 = this.riskRateEmployerCat1.value + this.riskRateEmployeeCat1.value;
            let body = {
                ...this.riskCat1Tariff,
                ...this.riskTariffCat1Form.value,
                tariffGroup: 'riskCat1'.toLowerCase(),
                employer: this.convertFromPercent(this.riskRateEmployerCat1.value),
                employee: this.convertFromPercent(this.riskRateEmployeeCat1.value),
                invalidityPensionRate: this.convertFromPercent(this.rateOfDisabilitypensionCat1.value),
                spousalPension: this.convertFromPercent(this.spousalPensionCat1.value),
                riskRateTotal: this.convertFromPercent(totalRiskRateCat1),
                disabilityChildPension: this.convertFromPercent(this.disabilityChildPensionCat1.value),
                administrativeRate: this.convertFromPercent(this.administrativeCostsContributionCat1.value),
            };
            riskTariffCat1ValuesToUpdate.push(body);
        }

        if (this.riskTariffCat2Form.dirty) {
            let totalRiskRateCat2 = this.riskRateEmployerCat2.value + this.riskRateEmployeeCat2.value;
            let body = {
                ...this.riskCat2Tariff,
                ...this.riskTariffCat2Form.value,
                tariffGroup: 'riskCat2'.toLowerCase(),
                employer: this.convertFromPercent(this.riskRateEmployerCat2.value),
                employee: this.convertFromPercent(this.riskRateEmployeeCat2.value),
                riskRateTotal: this.convertFromPercent(totalRiskRateCat2),
                invalidityPensionRate: this.convertFromPercent(this.rateOfDisabilitypensionCat2.value),
                spousalPension: this.convertFromPercent(this.spousalPensionCat2.value),
                disabilityChildPension: this.convertFromPercent(this.disabilityChildPensionCat2.value),
                administrativeRate: this.convertFromPercent(this.administrativeCostsContributionCat2.value),
            };
            riskTariffCat2ValuesToUpdate.push(body);
        }

        if (this.riskTariffCat3Form.dirty) {
            let totalRiskRateCat3 = this.riskRateEmployerCat3.value + this.riskRateEmployeeCat3.value;
            let body = {
                ...this.riskCat3Tariff,
                ...this.riskTariffCat3Form.value,
                tariffGroup: 'riskCat3'.toLowerCase(),
                employer: this.convertFromPercent(this.riskRateEmployerCat3.value),
                employee: this.convertFromPercent(this.riskRateEmployeeCat3.value),
                riskRateTotal: this.convertFromPercent(totalRiskRateCat3),
                invalidityPensionRate: this.convertFromPercent(this.rateOfDisabilitypensionCat3.value),
                spousalPension: this.convertFromPercent(this.spousalPensionCat3.value),
                disabilityChildPension: this.convertFromPercent(this.disabilityChildPensionCat3.value),
                administrativeRate: this.convertFromPercent(this.administrativeCostsContributionCat3.value),
            };
            riskTariffCat3ValuesToUpdate.push(body);
        }

        if (this.levyTariffForm.dirty) {
            const total = this.convertFromPercent(this.total.value);
            let body = {
                ...this.levyTariff,
                ...this.levyTariffForm.value,
                tariffGroup: 'payAsYouGo'.toLowerCase(),
                total: total,
                employer: total,
            };
            levyValuesToUpdate.push(body);
        }

        if (this.inflationFundTariffForm.dirty) {
            let body = {
                ...this.inflationFundTariff,
                ...this.inflationFundTariffForm.value,
                tariffGroup: 'inflationFund'.toLowerCase(),
            };
            inflationFundValuesToUpdate.push(body);
        }

        let listSubscriptions = [];
        if (planVarsToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.changeAllPlanFieldValues(this.offerId, planVarsToUpdate));
        }
        if (riskTariffCat1ValuesToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.updateTariffValues(riskTariffCat1ValuesToUpdate));
        }
        if (riskTariffCat2ValuesToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.updateTariffValues(riskTariffCat2ValuesToUpdate));
        }
        if (riskTariffCat3ValuesToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.updateTariffValues(riskTariffCat3ValuesToUpdate));
        }
        if (levyValuesToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.updateTariffValues(levyValuesToUpdate));
        }
        if (inflationFundValuesToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.updateTariffValues(inflationFundValuesToUpdate));
        }
        if (listSubscriptions.length > 0) {
            zip(...listSubscriptions).subscribe({
                next: () => {
                    this._productService.updatedForm(saveEventName);
                    if (saveEventName === SaveEventName.NEXT_BTN_SAVE) {
                        this._productService.updatedForm(SaveEventName.SWITCH_TAB_SAVE);
                    }
                },
                error: (err) => {},
            });
        } else {
            this._productService.updatedForm(saveEventName);
        }
    }

    convertToPercent(textParam) {
        let text = '' + textParam;
        if (text === '0') {
            return parseInt(text);
        }
        const indexOfPoint = text.indexOf('.');
        if (indexOfPoint === -1) {
            text += '00';
            return parseInt(text);
        } else if (indexOfPoint === text.length - 1) {
            text = text.substring(0, indexOfPoint);
            text += '00';
            return parseInt(text);
        } else {
            const firstPart = text.substring(0, indexOfPoint);
            const secondPart = text.substring(indexOfPoint + 1);
            if (secondPart.length == 1) {
                return parseInt(firstPart + secondPart + '0');
            } else if (secondPart.length == 2) {
                return parseInt(firstPart + secondPart);
            }
            const thirdPart = secondPart.substring(2);

            return parseFloat(firstPart + secondPart.substring(0, 2) + '.' + thirdPart);
        }
    }

    convertFromPercent(textParam) {
        let text = '' + textParam;
        if (text === '0') {
            return 0;
        } else if (text === '100') {
            return 1;
        }

        const indexOfPoint = text.indexOf('.');

        if (indexOfPoint === -1) {
            if (text.length === 2) {
                text = '0.' + text;
            } else if (text.length === 1) {
                text = '0.0' + text;
            }
            return parseFloat(text);
        }

        const firstPart = text.substring(0, indexOfPoint);
        text = text.replace('.', '');

        if (firstPart.length === 2) {
            text = '0.' + text;
        } else if (firstPart.length === 1) {
            text = '0.0' + text;
        }

        return parseFloat(text);
    }
}
