import { Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { loginGuard } from '@core/guards/login.guard';
import { OffersTableComponent } from './offers/components/offers-table/offers-table.component';
import { CreateOfferComponent } from './offers/pages/create-offer/create-offer.component';
import { CreateUserComponent } from '@pages/user-managment/create-user/create-user.component';
import { ListUsersComponent } from '@pages/user-managment/list-users/list-users.component';
import { adminRoleGuard } from '@core/guards/admin-role.guard';
import { onlyAdminRoleGuard } from '@core/guards/only-admin-role.guard';
import { onlyReadOnlyRoleGuard } from '@core/guards/only-read-only-role.guard';
import { reportsFeatureGuard } from '@core/guards/reports-feature.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@core/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [loginGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('@pages/dashboard/dashboard.component').then((c) => c.DashboardComponent),
    canActivate: [authGuard, onlyAdminRoleGuard],
  },
  {
    path: 'master-data',
    loadComponent: () => import('@pages/master-data/master-data.component').then((c) => c.MasterDataComponent),
    canActivate: [authGuard, onlyAdminRoleGuard],
  },
  {
    path: 'report',
    loadComponent: () => import('@pages/report/report.component').then((c) => c.ReportComponent),
    canActivate: [authGuard, onlyAdminRoleGuard, reportsFeatureGuard],
  },
  {
    path: 'offers',
    loadComponent: () => import('./offers/pages/offers.component').then((c) => c.OffersComponent),
    canActivate: [authGuard, onlyAdminRoleGuard],
    canActivateChild: [authGuard, onlyAdminRoleGuard],
    children: [
      {
        path: 'table',
        component: OffersTableComponent,
        data: { title: 'Created Offers' },
      },
      {
        path: 'new/:id',
        component: CreateOfferComponent,
        data: { title: 'New Offer' },
        canActivate: [onlyReadOnlyRoleGuard],
      },
      {
        path: 'edit/:id',
        component: CreateOfferComponent,
        data: { title: 'Edit Offer' },
        canActivate: [onlyReadOnlyRoleGuard],
      },
      {
        path: 'preview/:id',
        component: CreateOfferComponent,
        data: { title: 'Preview Offer' },
      },
      { path: '', redirectTo: 'table', pathMatch: 'full' },
    ],
  },
  {
    path: 'my-account',
    loadComponent: () => import('@pages/account/account.component').then((c) => c.AccountComponent),
    canActivate: [authGuard],
  },
  {
    path: 'admin-configuration',
    loadComponent: () => import('@pages/admin-configuration/admin-configuration.component').then((c) => c.AdminConfigurationComponent),
    canActivate: [authGuard, adminRoleGuard],
  },
  {
    path: 'users',
    loadComponent: () => import('@pages/user-managment/user-managment.component').then((c) => c.UserManagmentComponent),
    canActivate: [authGuard, adminRoleGuard],
    canActivateChild: [authGuard, adminRoleGuard],
    children: [
      {
        path: 'list',
        component: ListUsersComponent,
        data: { title: 'User Managment' },
      },
      {
        path: 'new',
        component: CreateUserComponent,
        data: { title: 'User Managment - Create new User' },
      },
      {
        path: 'edit/:id',
        component: CreateUserComponent,
        data: { title: 'User Managment - Edit User' },
      },

      { path: '', redirectTo: 'list', pathMatch: 'full' },
    ],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
