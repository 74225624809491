import { AbstractControl } from '@angular/forms';

export function numberValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value === null || control.value === undefined || control.value === '') {
    return null;
  }

  const value = parseFloat(control.value);

  if (!isNaN(value)) {
    return null;
  } else {
    return { 'notANumber': true };
  }
}
