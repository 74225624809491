import { inject } from '@angular/core';
import { CanActivateFn, NavigationEnd, Router } from '@angular/router';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { filter } from 'rxjs';
export const onlyReadOnlyRoleGuard: CanActivateFn = () => {
  const authorizationService = inject(AuthorizationService);
  const router = inject(Router);
  const swarmAuthToken = 'swarm-auth-token';
  const token = localStorage.getItem(swarmAuthToken);
  const tokenPayload = authorizationService.parseJwt(token);
  const roles = tokenPayload?.realm_access?.roles;
  const adminRole = roles.includes('offertool_admin');
  const editRole = roles.includes('offertool_edit');
  const readOnlyRole = roles.includes('offertool_read_only');
  const onlyReadOnlyRole = readOnlyRole && !adminRole && !editRole;

  if (onlyReadOnlyRole) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
