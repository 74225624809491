import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import PlanFromOffer from '@core/types/plan';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { RiskBenefitsComponent } from './risk-benefits/risk-benefits.component';
import { SavingContributionsStepComponent } from './saving-contribution/saving-contribution.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { SaveEventName } from '@core/enums/save-event-name.enum';

@Component({
    selector: 'app-configure-plan',
    standalone: true,
    imports: [
        MatTabsModule,
        GeneralInfoComponent,
        RiskBenefitsComponent,
        SavingContributionsStepComponent,
        TranslateModule,
    ],
    templateUrl: './configure-plan.component.html',
    styleUrl: './configure-plan.component.css',
})
export class ConfigurePlanComponent implements OnInit, OnDestroy {
    @Input() isPreviewMode: boolean;
    @Input() isEditable: boolean;
    @Input() isPlanEditable: boolean;
    @Input()
    currentPlan: PlanFromOffer;
    categories = 3;
    @Input()
    offerId: number;
    @Input() savePlanOnNextBtn: Subject<SaveEventName>;
    saveSubject: Subject<SaveEventName> = new Subject();
    _subscriptions = [];

    constructor() {}

    ngOnInit() {
        const sub1 = this.savePlanOnNextBtn.subscribe((value) => {
            this.saveSubject.next(value);
        });
        this._subscriptions.push(sub1);

        this.categories = this.currentPlan?.BSKAT.value ? parseInt(this.currentPlan?.BSKAT.value) : 3;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    handleNumberOfCategories(categories: number) {
        this.categories = categories;
    }

    onTabChange() {
        this.saveSubject.next(SaveEventName.SWITCH_TAB_SAVE);
    }
}
