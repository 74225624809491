import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthorizationService } from '@core/services/authorization/authorization.service';

export const onlyAdminRoleGuard: CanActivateFn = () => {
  const authorizationService = inject(AuthorizationService);
  const router = inject(Router);
  const swarmAuthToken = 'swarm-auth-token';
  const token = localStorage.getItem(swarmAuthToken);
  const tokenPayload = authorizationService.parseJwt(token);
  const roles = tokenPayload?.realm_access?.roles;
  const adminRole = roles.includes('offertool_admin');
  const editRole = roles.includes('offertool_edit');
  const readOnlyRole = roles.includes('offertool_read_only');
  const onlyAdminRole = adminRole && !editRole && !readOnlyRole;
  if (onlyAdminRole) {
    router.navigate(['/users/list']);
    return false;
  }

  return true;
};
