import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { OffersService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, combineLatest, of, Subscription } from 'rxjs';
@Injectable()
export class MatPaginatorIntlDe extends MatPaginatorIntl {
  currentLang: string;
  override itemsPerPageLabel;
  override nextPageLabel;
  subscription: Subscription;
  override previousPageLabel;
  paginatorIntl = new MatPaginatorIntl();
  constructor(private _offerService: OffersService, private _translate: TranslateService) {
    super();
    if (!this.currentLang) {
      this.getAndApplyTranslation();
    }

    const sub = combineLatest([this._offerService.language$, this._translate.stream('cabinet.pagination.itemsPerPage')])
      .pipe(
        switchMap(([lang]) => {
          return of(lang);
        })
      )
      .subscribe((lang) => {
        this.currentLang = lang;
        this.getAndApplyTranslation();
      });
    this.subscription = sub;
  }
  private getAndApplyTranslation() {
    this._translate.get('cabinet.pagination.itemsPerPage').subscribe((translation: string) => {
      this.itemsPerPageLabel = translation;
      this.changes.next();
    });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, length);
    let trans;
    this._translate.get('cabinet.pagination.of').subscribe((translation: string) => {
      trans = translation;
      return `${startIndex} - ${endIndex} ${translation} ${length}`;
    });
    return `${startIndex} - ${endIndex} ${trans} ${length}`;
  };
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
