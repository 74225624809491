import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const swarmAuthToken = 'swarm-auth-token';
  const swarmRefreshToken = 'swarm-refresh-token';
  if (localStorage.getItem(swarmAuthToken) && localStorage.getItem(swarmRefreshToken)) {
    return true;
  } else {
    router.navigate(['/auth']);
    return false;
  }
};
