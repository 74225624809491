<h1 mat-dialog-title>{{ 'cabinet.tableFields.changeReviewer' | translate }}</h1>
<div mat-dialog-actions>
  
  <mat-form-field appearance="outline">
    <mat-label>{{
      "cabinet.offers.plan.reviewStep.reviewer" | translate
      }}</mat-label>

    <mat-select [formControl]="reviewerControl">
      @for(reviewer of reviewerList; track reviewer.id){
      <mat-option [value]="reviewer.id">
        {{ reviewer.firstName + " " + reviewer.lastName }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div class="divButtons">
    <button 
    mat-button 
    color="primary"
    mat-raised-button 
    (click)="onCancel()"
    >{{ 'cabinet.profile.buttons.close' | translate }}</button> 

    <button 
    mat-button 
    color="primary"
    mat-raised-button 
    (click)="onConfirm()">
      {{ 'cabinet.offers.plan.reviewStep.assign' | translate }}
    </button>
  </div>
</div>
