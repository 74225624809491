import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { DialogService, OffersService } from '@core/services';
import { IPerson } from '@core/types/person';
import { ConfirmationDialogComponent, SpinnerComponent } from '@shared/components';
import { Subscription, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MoneyPipe } from '@core/pipes/money/money.pipe';
import { MatPaginatorIntlDe } from '@core/helpers/paginator-translate';

@Component({
  selector: 'app-calc-step-three',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule,
    SpinnerComponent,
    CommonModule,
    TranslateModule,
    MoneyPipe,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe }],
  templateUrl: './calc-step-three.component.html',
  styleUrls: ['./calc-step-three.component.css', './calc-step-three.component.css'],
})
export class CalcStepThreeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input()
  tableLoading: boolean = true;
  @Input() offerId;
  totalElements: number = 0;
  originalData: any[] = [];
  paginatedData: any[] = [];
  dataSource = new MatTableDataSource<any>();
  pageNumber: number = 0;
  pageSize: number = 20;
  direction;
  column;

  displayedColumns = [
    'name',
    'birthday',
    'gender',
    'maritalStatus',
    'category',
    'savingPlan',
    'bvgAge',
    'degreeOfEmployment',
    'grossSalary',
    'insuredSalary',
    'savingsContributionsEmployee',
    'savingsContributionsEmployer',
    'riskContributionsEmployee',
    'riskContributionsEmployer',
    'administrativeContributionsEmployee',
    'administrativeContributionsEmployer',
    'payAsYouGoContributionTotal',
    'costsOfLivingFundsContributionTotal',
  ];

  constructor(private _offersService: OffersService) {}

  ngOnInit(): void {
    this.handlePageEvent({ pageIndex: this.pageNumber, pageSize: this.pageSize } as PageEvent);
  }
  handleSortChange(column) {
    if (this.direction == 'desc') {
      this.direction = 'asc';
    } else {
      this.direction = 'desc';
    }
    this.column = column;
    this._offersService
      .getContribution(
        this.offerId,
        { pageNumber: this.pageNumber, pageSize: this.pageSize },
        this.column,
        this.direction
      )
      .subscribe((data) => {
        this.renderTableData(data);
      });
  }
  renderTableData({ content, totalElements, pageable: { pageSize, pageNumber } }) {
    this.tableLoading = true;
    this.dataSource = new MatTableDataSource(content);
    this.totalElements = totalElements;
    this.tableLoading = false;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
  paginate(array, page_size, page_number) {
    const startIndex = page_number * page_size;
    const endIndex = Math.min(startIndex + page_size, array.length);

    return array.slice(startIndex, endIndex);
  }
  handlePageEvent(event: PageEvent): void {
    this._offersService
      .getContribution(
        this.offerId,
        { pageNumber: event.pageIndex, pageSize: event.pageSize },
        this.column,
        this.direction
      )
      .subscribe((data) => {
        this.renderTableData(data);
      });
  }
}
