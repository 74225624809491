@if(nextLoading) {
<div class="spinner-box">
    <mat-spinner diameter="90" style="margin: 0 auto"></mat-spinner>
</div>
} @else {
<div class="sumDetailsDivParent">
    <div class="sumDetailsDiv">
        <label class="sumLabel">{{ 'cabinet.offers.plan.calculationResults.numbersOfInsurants' | translate }}</label>
        <p class="sumCalculation">
            {{ calculations?.insuredCount }}
        </p>
    </div>
    <div class="sumDetailsDiv">
        <label class="sumLabel">{{ 'cabinet.offers.plan.calculationResults.averageYear' | translate }}</label>
        <p class="sumCalculation">
            {{ calculations?.averageAge }}
        </p>
    </div>
    <div class="sumDetailsDiv">
        <label class="sumLabel">{{ 'cabinet.offers.plan.calculationResults.sumSalary' | translate }}</label>
        <p class="sumCalculation">
            {{ calculations?.insuredSalaries | money }}
        </p>
    </div>
    <div class="sumDetailsDiv">
        <label class="sumLabel">{{ 'cabinet.offers.plan.calculationResults.sumSavingContribution' | translate }}</label>
        <p class="sumCalculation">
            {{ calculations?.savingContributionsTotal | money }}
        </p>
    </div>
</div>
<mat-tab-group>
    <mat-tab [label]="'cabinet.offers.plan.overview' | translate">
        <ng-template matTabContent>
            <app-calc-step-one [calculations]="calculations"></app-calc-step-one>
        </ng-template>
    </mat-tab>
    <mat-tab [label]="'cabinet.offers.plan.benefits' | translate">
        <ng-template matTabContent>
            <app-calc-step-two [offerId]="offerId"></app-calc-step-two>
        </ng-template>
    </mat-tab>
    <mat-tab [label]="'cabinet.offers.plan.contributions' | translate">
        <ng-template matTabContent>
            <app-calc-step-three [offerId]="offerId"></app-calc-step-three>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<next-back-button
    [nextLoading]="nextLoading"
    (nextButton)="handleNextStep()"
    (backButton)="handleBackStep()"
    (middleButton)="handleBackStepResetCalculation()"
    [hideMiddleButton]="false"
    [middleButtonText]="'cabinet.profile.buttons.backAndRemoveCalculation'"
    [isMiddleButtonDisabled]="isPreviewMode || !isEditable"
></next-back-button>
}
