// import { registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
// import localeDe from '@angular/common/locales/de';
// import localeEn from '@angular/common/locales/en';
// import localeFr from '@angular/common/locales/fr';
// import localeIt from '@angular/common/locales/it';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LibraryAuthModule } from 'clan-auth';
import { LanguageData, LocaleModule } from 'models';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { TokenInterceptor } from './core/interceptors/token-interceptor';
import { MissingLocaleService } from './missing-locale.service';
import { ErrorInterceptor } from '@core/interceptors/error-interceptor';
// registerLocaleData(localeFr);

export function HttpLoadFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locale/', '.json');
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },

    importProvidersFrom(
      LocaleModule.forRoot(<LanguageData>environment.localeData),
      LibraryAuthModule.forRoot(environment.authApiUrl),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoadFactory,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingLocaleService,
        },
        useDefaultLang: true,
      })
    ),
    provideAnimations(),
  ],
};
