import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { DialogService, OffersService } from '@core/services';
import { IPerson } from '@core/types/person';
import { ConfirmationDialogComponent, SpinnerComponent } from '@shared/components';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MoneyPipe } from '@core/pipes/money/money.pipe';
import { MatPaginatorIntlDe } from '@core/helpers/paginator-translate';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-insurants-table',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule,
    SpinnerComponent,
    CommonModule,
    TranslateModule,
    MoneyPipe,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe }],
  templateUrl: './insurants-table.component.html',
  styleUrl: './insurants-table.component.scss',
})
export class InsurantsTableComponent implements OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatTable, { static: false }) table!: MatTable<IPerson>;
  @Input() isPreviewMode: boolean;
  @Input() isEditable: boolean;
  @Input() savingPlanNames: FormGroup;
  totalElements: number;
  dataSource = new MatTableDataSource([]);
  tableEmpty = false;
  pageNumber: number = 0;
  pageSize: number = 10;
  savingCategories;

  @Input() set insurantsTableData(value: any) {
    if (!Object.keys(value).length) return;

    this.renderTableData(value);
  }

  @Output() onEditInsurant: EventEmitter<number> = new EventEmitter();
  @Output() tableChange: EventEmitter<{ pageIndex: number; pageSize: number; length: number }> = new EventEmitter();
  @Output() deleteInsurant: EventEmitter<{ insurantId: number; insurantFirstName: string; insurantLastName: string }> =
    new EventEmitter();

  tableLoading = true;

  newOfferLoading = false;
  offerId = this._route.snapshot.params['id'];
  deleteLoading: number[] = [];

  private _subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private _route: ActivatedRoute,
    private _offersService: OffersService,
    private _dialogService: DialogService,
    private _translateService: TranslateService
  ) {}

  displayedColumns = [
    'name',
    'ssn',
    'collective',
    'savingPlan',
    'birthday',
    'maritalStatus',
    'sex',
    'levelOfEmployment',
    'salary',
    'savingsTotal',
    'savingsCapitalBvg',
    'levelOfDisability',
    'actions',
  ];

  handleEditOffer(id: number) {
    this.onEditInsurant.emit(id);
  }

  handleDeleteOffer(id: number, firstName?: string, lastName?: string): void {
    const insurantId = id;
    const insurantFirstName = firstName;
    const insurantLastName = lastName;
    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        title: this._translateService.instant('cabinet.navigationWizzard.deleteInsurant'),
        message:
          this._translateService.instant('cabinet.navigationWizzard.deleteInsurantText1') +
          ` ${firstName} ${lastName} ` +
          this._translateService.instant('cabinet.navigationWizzard.deleteInsurantText2'),
      },
      width: '300px',
    });

    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        this.deleteInsurant.emit({ insurantId, insurantFirstName, insurantLastName });
      }
    };
    this._dialogService.handleDialogClose(onDialogClose);
  }

  isDeleteLoading = (id: number) => this.deleteLoading.includes(id);
  ngOnInit() {
    this.tableLoading = true;
    this._offersService.getSavingsPlan(parseInt(this.offerId)).subscribe((data) => (this.savingCategories = data));
    this.tableLoading = false;
  }
  ngAfterViewInit(): void {
    const subs = this.sort?.sortChange.subscribe(() => {
      this.tableChange.emit({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
    });

    this._subscriptions.push(subs);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());

    this._dialogService.handleDialogUnsubscribe();
  }

  renderTableData({ content, totalElements, pageable: { pageSize, pageNumber } }) {
    this.tableLoading = true;
    this.dataSource = new MatTableDataSource(content);
    this.totalElements = totalElements;
    this.tableLoading = false;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.tableEmpty = !content.length;
  }

  handlePageChange({ pageSize, pageIndex }) {
    const subs = this._offersService
      .getAllPersons(this.offerId, { pageIndex, pageSize: pageSize }, this.column, this.direction)
      .subscribe((data) => {
        this.renderTableData(data);
      });
    this._subscriptions.push(subs);
  }

  direction;
  column;
  handleSortChange(column) {
    if (this.direction == 'desc') {
      this.direction = 'asc';
    } else {
      this.direction = 'desc';
    }
    this.column = column;
    const subs = this._offersService
      .getAllPersons(
        this.offerId,
        { pageNumber: this.pageNumber, pageSize: this.pageSize },
        this.column,
        this.direction
      )
      .subscribe((data) => {
        this.renderTableData(data);
      });
    this._subscriptions.push(subs);
  }
}
