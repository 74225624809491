import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IDocumentBody } from '@core/interfaces/documents/document.interface';
import { DialogService, OffersService } from '@core/services';
import { ReportService } from '@core/services/report/report.service';
import { THandleUpdateStepper, THandleUpdateStepsCompleted, TStepperMode, TStepsCompleted } from '@core/types';
import { ConfirmationDialogComponent, SpinnerComponent } from '@shared/components';
import { Subscription, switchMap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { IOffer, IOfferStatus } from '@core/interfaces/offers/offers.interface';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { OfferStatusType } from '@core/interfaces/offers/offers.interface';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-offers-step-final-documents',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        SpinnerComponent,
        TranslateModule,
        NextBackButtonComponent,
        MatFormFieldModule,
    ],
    templateUrl: './offers-step-final-documents.component.html',
    styleUrl: './offers-step-final-documents.component.scss',
})
export class OffersStepFinalDocumentsComponent implements OnInit, OnDestroy {
    @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
    @Input() stepsCompleted!: TStepsCompleted;
    @Input() stepper!: MatStepper;
    @Input() statusName: string;
    @Input() offer: IOffer;
    @Input() mode: TStepperMode;
    displayedColumns: string[] = ['title', 'dateCreated', 'actions'];
    dataSource = new MatTableDataSource<any>();
    key = 0;
    @Input()
    documentList: any;
    companyId: number;
    pensionFundId: number;
    foundationId: number;
    ownerId: number;
    body: IDocumentBody;
    ownerEmail: string;
    nextLoading = true;
    hideMiddleButton = false;
    styleType: number = 2;
    middleButtonId = 'middleButton';
    nextButtonId = 'nextButton';
    middleButtonText = 'cabinet.dashboard.statuses.reject';
    nextButtonText;
    acceptButtonText = 'cabinet.dashboard.statuses.accept';
    loggedInUserId: string;
    nextText: string = 'cabinet.profile.buttons.next';
    remarkControl: FormControl = new FormControl('', Validators.required);
    hiddenIcon: boolean = false;
    private _subscriptions: Subscription[] = [];

    private offerId: number = this._route.snapshot.params['id'];
    constructor(
        private _reportService: ReportService,
        private _sanitizer: DomSanitizer,
        private _route: ActivatedRoute,
        private _offersService: OffersService,
        private _router: Router,
        private _authorizationService: AuthorizationService,
        private _dialogService: DialogService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.changeStatusToReady();
        const subs = this._offersService.requestToUpdateAccessToFields.subscribe((statusName) =>
            this.isStepEditableByStatusAndMode(statusName)
        );

        this.getDocuments();
        this.loggedInUserId = this._authorizationService.getDecodedToken().sub;
        this._subscriptions.push(subs);
    }

    changeStatusToReady() {
        if (this.offer.offerStatus.status.name === OfferStatusType.REVIEW_ACCEPTED) {
            this._offersService.proceedToStatus(this.offerId, OfferStatusType.READY).subscribe({
                next: (data) => {
                    this.statusName = OfferStatusType.READY;
                    this._offersService.statusChangeMade.emit(OfferStatusType.READY);
                    this.isStepEditableByStatusAndMode(OfferStatusType.READY);

                    this.stepper.selected.completed = true;
                    this.nextLoading = false;
                    this.handleUpdateStepsCompleted({
                        ...this.stepsCompleted,
                        fifth: true,
                    });
                },
                error: (err) => console.error(err),
            });
        } else {
            this.isStepEditableByStatusAndMode(this.offer.offerStatus.status.name);
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    getOfferReviewedStatus() {
        this._offersService.getOfferStatuses(this.offerId).subscribe({
            next: (data) => {
                for (let index = 0; index < data.length; index++) {
                    const elem = data[index];
                    if (
                        elem.status.name === OfferStatusType.ACCEPTED ||
                        elem.status.name === OfferStatusType.REJECTED
                    ) {
                        this.remarkControl.patchValue(elem.remark);
                        break;
                    }
                }
            },
            error: (err) => {
                console.warn(err);
            },
        });
    }

    isStepEditableByStatusAndMode(statusName: OfferStatusType) {
        if (statusName !== OfferStatusType.READY || this.mode === 'preview') {
            this.getOfferReviewedStatus();
            this.remarkControl.disable();
            this.hideMiddleButton = true;
            this.nextButtonText = this.nextText;
            this.styleType = 0;
        } else {
            this.remarkControl.enable();
            this.hideMiddleButton = false;
            this.nextButtonText = this.acceptButtonText;
            this.styleType = 2;
            this.hiddenIcon = true;
        }
    }

    getDocuments() {
        this.nextLoading = true;
        this.companyId = this.offer?.company.id;
        this.pensionFundId = this.offer?.pensionFund.id;
        this.foundationId = this.offer?.pensionFund.foundation.id;
        this.ownerId = this.offer?.offerStatus.owner.id;
        this.ownerEmail = this.offer?.offerStatus.owner.email;
        this.body = {
            report: {
                reportType: 'other',
                reportEngine: 'JSR',
                reportName: null,
                reportTemplate: null,
                data: [
                    {
                        type: 'selectionData',
                        view: 'PS1_OFFER_REPORT',
                        selectionType: 'PERSON_ID',
                        objects: [this.offerId],
                    },
                ],
                bulkRendition: false,
            },
            dms: {
                service: 'CONFIGURABLE',
                owner: {
                    personId: this.ownerId,
                    companyId: this.companyId,
                    pensionFundId: this.pensionFundId,
                    foundationId: this.foundationId,
                },
                documentName: document.title,
                template: {
                    templateType: 'offerTemplate',
                    documentTitle: document.title,
                    documentDate: new Date(Date.now()).toISOString().slice(0, -1),
                    documentType: 'OFFER_SUMMARY',
                    documentClass: 'OFFERS',
                    author: this.ownerEmail,
                    personId: this.ownerId,
                    finalDocument: false,
                    companyId: this.companyId,
                    pensionFundId: this.pensionFundId,
                    foundationId: this.foundationId,
                    year: new Date(Date.now()).getFullYear(),
                },
            },
        };
        return this._reportService
            .getDocuments(this.companyId, this.pensionFundId, this.foundationId, false, this.offerId)
            .subscribe({
                next: (data) => {
                    const documents: any = data;

                    if (documents.length > 0) {
                        const finalDocuments = [];
                        const reviewDocuments = [];

                        this.dataSource.data = [];

                        documents.forEach((doc) =>
                            doc.name.includes('final') ? finalDocuments.push(doc) : reviewDocuments.push(doc)
                        );

                        if (this.areFinalDocumentsAlreadyGenerated(reviewDocuments, finalDocuments)) {
                            this.addAlreadyGeneratedDocuments(finalDocuments);
                            this.nextLoading = false;
                            return;
                        }

                        this.generateFinalDocuments(reviewDocuments, finalDocuments);
                        this.nextLoading = false;
                        return;
                    }
                    this.loadDocumentsFromSessionStorage();
                    this.nextLoading = false;
                },
                error: (err) => {
                    this.nextLoading = false;
                    console.warn(err);
                },
            });
    }

    generateFinalDocuments(reviewDocuments, finalDocuments) {
        if (reviewDocuments.length === 0) {
            return;
        }

        this.documentList.forEach((element) => {
            element.types.find((type) => {
                if (type.final !== undefined) {
                    this.pushDocumentToTable(type.final);
                    return false;
                }

                return reviewDocuments.find((document) => {
                    if (document.name.startsWith(type.key)) {
                        const finalDoc = this.finalDocumentAlreadyGenerated(finalDocuments, type.key);
                        reviewDocuments = reviewDocuments.filter((i) => i.name !== document.name);
                        if (finalDoc === undefined) {
                            this.generateDocument(element);
                            return true;
                        }

                        this.addFinalDocument(type, finalDoc.nodeId);
                        return true;
                    }
                    return false;
                });
            });
        });
    }

    addAlreadyGeneratedDocuments(finalDocuments) {
        this.documentList.forEach((document) => {
            document.types.forEach((type) => {
                const documentAdded = this.addFinalDocumentIfExist(type);
                if (!documentAdded) {
                    this.filterFinalDocuments(finalDocuments, type);
                }
            });
        });
    }

    filterFinalDocuments(finalDocuments, type) {
        finalDocuments.find((elem) => {
            if (elem.name.startsWith(type.key)) {
                this.addFinalDocument(type, elem.nodeId);
                return true;
            }
            return false;
        });
    }

    areFinalDocumentsAlreadyGenerated(reviewDocuments, finalDocuments) {
        return (
            reviewDocuments.length === finalDocuments.length ||
            (reviewDocuments.length === 0 && finalDocuments.length !== 0)
        );
    }

    loadDocumentsFromSessionStorage() {
        this.documentList.forEach((document) => {
            let found = false;
            document.types.forEach((type) => {
                if (found) {
                    return;
                }
                const documentAdded = this.addFinalDocumentIfExist(type);
                if (!documentAdded && type.bytes !== undefined) {
                    this.generateDocument(document);
                    found = true;
                }
            });
        });
    }

    addFinalDocumentIfExist(type) {
        if (type.final !== undefined) {
            this.pushDocumentToTable(type.final);
            return true;
        }
        return false;
    }

    addFinalDocument(type, nodeId) {
        this.nextLoading = true;
        this._reportService.getDocument(nodeId).subscribe({
            next: (data) => {
                type.final = this.updateDocument(type, data, nodeId);
                this.pushDocumentToTable(type.final);
                this.nextLoading = false;
            },
            error: (err) => {
                this.nextLoading = false;
                console.warn(err);
            },
        });
    }

    pushDocumentToTable(document) {
        this.dataSource.data.push(document);
        this.dataSource.data = this.dataSource.data;
    }

    finalDocumentAlreadyGenerated(finalDocuments, key) {
        return finalDocuments.find((elem) => elem.name.startsWith(key));
    }

    generateDocument(documentRequest) {
        this.nextLoading = true;
        documentRequest.types.forEach((document) => {
            this.body.report.reportTemplate = document.key;
            this.body.report.reportName = `${document.docType}_${document.key}_OFFERID_${this.offerId}_final`;

            this._reportService.generateDocument(this.body).subscribe({
                next: (nodeId) => {
                    this.addFinalDocument(document, nodeId);
                    this.nextLoading = false;
                },
                error: (err) => {
                    this.nextLoading = false;
                    console.warn(err);
                },
            });
        });
    }

    updateDocument(document, data, uuid) {
        const blob = new Blob([data], { type: document.responseType });

        const url = URL.createObjectURL(blob);
        document.url = url;
        document.sanitizedUrl = this.sanitize(url);
        document.bytes = data;
        document.uuid = uuid;
        document.final = true;
        document.name = this.body.report.reportName;
        document.createdDate = new Date(Date.now()).toISOString().slice(0, -1).toString();
        document.id = this.companyId;

        return document;
    }

    sanitize(url: string) {
        return this._sanitizer.bypassSecurityTrustUrl(url);
    }

    preview(document, event) {
        event.preventDefault();

        if (document.docType == 'pdf') {
            window.open(document.url, '_blank');
            return;
        }
    }

    handleStatus(buttonPosition: string) {
        if (this.offer.offerStatus.status.name == 'READY') {
            if (this.loggedInUserId !== this.offer.offerStatus.owner.externalId || this.mode === 'preview') {
                this._router.navigate(['/offers/table']);
                return;
            }
            this.remarkControl.markAsTouched();
            if (this.remarkControl.invalid) {
                return;
            } else {
                let statusName;
                let title: string;
                let question: string;
                if (buttonPosition === this.middleButtonId) {
                    statusName = OfferStatusType.REJECTED;
                    title = 'cabinet.offers.plan.reviewStep.rejectOfferByClientTitle';
                    question = 'cabinet.offers.plan.reviewStep.rejectOfferByClientQuestion';
                }
                if (buttonPosition === this.nextButtonId) {
                    statusName = OfferStatusType.ACCEPTED;
                    title = 'cabinet.offers.plan.reviewStep.acceptOfferByClientTitle';
                    question = 'cabinet.offers.plan.reviewStep.acceptOfferByClientQuestion';
                }
                this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
                    data: {
                        title: this._translateService.instant(title),
                        message: this._translateService.instant(question),
                    },
                    width: '300px',
                });

                const onDialogCloseCalc = (confirmed: boolean) => {
                    if (confirmed) {
                        if (this.offer.offerStatus.owner.externalId === this.loggedInUserId) {
                            this._offersService
                                .proceedToStatus(this.offerId, statusName, this.remarkControl.value)
                                .subscribe({
                                    next: () => {
                                        this._offersService.statusChangeMade.emit(statusName);
                                        this.isStepEditableByStatusAndMode(statusName);
                                        this.handleNextStep();
                                    },
                                    error: (err) => console.error(err),
                                });
                        }
                    }
                };
                this._dialogService.handleDialogClose(onDialogCloseCalc);
            }
        } else {
            this.stepper.next();
        }
    }
    handleNextStep() {
        this.stepper.selected.completed = true;
        this.nextLoading = false;
        this.handleUpdateStepsCompleted({
            ...this.stepsCompleted,
            seventh: true,
        });
        this.stepper.next();
    }

    handleBackStep() {
        this.stepper.previous();
    }

    getMaterialIconForType(type) {
        switch (type) {
            case 'pdf':
                return 'picture_as_pdf';
            default:
                return 'article';
        }
    }
}
