export enum PensionFundTypeDE {
  OWN = 'EIGENES',
  SHARED = 'GEMEINSAMES',
  BLPK = 'BLPK',
}

export enum PensionFundTypeEN {
  OWN = 'OWN',
  SHARED = 'SHARED',
  BLPK = 'BLPK'
}
