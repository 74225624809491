import { AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportService } from '@core/services/report/report.service';
import { PDFDocument, degrees, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { OffersService } from '@core/services/offers/offers.service';
import { CommonModule } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { Locale } from 'models';
import { THandleUpdateStepper, THandleUpdateStepsCompleted, TStepperMode, TStepsCompleted } from '@core/types';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IDocument, IDocumentBody } from '@core/interfaces/documents/document.interface';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { Subscription } from 'rxjs';
import { IOffer } from '@core/interfaces/offers/offers.interface';
import { ProductService } from '@core/services';
import { MatTableModule } from '@angular/material/table';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { environment } from '@environment/environment';
import JSZip from 'jszip';

@Component({
    selector: 'app-offers-step-generate-documents',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSelectModule,
        MatStepperModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        SpinnerComponent,
        MatIconModule,
        TranslateModule,
        NextBackButtonComponent,
        MatExpansionModule,
    ],
    templateUrl: './offers-step-generate-documents.component.html',
    styleUrl: './offers-step-generate-documents.component.scss',
})
export class OffersStepGenerateDocumentsComponent implements OnInit, OnDestroy {
    @Input()
    currentLocale: Locale;
    @Input() mode: TStepperMode;
    @Input() stepper!: MatStepper;
    @Input() handleUpdateStepsCompleted!: THandleUpdateStepsCompleted;
    @Input() stepsCompleted!: TStepsCompleted;
    @Input() offer: IOffer;
    documentListCopy: IDocument[] = [];
    companyId: number;
    pensionFundId: number;
    foundationId: number;
    ownerId: number;
    ownerEmail: string;
    nextLoading = false;
    categoryNumber = 1;
    isPreviewMode: boolean;
    isEditable: boolean = true;
    isNextButtonDisabled: boolean = false;
    isMiddleButtonDisabled: boolean = true;
    displayedColumns: string[] = ['documents', 'actions'];
    openedPanel = false;
    closedPanel = false;
    openedPanelIndex = null;
    hiddenIcon = true;
    private _subscriptions: Subscription[] = [];
    private stepGenerateDocuments = 'cabinet.offers.stepGenerateDocuments';
    private typePDF = 'application/pdf';
    private typeDOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    documentList: IDocument[];
    body: IDocumentBody;
    private offerId: number = this.route.snapshot.params['id'];
    constructor(
        private _reportService: ReportService,
        private _productSerice: ProductService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private _offersService: OffersService,
        private _translateService: TranslateService
    ) {}

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngOnInit(): void {
    
        this.translateDocuments();
        this.updateCategory();
        this.isPreviewMode = this.mode == 'preview';
        this.createDocumentBody();
        this.isStepEditableByStatusAndMode(this.offer.offerStatus.status.name);
        const subs1 = this._offersService.reloadDocumentDataRequested.subscribe((value) => this.createDocumentBody());
        const subs3 = this._offersService.reloadCategoryRequested.subscribe((value) => this.updateCategory());
        const subs2 = this._offersService.requestToUpdateAccessToFields.subscribe((statusName) =>
            this.isStepEditableByStatusAndMode(statusName)
        );

        this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            let previous = this.documentList;
            let next = this.translateDocuments();
            let result = this.switchConfigTitleObjects(previous, next);
            this.documentListCopy = result;
            this.documentList = this.documentListCopy;
        });
        this._subscriptions.push(subs1, subs2, subs3);
    }

    switchConfigTitleObjects(arrayA, arrayB) {
        return arrayA.map((item, index) => {
            return {
                ...item,
                title: arrayB[index]?.title,
                config: arrayB[index]?.types[0]?.sanitizedUrl,
            };
        });

    }
    filterDocumentConfiguration() {
        const foundationName = this.offer.pensionFund.foundation.name;
        let documents:any = Object.assign({}, environment.config?.documentConfiguration);
        let documentResponse = JSON.parse(JSON.stringify(documents["default"].config))

        if(documents[foundationName]) {
            const foundationConfig = documents[foundationName];
            if(!foundationConfig.showDefault) {
                return foundationConfig.config;
            }

            foundationConfig.config.forEach(e => documentResponse.push(e))
        }
        return documentResponse;
    }

    translateDocuments() {
        let documents:any = this.filterDocumentConfiguration();
        this.documentList = []
        Object.values(documents).forEach(docConfig => {
            let docConfigCopy:any = Object.assign({}, docConfig);
            
            docConfigCopy.title = this._translateService.instant(docConfigCopy.title);
            let types = [];
            docConfigCopy.types.forEach(type => {
                let typeCopy:any = Object.assign({}, type);
                typeCopy.title = this._translateService.instant(typeCopy.title);
                types.push(typeCopy)
              })
              docConfigCopy.types = types;

            this.documentList.push(docConfigCopy)
        })
        
        return this.documentList;
    }
    updateCategory() {
        this._productSerice.getPlanSpecByOfferAndName(this.offerId, 'BSKAT').subscribe({
            next: (data) => {
                this.categoryNumber = data.value;

                this.documentListCopy = [];

                if (this.categoryNumber == 1) {
                    this.documentList.forEach((config) => {
                        if (
                            [
                                'OT01_pension_fund_regulations_category2',
                                'OT01_pension_fund_regulations_category3',
                            ].indexOf(config.types[0].key) === -1
                        ) {
                            this.documentListCopy.push(config);
                        }
                    });
                } else if (this.categoryNumber == 2) {
                    this.documentList.forEach((config) => {
                        if (['OT01_pension_fund_regulations_category3'].indexOf(config.types[0].key) === -1) {
                            this.documentListCopy.push(config);
                        }
                    });
                } else {
                    this.documentListCopy = this.documentList;
                }
            },
        });
    }

    isStepEditableByStatusAndMode(statusName: string): boolean {
        if (statusName === 'CALCULATED' && this.mode === 'preview') {
            this.isNextButtonDisabled = true;
            this.isEditable = false;
            return false;
        }
        if (statusName !== 'CALCULATED' || this.mode === 'preview') {
            this.isEditable = false;
            return false;
        }
        this.isEditable = true;
        return true;
    }

    generatePDF(documentRequest, index) {
        if (this.nextLoading) {
            return;
        }
    
        this.nextLoading = true;
        documentRequest.types.forEach((document) => {
            document.nextLoading = true;
            this.body.report.reportTemplate = document.key;
            this.body.report.reportName = `${document.docType}_${document.key}_OFFERID_${this.offerId}_review`;
            this._reportService.generateDocument(this.body).subscribe({
                next: (uuid) => {
                    this._reportService.getDocument(uuid).subscribe({
                        next: (data) => {
                            if (data) {
                                this.openedPanelIndex = index;
                            }
                            this.updateDocument(document, data, uuid);
                            this.checkIsMiddleButtonDisabled();
                            this.nextLoading = false;
                        },
                        error: (err) => {
                            this.nextLoading = false;
                            document.nextLoading = false;
                            console.warn(err);
                        },
                    });
                    
                },
                error: (err) => {
                    this.nextLoading = false;
                    document.nextLoading = false;
                    console.warn(err);
                },
            });
        });
    }
    updateDocument(document, data, uuid) {
        const blob = new Blob([data], { type: document.responseType });

        const url = URL.createObjectURL(blob);
        document.url = url;
        document.sanitizedUrl = this.sanitize(url);
        document.bytes = data;
        document.uuid = uuid;
        document.name = this.body.report.reportName;
        document.createdDate = new Date(Date.now()).toISOString().slice(0, -1).toString();
        document.id = this.companyId;
        document.nextLoading = false;
        
    }

    createDocumentBody() {
        this.nextLoading = true;

        this.companyId = this.offer.company.id;
        this.pensionFundId = this.offer.pensionFund.id;
        this.foundationId = this.offer.pensionFund.foundation.id;
        this.ownerId = this.offer.offerStatus.owner.id;
        this.ownerEmail = this.offer.offerStatus.owner.email;
        this.body = {
            report: {
                reportType: 'other',
                reportEngine: 'JSR',
                reportName: null,
                reportTemplate: null,
                data: [
                    {
                        type: 'selectionData',
                        view: 'PS1_OFFER_REPORT',
                        selectionType: 'PERSON_ID',
                        objects: [this.offerId],
                    },
                ],
                bulkRendition: false,
            },
            dms: {
                service: 'CONFIGURABLE',
                owner: {
                    personId: this.ownerId,
                    companyId: this.companyId,
                    pensionFundId: this.pensionFundId,
                    foundationId: this.foundationId,
                },
                documentName: document.title,
                template: {
                    templateType: 'offerTemplate',
                    documentTitle: document.title,
                    documentDate: new Date(Date.now()).toISOString().slice(0, -1),
                    documentType: 'OFFER_SUMMARY',
                    documentClass: 'OFFERS',
                    author: this.ownerEmail,
                    personId: this.ownerId,
                    finalDocument: false,
                    companyId: this.companyId,
                    pensionFundId: this.pensionFundId,
                    foundationId: this.foundationId,
                    year: new Date(Date.now()).getFullYear(),
                },
            },
        };
        this._reportService
            .getDocuments(this.companyId, this.pensionFundId, this.foundationId, true, this.offerId)
            .subscribe({
                next: (documentsResponse) => {
                    const list: any = documentsResponse;

                    if (list.length !== 0) {
                        list.forEach((document) => {
                            this.documentList.find((element) => {
                                element.types.forEach((doc) => {
                                    if (document.name.startsWith(doc.key) && document.name.endsWith(doc.docType)) {
                                        doc.nextLoading = true;
                                        this._reportService.getDocument(document.nodeId).subscribe({
                                            next: (data) => {
                                                this.updateDocument(doc, data, document.nodeId);
                                                this.checkIsMiddleButtonDisabled();
                                                return true;
                                            },
                                            error: (err) => {
                                                this.nextLoading = false;
                                                doc.nextLoading = false;
                                                console.warn(err);
                                            },
                                        });
                                    }
                                });
                            });
                        });
                    } else {
                        this.documentList.forEach((documentConfig) =>
                            this.removeSavedDocumentFromConfig(documentConfig)
                        );
                    }
                    this.nextLoading = false;
                },
                error: (err) => {
                    console.warn(err);
                    this.nextLoading = false;
                },
            });
    }

    download(document, event) {
        event.preventDefault();
        let docName = document.title.substring(0, document.title.indexOf('.'));
        let currentDate = new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '');
        let titelWithIdAndDate = docName + '-' + this.offerId + '-' + currentDate;
        if (document.docType == 'pdf') {
            PDFDocument.load(document.bytes).then((pdfDoc) => {
                const pages = pdfDoc.getPages();
                const watermarkText = 'Vorschau';

                this.drawWatermark(pages, watermarkText);
                pdfDoc.save().then((bytes) => {
                    const blob = new Blob([bytes], { type: document.responseType });
                    saveAs(blob, titelWithIdAndDate);
                });
            });
        } else if (document.docType == 'docx' || document.docType == 'xlsx') {
            const blob = new Blob([document.bytes], { type: document.responseType });
            saveAs(blob, titelWithIdAndDate);
        }
    }

    openPdfWithWatermark(document, event) {
        event.preventDefault();

        if (document.docType == 'pdf') {
            PDFDocument.load(document.bytes).then((pdfDoc) => {
                const pages = pdfDoc.getPages();
                const watermarkText = 'Vorschau';

                this.drawWatermark(pages, watermarkText);

                pdfDoc.save().then((bytes) => {
                    const blob = new Blob([bytes], { type: document.responseType });
                    const url = URL.createObjectURL(blob);

                    window.open(url, '_blank');
                });
            });
        }
    }

    drawWatermark(pages, watermarkText) {
        pages.forEach((page) => {
            const textSize = 200;

            // start from bottom left corner
            const xStart = 120;
            const yStart = 10;

            page.drawText(watermarkText, {
                x: xStart,
                y: yStart,
                size: textSize,
                color: rgb(0, 0, 0),
                opacity: 0.1,
                rotate: degrees(55),
            });
        });
        return pages;
    }

    removeGeneratedDocuments(documentConfig) {
        if (this.nextLoading) {
            return;
        }

        this.nextLoading = true;
        let arrayIds = [];
        documentConfig.types.forEach((document) => arrayIds.push(document.uuid));

        this._reportService.deleteDocuments(arrayIds).subscribe({
            next: () => {
                this.removeSavedDocumentFromConfig(documentConfig);
                this.checkIsMiddleButtonDisabled();
                this.nextLoading = false;
            },
            error: (err) => {
                this.nextLoading = false;
                console.warn(err);
            },
        });

    }

    removeSavedDocumentFromConfig(documentConfig) {
        documentConfig.types.forEach((document) => {
            document.sanitizedUrl = undefined;
            document.bytes = undefined;
            document.name = undefined;
            document.id = undefined;
            document.uuid = undefined;
            document.url = undefined;
            document.createdDate = undefined;
            document.nextLoading = false;
        });
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    handleNextStep() {
        this.stepper.selected.completed = true;
        this.nextLoading = false;
        this.handleUpdateStepsCompleted({
            ...this.stepsCompleted,
            fifth: true,
        });
        this._offersService.reloadReviewStepRequested.emit(true);
        this.stepper.next();
    }

    handleBackStep() {
        this.stepper.previous();
    }

  private documentCache: Map<string, Uint8Array> = new Map();

    // Download documents and create a ZIP file
    documentListDownload() {
        const zip = new JSZip();
        const folder = zip.folder(`Documents_Offer_${this.offerId}`);

        const fetchDocumentPromises = this.documentList.flatMap(documentConfig =>
            documentConfig.types.forEach(document => {
            const doc: any = document;
            if (doc.bytes) { // Check if document.bytes exists
                const docName = this.generateDocumentName(document);
                folder.file(docName, doc.bytes, { binary: true });
            }
            })
        );

        // Wait for all documents to be fetched and added to the ZIP
        Promise.all(fetchDocumentPromises)
            .then(() => zip.generateAsync({ type: 'blob' }))
            .then(content => saveAs(content, `Documents_Offer_${this.offerId}.zip`))
            .catch(err => console.error('Error generating or downloading zip:', err));
    }

// Generate the file name based on document type and title
    private generateDocumentName(document): string {
        let extension = '';
        if (document.docType === 'pdf') {
            extension = '.pdf';
        } else if (document.docType === 'docx') {
            extension = '.docx';
        } else if (document.docType === 'xlsx') {
            extension = '.xlsx';
        }
        return `${document.title}${extension}`;
    }
    
    private checkIsMiddleButtonDisabled(): void {
        this.isMiddleButtonDisabled = true;
        this.documentList.flatMap(documentConfig =>
            documentConfig.types.forEach((document: any) => {
                if (document.bytes) {
                    this.isMiddleButtonDisabled = false; 
                } 
            })
        );      
    }
  
}
