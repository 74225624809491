import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { default as PlanFromOffer } from '../../../../../core/types/plan';
import { OffersService, ProductService } from '@core/services';
import { ActivatedRoute } from '@angular/router';
import RetirementTarrif from '@core/types/RetirementTariff';
import { Subscription, forkJoin, zip, Subject } from 'rxjs';
import ConversionTariff from '@core/types/ConversionTariff';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SaveEventName } from '@core/enums/save-event-name.enum';

@Component({
    selector: 'app-general-info',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
    ],
    templateUrl: './general-info.component.html',
    styleUrl: './general-info.component.scss',
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
    @Input() isPreviewMode: boolean;
    @Input() isEditable: boolean;
    @Input() isPlanEditable: boolean;
    @ViewChild('bvgElement') bvgElement;

    offerId: number;

    varsToUpdate = {
        planGeneralForm: {},
        coordinationTypeForm: {},
        categoryForm: {},
        conversionForm: {},
        pensionAgesForm: {},
    };
    @Input()
    currentPlan: PlanFromOffer;

    @Input() saveSubject: Subject<SaveEventName>;

    @Output() selectedCategoryChange: EventEmitter<number> = new EventEmitter();

    wageAvailableCodes = [];
    coordinationTypes = [];
    currentRetirementTariff: RetirementTarrif = null;
    currentConversionTariff: ConversionTariff = null;
    categoryList = [
        { value: 1, display: 1 },
        { value: 2, display: 2 },
        { value: 3, display: 3 },
    ];
    ages: string[] = ['18', '19', '20', '21', '22', '23', '24', '25'];
    selectedCategory = this.categoryList[2].value;
    categoryCountChanged: boolean = false;

    pensionAgesForm: FormGroup = this.fb.group({
        minimumEarlyRetirementAge: [0, [Validators.required]],
        regularRetirementAge: [0, [Validators.required]],
        maximumRetirementAge: [0, [Validators.required]],
        maximumWefWithdrawalAge: [0, [Validators.required]],
        endOfContributionAge: [0, [Validators.required]],
    });

    conversionForm: FormGroup = this.fb.group({
        key_1900: [1, [Validators.required, Validators.min(0), Validators.max(10)]],
    });

    coordinationTypeForm: FormGroup = this.fb.group({
        BSKOORDK1: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
        BSKOORDK2: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
        BSKOORDK3: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
    });

    categoryForm: FormGroup = this.fb.group({
        category: [2, [Validators.required]],
    });
    planGeneralForm: FormGroup = this.fb.group({
        BSBEZPLAN: ['', [Validators.required]],
        BSVORSALT: [0, [Validators.required, Validators.min(0), Validators.max(25)]],
        BSTFK: [0, [Validators.required, Validators.min(0)]],
        BSKAT: [0, [Validators.required, Validators.min(0)]],
        BSWARTEF: [0, [Validators.required, Validators.min(0)]],
        BSKOOABIND: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],
        BSINDBA: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],
        BSINDBAK1: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],
        BSINDBAK2: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],
        BSINDBAK3: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],
        BSLOMIN: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],
        BSUMLBEITR: [0, [Validators.required, Validators.min(0), Validators.max(10000000)]],

        BSMAXKAPOP: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    });
    accordingBvg = 0;
    planVarsToUpdate = [];
    private _subscriptions: Subscription[] = [];
    statusChange = false;
    constructor(
        private fb: FormBuilder,
        private _productService: ProductService,
        private _offersService: OffersService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        if (this.isPreviewMode || !this.isEditable || !this.isPlanEditable) {
            this.disableForms();
        }

        this.getPlan();

        const subs = this._offersService.requestToUpdateAccessToFields.subscribe((statusName) => {
            if (statusName == 'PENDING') {
                this.statusChange = true;
                this.getPlan();
            } else {
                this.statusChange = false;
            }

            this.isStepEditableByStatusAndMode(statusName);
        });
        const subs1 = this.saveSubject.subscribe((value) => this.save(value));
        this._subscriptions.push(subs, subs1);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    disableForms() {
        this.planGeneralForm.disable();
        this.pensionAgesForm.disable();
        this.conversionForm.disable();
        this.coordinationTypeForm.disable();
        this.categoryForm.disable();
    }

    enableForms() {
        this.planGeneralForm.enable();
        this.pensionAgesForm.enable();
        this.conversionForm.enable();
        this.coordinationTypeForm.enable();
        this.categoryForm.enable();
    }

    isStepEditableByStatusAndMode(statusName: string): boolean {
        if (statusName !== 'PENDING' || this.isPreviewMode || !this.isPlanEditable) {
            this.isEditable = false;
            this.disableForms();
            return false;
        }
        this.isEditable = true;
        this.bvgElement?.enable;
        this.enableForms();
        return true;
    }

    getPlan() {
        this.offerId = this.route.snapshot.params['id'];

        const req1 = this._productService.getCodesByPlanSpecId(this.currentPlan.BSMAXBA.id);
        const req2 = this._productService.getCodesByPlanSpecId(this.currentPlan.BSKOORD.id);
        const req3 = this._productService.getTarrifs(this.offerId, 'retirement');
        const req4 = this._productService.getTarrifs(this.offerId, 'conversion');

        const subs = forkJoin([req1, req2, req3, req4]).subscribe({
            next: (response) => {
                this.wageAvailableCodes = response[0];
                this.coordinationTypes = response[1];
                let list: any = response[2];

                this.currentRetirementTariff = list.retirement[0];

                list = response[3];

                this.currentConversionTariff = list.conversion[0];

                this.fillForm();
            },
            error: (err) => {
                console.error(err);
            },
        });
        this._subscriptions.push(subs);
    }

    fillForm() {
        let pensionAges = this.pensionAgesForm.controls;
        let conversionControls = this.conversionForm.controls;
        let generalForm = this.planGeneralForm.controls;
        let coordinationTypeFormControls = this.coordinationTypeForm.controls;
        generalForm['BSKAT'].setValue(this.currentPlan?.BSKAT.value ? parseInt(this.currentPlan?.BSKAT.value) : 3);
        this.selectedCategory = this.BSKAT.value;
        generalForm['BSBEZPLAN'].setValue(this.currentPlan?.BSBEZPLAN?.codeValueDescription);

        generalForm['BSVORSALT'].setValue(this.currentPlan?.BSVORSALT?.value);
        generalForm['BSKOOABIND'].setValue(parseInt(this.currentPlan?.BSKOOABIND?.value));

        generalForm['BSINDBAK1'].setValue(this.currentPlan?.BSINDBAK1?.value);
        generalForm['BSINDBAK2'].setValue(this.currentPlan?.BSINDBAK2?.value);
        generalForm['BSINDBAK3'].setValue(this.currentPlan?.BSINDBAK3?.value);

        coordinationTypeFormControls['BSKOORDK1'].setValue(this.currentPlan?.BSKOORDK1?.value);
        coordinationTypeFormControls['BSKOORDK2'].setValue(this.currentPlan?.BSKOORDK2?.value);
        coordinationTypeFormControls['BSKOORDK3'].setValue(this.currentPlan?.BSKOORDK3?.value);

        generalForm['BSINDBA'].setValue(this.currentPlan?.BSINDBA?.value);
        generalForm['BSLOMIN'].setValue(this.currentPlan?.BSLOMIN?.value);
        generalForm['BSMAXKAPOP'].setValue(this.currentPlan?.BSMAXKAPOP?.value);

        conversionControls['key_1900'].setValue(this.convertToPercent(this.currentConversionTariff?.key_1900));

        //pensionAge
        pensionAges['minimumEarlyRetirementAge'].setValue(this.currentRetirementTariff?.minimumEarlyRetirementAge);
        pensionAges['regularRetirementAge'].setValue(this.currentRetirementTariff?.regularRetirementAge);
        pensionAges['maximumWefWithdrawalAge'].setValue(this.currentRetirementTariff?.maximumWefWithdrawalAge);
        pensionAges['maximumRetirementAge'].setValue(this.currentRetirementTariff?.maximumRetirementAge);
        pensionAges['endOfContributionAge'].setValue(this.currentRetirementTariff?.endOfContributionAge);

        //RiskTab
        //Risk Service
        generalForm['BSTFK'].setValue(parseInt(this.currentPlan?.BSTFK?.value));
        generalForm['BSUMLBEITR'].setValue(this.currentPlan?.BSUMLBEITR?.value);
        generalForm['BSWARTEF'].setValue(parseInt(this.currentPlan?.BSWARTEF?.value));

        this.planGeneralForm.markAsPristine();
        this.planGeneralForm.markAsUntouched();

        this.coordinationTypeForm.markAsPristine();
        this.coordinationTypeForm.markAsUntouched();

        if (this.isPreviewMode || !this.isEditable || !this.isPlanEditable) {
            return;
        }

        this.saveUpdateValueInVarsToUpdate(this.categoryForm, 'categoryForm');
        this.saveUpdateValueInVarsToUpdate(this.coordinationTypeForm, 'coordinationTypeForm');
        this.saveUpdateValueInVarsToUpdate(this.planGeneralForm, 'planGeneralForm');
        this.saveUpdateValueInVarsToUpdate(this.pensionAgesForm, 'pensionAgesForm');
        this.saveUpdateValueInVarsToUpdate(this.conversionForm, 'conversionForm');
    }

    saveUpdateValueInVarsToUpdate(form: FormGroup, formKey: string) {
        Object.entries(form.controls).forEach((value) => {
            const [key1, control1] = value;
            this.resetFormAfterStatusChange();
            const subs = control1.valueChanges.subscribe((val) => {
                const [key2, control2] = value;
                this.varsToUpdate[formKey][key2] = val;
            });
            this._subscriptions.push(subs);
        });
    }

    resetFormAfterStatusChange() {
        if (this.statusChange) {
            this.varsToUpdate.planGeneralForm = {};
            this.varsToUpdate.categoryForm = {};
            this.varsToUpdate.conversionForm = {};
            this.varsToUpdate.coordinationTypeForm = {};
            this.varsToUpdate.pensionAgesForm = {};
            this.planVarsToUpdate = [];
        }
    }

    get BSINDBAK1() {
        return this.planGeneralForm.get('BSINDBAK1');
    }

    get BSINDBAK2() {
        return this.planGeneralForm.get('BSINDBAK2');
    }

    get BSINDBAK3() {
        return this.planGeneralForm.get('BSINDBAK3');
    }

    get BSKAT() {
        return this.planGeneralForm.get('BSKAT');
    }
    get BSBEZPLAN() {
        return this.planGeneralForm.get('BSBEZPLAN');
    }

    get BSVORSALT() {
        return this.planGeneralForm.get('BSVORSALT');
    }

    get BSLOMIN() {
        return this.planGeneralForm.get('BSLOMIN');
    }

    get BSKOORDK1() {
        return this.coordinationTypeForm.get('BSKOORDK1');
    }

    get BSKOORDK2() {
        return this.coordinationTypeForm.get('BSKOORDK2');
    }

    get BSKOORDK3() {
        return this.coordinationTypeForm.get('BSKOORDK3');
    }

    get minimumEarlyRetirementAge() {
        return this.pensionAgesForm.get('minimumEarlyRetirementAge');
    }

    get regularRetirementAge() {
        return this.pensionAgesForm.get('regularRetirementAge');
    }

    get maximumRetirementAge() {
        return this.pensionAgesForm.get('maximumRetirementAge');
    }

    get maximumWefWithdrawalAge() {
        return this.pensionAgesForm.get('maximumWefWithdrawalAge');
    }

    get endOfContributionAge() {
        return this.pensionAgesForm.get('endOfContributionAge');
    }

    get key_1900() {
        return this.conversionForm.get('key_1900');
    }

    get BSMAXKAPOP() {
        return this.planGeneralForm.get('BSMAXKAPOP');
    }

    handleCategorySelectionChange({ value }) {
        this.selectedCategory = value;
        this.planGeneralForm.controls['BSKAT'].setValue(value);
        this.selectedCategoryChange.emit(value);
    }
    setValueToBvg(plan: string) {
        this.planGeneralForm.get(plan).setValue(0);
        this.planGeneralForm.get(plan).markAsDirty();
    }

    extractPlanVarsToUpdate(form: FormGroup, formKey: string) {
        if (form.dirty) {
            Object.entries(this.varsToUpdate[formKey]).forEach((item) => {
                const [key, value] = item;
                this.planVarsToUpdate.push({
                    name: key,
                    value: value,
                });
            });
        }
    }

    save(saveEventName) {
        if (
            this.planGeneralForm.invalid ||
            this.categoryForm.invalid ||
            this.coordinationTypeForm.invalid ||
            this.pensionAgesForm.invalid ||
            this.conversionForm.invalid
        ) {
            return;
        }

        const pensionTariffValuesToUpdate = [];

        this.extractPlanVarsToUpdate(this.categoryForm, 'categoryForm');

        if (this.coordinationTypeForm.dirty) {
            Object.entries(this.varsToUpdate.coordinationTypeForm).forEach((item) => {
                const [key, value] = item;

                let code = this.coordinationTypes.find((item) => item.value === value);

                this.planVarsToUpdate.push({
                    name: key,
                    value: code.id,
                });
            });
        }

        this.extractPlanVarsToUpdate(this.planGeneralForm, 'planGeneralForm');
        this.varsToUpdate.planGeneralForm = {};

        if (this.pensionAgesForm.dirty) {
            const body = { ...this.currentRetirementTariff, ...this.pensionAgesForm.value };
            body.tariffGroup = 'retirement';
            pensionTariffValuesToUpdate.push(body);
        }

        if (this.conversionForm.dirty) {
            const body = {
                ...this.currentConversionTariff,
                ...{
                    tariffGroup: 'conversion',
                    key_1900: this.convertFromPercent(this.key_1900.value),
                },
            };
            pensionTariffValuesToUpdate.push(body);
        }
        let listSubscriptions = [];
        if (this.planVarsToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.changeAllPlanFieldValues(this.offerId, this.planVarsToUpdate));
        }
        if (pensionTariffValuesToUpdate.length !== 0) {
            listSubscriptions.push(this._productService.updateTariffValues(pensionTariffValuesToUpdate));
        }

        if (listSubscriptions.length > 0) {
            const subs = zip(...listSubscriptions).subscribe({
                next: () => {
                    this._productService.updatedForm(saveEventName);
                    if (this.planVarsToUpdate.length !== 0) {
                        if (this.planVarsToUpdate.find((plan) => plan.name === 'BSKAT')) {
                            this._offersService.reloadCategoryRequested.emit(true);
                        }
                    }
                    if (saveEventName === SaveEventName.NEXT_BTN_SAVE) {
                        this._productService.updatedForm(SaveEventName.SWITCH_TAB_SAVE);
                    }
                },
                error: (err) => {},
            });
            this._subscriptions.push(subs);
        } else {
            this._productService.updatedForm(saveEventName);
        }
    }

    convertToPercent(textParam) {
        let text = '' + textParam;
        if (text === '0') {
            return parseInt(text);
        }
        const indexOfPoint = text.indexOf('.');
        if (indexOfPoint === -1) {
            text += '00';
            return parseInt(text);
        } else if (indexOfPoint === text.length - 1) {
            text = text.substring(0, indexOfPoint);
            text += '00';
            return parseInt(text);
        } else {
            const firstPart = text.substring(0, indexOfPoint);
            const secondPart = text.substring(indexOfPoint + 1);
            if (secondPart.length == 1) {
                return parseInt(firstPart + secondPart + '0');
            } else if (secondPart.length == 2) {
                return parseInt(firstPart + secondPart);
            }
            const thirdPart = secondPart.substring(2);
            return parseFloat(firstPart + secondPart.substring(0, 2) + '.' + thirdPart);
        }
    }

    convertFromPercent(textParam) {
        let text = '' + textParam;
        if (text === '0') {
            return 0;
        } else if (text === '100') {
            return 1;
        }

        const indexOfPoint = text.indexOf('.');

        if (indexOfPoint === -1) {
            if (text.length === 2) {
                text = '0.' + text;
            } else if (text.length === 1) {
                text = '0.0' + text;
            }
            return parseFloat(text);
        }

        const firstPart = text.substring(0, indexOfPoint);
        text = text.replace('.', '');

        if (firstPart.length === 2) {
            text = '0.' + text;
        } else if (firstPart.length === 1) {
            text = '0.0' + text;
        }

        return parseFloat(text);
    }
}
