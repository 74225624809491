import { AsyncPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { concatMap, Observable } from 'rxjs';

import {
  IFilterOffers,
  IOffer,
  OfferStatusType,
  offerStatusColorMapMapping,
} from '@core/interfaces/offers/offers.interface';
import { DialogService, OffersService, SnackbarService } from '@core/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, SpinnerComponent } from '@shared/components';
import { Subscription, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchOffersComponent } from './search-offers/search-offers.component';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
import { ChangeReviewerDialogComponent } from './change-reviewer-dialog/change-reviewer-dialog.component';
import { ColoredStatusBoxComponent } from '@shared/components/colored-status-box/colored-status-box.component';
import { ChangeOwnerDialogComponent } from './change-owner-dialog/change-owner-dialog.component';
import { MatPaginatorIntlDe } from '@core/helpers/paginator-translate';

@Component({
  selector: 'app-offers-table',
  templateUrl: './offers-table.component.html',
  styleUrls: ['./offers-table.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule,
    SpinnerComponent,
    TranslateModule,
    SearchOffersComponent,
    ColoredStatusBoxComponent,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe }],
})
export class OffersTableComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<IOffer>;

  dataSource: MatTableDataSource<IOffer> = new MatTableDataSource<IOffer>([]);
  pageEvent: PageEvent;
  page;
  sortTrack;
  totalElements: number;
  pageNumber: number = 0;
  pageSize: number = 10;
  isLoading = true;
  statusFromDashboard: string;
  status = [];
  newOfferLoading = false;
  displayedColumns = ['id', 'startDate', 'ownerName', 'companyName', 'reviewerName', 'status', 'actions'];
  deleteLoading: number[] = [];
  tableEmpty = this.dataSource.connect().pipe(map((data) => data.length === 0));
  paramsValues: IFilterOffers;
  loggedInUserId: string;
  onlyReadOnlyRole = false;
  readOnlyUser = false;
  adminRole = false;
  readOnlyRole = false;
  editRole = false;
  showEditBtn = false;
  offerStatuses = OfferStatusType;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    public dialog: MatDialog,
    private _offersService: OffersService,
    private _dialogService: DialogService,
    private _snackbarService: SnackbarService,
    private _translateService: TranslateService,
    private _authorizationService: AuthorizationService
  ) {}

  handleNewOffer() {
    this._router.navigate([`../new`, 'offer'], {
      relativeTo: this._route,
    });
    // this._offersService
    //   .createOffer()
    //   .pipe(
    //     tap((offer: IOffer) => {
    //     })
    //   )
    //   .subscribe();
  }

  handlePreviewOffer(id: number) {
    this._router.navigate([`../preview/${id}`], {
      relativeTo: this._route,
    });
  }

  handleChangeReviewer(offer: IOffer) {
    this._dialogService.handleDialogOpen(ChangeReviewerDialogComponent, {
      data: {
        offer: offer,
      },
      width: '300px',
    });

    const onDialogClose = (confirmed: number) => {
      this.listAllOffers(this.page, this.sortTrack, this.paramsValues);
    };

    this._dialogService.handleDialogClose(onDialogClose);
  }

  handleEditOffer(id: number) {
    this._router.navigate([`../edit/${id}`], {
      relativeTo: this._route,
    });
  }

  handleChangeOwner(offer: IOffer) {
    this._dialogService.handleDialogOpen(ChangeOwnerDialogComponent, {
      data: {
        offer: offer,
      },
      width: '300px',
    });

    const onDialogClose = (confirmed: number) => {
      this.listAllOffers(this.page, this.sortTrack, this.paramsValues);
    };
    this._dialogService.handleDialogClose(onDialogClose);
  }

  handleDeleteOffer(id: number, name: string): void {
   
    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        title: this._translateService.instant('cabinet.tableFields.deleteOffer'),
        message: this._translateService.instant('cabinet.tableFields.deleteOfferInfo', { name: name }),
      },
      width: '300px',
    });

    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        this.deleteLoading = [...this.deleteLoading, id];
        const deleteOfferSubscription = this._offersService
          .deleteOffer(id)
          .pipe(switchMap(() => this._offersService.getOffers()))
          .subscribe(() => {
            this.deleteLoading = this.deleteLoading.filter((dL) => dL !== id);
            this._snackbarService.handleOpenSnackbar(
              this._translateService.instant('cabinet.notifications.offerDeleteSuccess', { name: name }),
              'Close',
              'success'
            );
            this.listAllOffers(this.page, this.sortTrack, this.paramsValues);
          });
        this._subscriptions.push(deleteOfferSubscription);
      }
    };
    this._dialogService.handleDialogClose(onDialogClose);
  }
  listAllOffers(page?, sort?, additionalParams?) {
    this.isLoading = true;
    this.dataSource = null;
    this._offersService.getAllOffers(page, sort, additionalParams).subscribe({
      next: (data) => {
        this.dataSource = new MatTableDataSource(data.content);
        this.totalElements = data.totalElements;
        this.pageSize = data.pageable.pageSize;
        this.pageNumber = data.pageable.pageNumber;
        this.isLoading = false;
      },
      error: (err) => {
        console.warn(err);
      },
    });
  }

  isDeleteLoading = (id: number) => this.deleteLoading.includes(id);
  sortDirectionCheck(sort: MatSort) {
    return (sort.direction = sort.direction === '' ? 'asc' : sort.direction);
  }
  getServices(event?: PageEvent) {
    this.page = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    };
    this.sortTrack = {
      sortColumn: `${this.sort.active}`,
      sortDirection: this.sortDirectionCheck(this.sort),
    };

    this.listAllOffers(this.page, this.sortTrack, this.paramsValues);

    return event;
  }

  receiveSearchValueControl(params: IFilterOffers) {
    this.paramsValues = params;
    this.listAllOffers(this.page, this.sortTrack, this.paramsValues);
  }

  isUserAuthorizedForAction(row, actionName) {
    if (actionName === 'EDIT') {
      return (
        row.offerStatus.owner.externalId === this.loggedInUserId ||
        (row.offerStatus.reviewer?.externalId === this.loggedInUserId && row.offerStatus.status.name === 'IN_REVIEW')
      );
    }
    if (actionName === 'DELETE') {
      return row.offerStatus.owner.externalId === this.loggedInUserId;
    }
    if (actionName === 'CHANGE-OWNER' && row.userHaveOfferAccess) {
      if (
        ['PENDING', 'CALCULATED', 'IN_REVIEW', 'REVIEW_ACCEPTED', 'REVIEW_REJECTED', 'READY'].includes(
          row.offerStatus.status.name
        )
      ) {
        return (
          row.offerStatus.owner.externalId !== this.loggedInUserId &&
          row.offerStatus.reviewer?.externalId !== this.loggedInUserId
        );
      }
    }
    return false;
  }
  copyOffer(id: number, body: IOffer) {
    this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
      data: {
        title: this._translateService.instant('cabinet.tableFields.copyOffer'),
        message: this._translateService.instant('cabinet.tableFields.copyOfferInfo', { offerId: body.name }),
      },
      width: '300px',
    });
    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        this._offersService
          .copyOffer(id, body)
          .pipe(
            switchMap(() => {
              return new Observable((observer) => {
                this.listAllOffers(this.page, this.sortTrack, this.paramsValues);
                observer.next(); // Emit a value since concatMap expects an observable
                observer.complete();
              });
            })
          )
          .subscribe(() => {
            this._snackbarService.handleOpenSnackbar(
              this._translateService.instant('cabinet.notifications.offerCopySuccess', { id: body.name }),
              'Close',
              'success'
            );
          });
      }
    };
    this._dialogService.handleDialogClose(onDialogClose);
  }
  ngOnInit(): void {
    const token = this._authorizationService.getDecodedToken();
    if(!token) {
      this.isLoading = false;
      return;
    }
    this.loggedInUserId = this._authorizationService.getDecodedToken().sub;
    this.statusFromDashboard = this._route.snapshot.queryParams['status'];
    const index1 = token.realm_access.roles.findIndex((i) => i === 'offertool_read_only');
    const index2 = token.realm_access.roles.findIndex((i) => i === 'offertool_edit');

    this.readOnlyUser = index1 !== -1 && index2 === -1;
    this._authorizationService.getAllRoles().subscribe({
      next: (roles) => {
        this.adminRole = roles.includes('offertool_admin');
        this.readOnlyRole = roles.includes('offertool_read_only');
        this.editRole = roles.includes('offertool_edit');
        this.onlyReadOnlyRole = this.readOnlyRole && !this.adminRole && !this.editRole;
      },
      error: (err) => console.warn(err),
    });
    if (!this.statusFromDashboard) {
      this.listAllOffers();
    }
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => {
      this.getServices({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());

    this._dialogService.handleDialogUnsubscribe();
  }

  protected readonly offerStatusColorMapMapping = offerStatusColorMapMapping;
}
