<h1 mat-dialog-title>
  {{ showEditText(isEditMode) | translate }}
</h1>
<div mat-dialog-content>
  <form [formGroup]="insuranceStepForm" (submit)="handleInsuranceSubmit()">
    <fieldset>
      <legend>{{ "cabinet.profile.personalDetails" | translate }}</legend>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{ "cabinet.tableFields.firstName" | translate }}</mat-label>
        <input matInput formControlName="firstName" />
        @if (firstName.hasError('required')) {
        <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
        >}
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{ "cabinet.tableFields.lastName" | translate }}</mat-label>
        <input matInput formControlName="lastName" />
        @if (lastName.hasError('required')) {
        <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
        >}
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.personal.dateOfBirth" | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          [max]="yesterday"
          formControlName="dateOfBirth"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1 color="primary"></mat-datepicker>
        <mat-error *ngIf="dateOfBirth.invalid">{{
          "auth.errors.dateOfBirthInvalid" | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.personal.gender" | translate
        }}</mat-label>
        <mat-select ngDefaultControl formControlName="title">
          @for (title of titleList; track title.id) {
          <mat-option [value]="title.id">
            {{ title.value }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{ "cabinet.profile.personal.sov" | translate }}</mat-label>
        <input matInput formControlName="socialSecurityNo" />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.tableFields.martialStatus" | translate
        }}</mat-label>
        <mat-select ngDefaultControl formControlName="maritalStatus">
          @for (status of maritalStatusList; track status[0]) {
          <mat-option [value]="status[0]">
            {{
              "cabinet.profile.personal.maritalStatusCode." + status[1]
                | translate
            }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </fieldset>
    <fieldset>
      <legend>{{ "cabinet.profile.employmentInformation" | translate }}</legend>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{ "cabinet.home.infoField.salary" | translate }}</mat-label>
        <input matInput formControlName="salary" />
        @if (salary.hasError('required')) {
        <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
        >} @if (salary.hasError('pattern')) {
        <mat-error>{{ "auth.errors.enterValidValue" | translate }}</mat-error
        >}
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.levelOfEmploymentInPercent" | translate
        }}</mat-label>
        <input matInput formControlName="levelOfEmployment" /><mat-icon
        matSuffix
        >%</mat-icon>
        @if (levelOfEmployment.hasError('required')) {
        <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
        >} @if (levelOfEmployment.hasError('max')) {
        <mat-error>{{
          "auth.errors.maxError" | translate : { value: 100 }
        }}</mat-error
        >}@if (levelOfEmployment.hasError('pattern')) {
          <mat-error>{{ "auth.errors.enterValidValue" | translate }}</mat-error
        >}
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.employment.savingsTotal" | translate
        }}</mat-label>
        <input matInput formControlName="savingsTotal" />
        @if (savingsTotal.hasError('required')) {
        <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
        >} @if (savingsTotal.hasError('pattern')) {
        <mat-error>{{ "auth.errors.enterValidValue" | translate }}</mat-error
        >}
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.employment.savingsCapitalBvg" | translate
        }}</mat-label>
        <input matInput formControlName="savingsCapitalBvg" />
        @if (savingsCapitalBvg.hasError('required')) {
        <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
        >} @if (savingsCapitalBvg.hasError('pattern')) {
        <mat-error>{{ "auth.errors.enterValidValue" | translate }}</mat-error
        >}
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.levelOfDisability" | translate
        }}</mat-label>
        <input matInput formControlName="levelOfDisability" />
        @if (levelOfDisability.hasError('max')) {
        <mat-error>{{
          "auth.errors.maxError" | translate : { value: 100 }
        }}</mat-error
        >}
        @if (levelOfDisability.hasError('required')) {
          <mat-error>{{ "auth.errors.fieldRequired" | translate }}</mat-error
          >}
      </mat-form-field>
      <mat-form-field
        class="input--lg"
        class="offer-form-item"
        appearance="outline"
      >
        <mat-label>{{
          "cabinet.profile.employment.collective" | translate
        }}</mat-label>
        <mat-select formControlName="collective">
          <mat-option
            *ngFor="let collective of collectiveList"
            selected
            [value]="collective"
          >
            {{ collective }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="collective.invalid">{{
          "auth.errors.collective" | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field class="input--lg" class="offer-form-item" appearance="outline">
        <mat-label>{{
          "cabinet.profile.employment.savingPlan" | translate
        }}</mat-label>
        <mat-select formControlName="savingPlan">
          <mat-option
            *ngFor="let plan of savingPlanList"
            selected
            [value]="plan.value"
          >
            {{ plan.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="savingPlan.invalid">{{
          "auth.errors.plan" | translate
        }}</mat-error>
      </mat-form-field>
    </fieldset>
    <div class="insurant-dialog-actions" mat-dialog-actions>
      <button
        class="medium-action-button"
        mat-raised-button
        (click)="handleCloseDialog($event)"
      >
        {{ "cabinet.profile.buttons.close" | translate }}
      </button>
      @if (nextLoading) {
      <button
        class="medium-action-button"
        type="submit"
        color="primary"
        disabled
        mat-raised-button
        disabled="true"
      >
        <mat-spinner diameter="18"></mat-spinner>
      </button>
      } @else {
      <button
        class="medium-action-button"
        type="submit"
        color="primary"
        mat-raised-button
      >
        {{ "cabinet.profile.buttons.save" | translate }}
      </button>
      }
    </div>
  </form>
</div>
