<div class="tableComponent">
  <header class="offers_table_header">
    <h1 class="title">{{ "cabinet.navigation.offers" | translate }}</h1>

    @if(!onlyReadOnlyRole){ @if (newOfferLoading) {
    <button
      class="major-action-button"
      mat-raised-button
      color="primary"
      disabled
      (click)="handleNewOffer()"
    >
      <mat-spinner diameter="24"></mat-spinner>
    </button>
    } @else {
    <button
      class="major-action-button"
      mat-raised-button
      color="primary"
      (click)="handleNewOffer()"
    >
      <mat-icon iconPositionEnd>add</mat-icon>
      {{ "cabinet.tableFields.newOffer" | translate }}</button
    >}}
  </header>
  <app-search-offers
    [statusFromDashboard]="statusFromDashboard"
    (searchValue)="receiveSearchValueControl($event)"
  ></app-search-offers>
  <div class="mat-elevation-z8">
    <div class="table-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        class="full-width-table"
        matSort
        matSortActive="id"
        matSortDirection="desc"
        aria-label="Elements"
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "cabinet.offers.offerId" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "cabinet.offers.mutationDate" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.offerStatus?.timestamp | date : "dd.MM.yyyy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ownerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "cabinet.offers.createdBy" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              row?.offerStatus?.owner?.firstName +
                " " +
                row?.offerStatus?.owner?.lastName
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "cabinet.offers.company" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row?.company?.name }}</td>
        </ng-container>
        <ng-container matColumnDef="reviewerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "cabinet.offers.assignee" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            @if(row?.offerStatus?.reviewer?.firstName){
            <ng-container>
              {{
                row?.offerStatus?.reviewer?.firstName +
                  " " +
                  row?.offerStatus?.reviewer?.lastName
              }}
            </ng-container>
            }@else {
            <ng-container>
              {{ "cabinet.offers.unassigned" | translate }}
            </ng-container>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ "cabinet.tableFields.status" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <app-colored-status-box
              [text]="
                'cabinet.offers.offerStatus.' + row?.offerStatus?.status?.name
                  | translate
              "
              [backgroundColor]="
                offerStatusColorMapMapping[row?.offerStatus?.status?.name]
                  .background
              "
              [textColor]="
                offerStatusColorMapMapping[row?.offerStatus?.status?.name]
                  .textColor
              "
            >
            </app-colored-status-box>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ "cabinet.tableFields.actions" | translate }}
          </th>
          <td mat-cell *matCellDef="let row" class="action-button">
            <button
              class="preview-button"
              mat-icon-button
              [matTooltip]="'cabinet.tableFields.preview' | translate"
              [disabled]="isDeleteLoading(row.id)"
              (click)="handlePreviewOffer(row.id)"
            >
              <mat-icon>visibility</mat-icon>
            </button>
            @if(!onlyReadOnlyRole && isUserAuthorizedForAction(row, 'EDIT')) {
            <button
              class="edit-button"
              mat-icon-button
              [matTooltip]="'cabinet.tableFields.editOffer' | translate"
              [disabled]="isDeleteLoading(row.id)"
              (click)="handleEditOffer(row.id)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            } @if(!onlyReadOnlyRole && isUserAuthorizedForAction(row,
            'CHANGE-OWNER')) {
            <button
              class="change-owner-button"
              mat-icon-button
              [matTooltip]="'cabinet.tableFields.changeOwner' | translate"
              [disabled]="isDeleteLoading(row.id)"
              (click)="handleChangeOwner(row)"
            >
              <mat-icon>manage_accounts</mat-icon>
            </button>
            } @if(row.offerStatus.status.name === 'IN_REVIEW' && !readOnlyUser)
            {
            <button
              class="change-reviewer-button"
              mat-icon-button
              [matTooltip]="'cabinet.tableFields.changeReviewer' | translate"
              [disabled]="isDeleteLoading(row.id)"
              (click)="handleChangeReviewer(row)"
            >
              <mat-icon>switch_account</mat-icon>
            </button>
            } @if(!onlyReadOnlyRole && isUserAuthorizedForAction(row, 'DELETE')
            && row.offerStatus.status.deletable) {
            <button
              class="delete-button"
              mat-icon-button
              [matTooltip]="'cabinet.tableFields.deleteOffer' | translate"
              [disabled]="isDeleteLoading(row.id)"
              (click)="handleDeleteOffer(row.id, row.name)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            } @if(row?.offerStatus?.status?.name !== offerStatuses.ACCEPTED &&
            row?.offerStatus?.status?.name !== offerStatuses.EXPORTED &&
            row.userHaveOfferAccess){
            <button
              mat-icon-button
              class="copy-button"
              (click)="copyOffer(row.id, row)"
              [matTooltip]="'cabinet.tableFields.copyOffer' | translate"
              [disabled]="isDeleteLoading(row.id)"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
            }
          </td>
        </ng-container>
        @if(tableEmpty){
        <ng-container>
          <tr class="mat-row empty-table" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              <p class="empty-table_message">
                {{ "cabinet.tableFields.noDataMatchingFilter" | translate }}
              </p>
            </td>
          </tr>
        </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    @if (isLoading) {
    <app-spinner></app-spinner>
    }
    <mat-paginator
      class="paginator"
      [length]="totalElements"
      [pageSize]="pageSize"
      [pageIndex]="pageNumber"
      [pageSizeOptions]="[10, 20, 30, 50, 100]"
      (page)="pageEvent = getServices($event)"
    ></mat-paginator>
  </div>
</div>
