<div class="riskForm" *ngIf="savingcat1">
    <div>
        <div class="title-plan">
            {{ 'cabinet.offers.plan.BPIndividualSavingratesCategory1' | translate }}
        </div>
    </div>
    <savings-table 
    [offerId]="offerId" 
    [isPreviewMode]="isPreviewMode"
    [isEditable]="isEditable" 
    [isPlanEditable]="isPlanEditable"
    category="savingcat1" 
    (savingcat)="showCategory($event, 'savingcat1')"
    ></savings-table>
</div>

@if (categories === 2 || categories === 3) {
    <div class="riskForm" *ngIf="savingcat2">
        <div>
            <div class="title-plan">
                {{ 'cabinet.offers.plan.BPIndividualSavingratesCategory2' | translate }}
            </div>
        </div>
        <savings-table 
        [offerId]="offerId" 
        [isPreviewMode]="isPreviewMode"
        [isEditable]="isEditable" 
        [isPlanEditable]="isPlanEditable"
        category="savingcat2" 
        (savingcat)="showCategory($event, 'savingcat2')"
        ></savings-table>
    </div>
}
@if (categories === 3) {
    <div class="riskForm" *ngIf="savingcat3">
        <div>
            <div class="title-plan">
                {{ 'cabinet.offers.plan.BPIndividualSavingratesCategory3' | translate }}
            </div>
        </div>
        <savings-table 
        [isPreviewMode]="isPreviewMode"
        [isEditable]="isEditable"
        [isPlanEditable]="isPlanEditable"
        [offerId]="offerId" 
        category="savingcat3" 
        (savingcat)="showCategory($event, 'savingcat3')"
        ></savings-table>
    </div>
}