<form
  [formGroup]="planGeneralForm"
  class="general-info-form"
  onsubmit="return false"
>
  <div class="plan-form">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.generalTab.planDesignation" | translate
      }}</mat-label>
      <input matInput formControlName="BSBEZPLAN" type="text" />
      @if (BSBEZPLAN?.invalid) {
      <mat-error>{{
        "cabinet.offers.plan.generalTab.planDesignationError" | translate
      }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.generalTab.numberOfCategory" | translate
      }}</mat-label>
      <mat-select
        [value]="selectedCategory"
        (selectionChange)="handleCategorySelectionChange($event)"
        [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
        formControlName="BSKAT"
      >
        @for (category of categoryList; track category.value) {
        <mat-option [value]="category.value">
          {{ category.display }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.generalTab.ageOfEntryintoPension" | translate
      }}</mat-label>
      <mat-select
        formControlName="BSVORSALT"
        [value]="currentPlan.BSVORSALT.value"
      >
        <mat-option *ngFor="let age of ages" [value]="age">{{
          age
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="bvg-group">
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.minimumWageLimit" | translate
        }}</mat-label>
        <input matInput formControlName="BSLOMIN" type="number" />
        <mat-error *ngIf="BSLOMIN?.invalid">{{
          "cabinet.offers.plan.generalTab.minimumWageLimitError" | translate
        }}</mat-error>
      </mat-form-field>
      <mat-chip-listbox aria-label="Fish selection">
        <mat-chip-option
          [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
          [selected]="BSLOMIN.value == 0"
          (click)="setValueToBvg('BSLOMIN')"
          >{{ "cabinet.product.accordingBVG" | translate }}</mat-chip-option
        >
      </mat-chip-listbox>
    </div>
  </div>

  <form [formGroup]="coordinationTypeForm" class="category-form">
    <!-- Category 1 -->
    <div class="category-form-item">
      <div class="categoryTitle">
        {{ "cabinet.profile.personal.collectives.1" | translate }}
      </div>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.coordinationTypeCat1" | translate
        }}</mat-label>
        <mat-select ngDefaultControl formControlName="BSKOORDK1" required>
          <mat-option
            *ngFor="let types of coordinationTypes"
            [value]="types.value"
          >
            {{ types.description }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="BSKOORDK1?.invalid">{{
          "cabinet.offers.plan.generalTab.coordinationTypeCat1Error" | translate
        }}</mat-error>
      </mat-form-field>
      <form [formGroup]="planGeneralForm" class="general-info-form">
        <div class="bvg-group">
          <mat-form-field appearance="outline" class="width100">
            <mat-label class="fieldLabel">{{
              "cabinet.offers.plan.generalTab.individualSalaryMaxCat1"
                | translate
            }}</mat-label>
            <input matInput formControlName="BSINDBAK1" type="number" />
            <mat-error *ngIf="BSINDBAK1?.invalid">{{
              "cabinet.offers.plan.generalTab.individualSalaryMaxCat1Error"
                | translate
            }}</mat-error>
          </mat-form-field>
          <mat-chip-listbox aria-label="Fish selection">
            <mat-chip-option
              [selected]="BSINDBAK1.value == 0"
              [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
              (click)="setValueToBvg('BSINDBAK1')"
            >
              {{ "cabinet.product.accordingBVG" | translate }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </form>
    </div>

    <!-- Category 2 (conditionally rendered) -->
    <div
      *ngIf="selectedCategory === 2 || selectedCategory === 3"
      class="category-form-item"
    >
      <div class="categoryTitle">
        {{ "cabinet.profile.personal.collectives.2" | translate }}
      </div>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.coordinationTypeCat2" | translate
        }}</mat-label>
        <mat-select ngDefaultControl formControlName="BSKOORDK2" required>
          <mat-option
            *ngFor="let types of coordinationTypes"
            [value]="types.value"
          >
            {{ types.description }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="BSKOORDK2?.invalid">{{
          "cabinet.offers.plan.generalTab.coordinationTypeCat2Error" | translate
        }}</mat-error>
      </mat-form-field>
      <form [formGroup]="planGeneralForm" class="general-info-form">
        <div class="bvg-group">
          <mat-form-field appearance="outline" class="width100">
            <mat-label class="fieldLabel">{{
              "cabinet.offers.plan.generalTab.individualSalaryMaxCat2"
                | translate
            }}</mat-label>
            <input matInput formControlName="BSINDBAK2" type="number" />
            <mat-error *ngIf="BSINDBAK2?.invalid">{{
              "cabinet.offers.plan.generalTab.individualSalaryMaxCat2Error"
                | translate
            }}</mat-error>
          </mat-form-field>
          <mat-chip-listbox aria-label="Fish selection">
            <mat-chip-option
              [selected]="BSINDBAK2.value == 0"
              [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
              (click)="setValueToBvg('BSINDBAK2')"
            >
              {{ "cabinet.product.accordingBVG" | translate }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </form>
    </div>

    <!-- Category 3 (conditionally rendered) -->
    <div *ngIf="selectedCategory === 3" class="category-form-item">
      <div class="categoryTitle">
        {{ "cabinet.profile.personal.collectives.3" | translate }}
      </div>
      <mat-form-field appearance="outline">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.coordinationTypeCat3" | translate
        }}</mat-label>
        <mat-select ngDefaultControl formControlName="BSKOORDK3" required>
          <mat-option
            *ngFor="let types of coordinationTypes"
            [value]="types.value"
          >
            {{ types.description }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="BSKOORDK3?.invalid">{{
          "cabinet.offers.plan.generalTab.coordinationTypeCat3Error" | translate
        }}</mat-error>
      </mat-form-field>
      <form [formGroup]="planGeneralForm" class="general-info-form">
        <div class="bvg-group">
          <mat-form-field appearance="outline" class="width100">
            <mat-label class="fieldLabel">{{
              "cabinet.offers.plan.generalTab.individualSalaryMaxCat3"
                | translate
            }}</mat-label>
            <input matInput formControlName="BSINDBAK3" type="number" />
            <mat-error *ngIf="BSINDBAK3?.invalid">{{
              "cabinet.offers.plan.generalTab.individualSalaryMaxCat3Error"
                | translate
            }}</mat-error>
          </mat-form-field>
          <mat-chip-listbox aria-label="Fish selection">
            <mat-chip-option
              [selected]="BSINDBAK3.value == 0"
              [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
              (click)="setValueToBvg('BSINDBAK3')"
            >
              {{ "cabinet.product.accordingBVG" | translate }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </form>
    </div>
  </form>
</form>

<form [formGroup]="pensionAgesForm" class="general-info-form">
  <div class="title-plan">
    {{ "cabinet.offers.plan.generalTab.individualFinalAge" | translate }}
  </div>
  <div class="plan-form">
    <div class="plan-form-item">
      <mat-form-field appearance="outline" class="width100">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.minimumEarlyRetirementAge" | translate
        }}</mat-label>
        <input
          matInput
          formControlName="minimumEarlyRetirementAge"
          type="number"
        />
        <mat-error *ngIf="minimumEarlyRetirementAge?.invalid">{{
          "cabinet.offers.plan.generalTab.minimumEarlyRetirementAgeError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="plan-form-item">
      <mat-form-field appearance="outline" class="width100">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.ordinaryRetirementAge" | translate
        }}</mat-label>
        <input matInput formControlName="regularRetirementAge" type="number" />
        <mat-error *ngIf="regularRetirementAge?.invalid">{{
          "cabinet.offers.plan.generalTab.regularRetirementAgeError" | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="plan-form-item">
      <mat-form-field appearance="outline" class="width100">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.maximumRetirementAge" | translate
        }}</mat-label>
        <input matInput formControlName="maximumRetirementAge" type="number" />
        <mat-error *ngIf="maximumRetirementAge?.invalid">{{
          "cabinet.offers.plan.generalTab.maximumRetirementAgeError" | translate
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="plan-form">
    <div class="plan-form-item">
      <mat-form-field appearance="outline" class="width100">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.maximumAgeForEarlyWithdrawalWEF"
            | translate
        }}</mat-label>
        <input
          matInput
          formControlName="maximumWefWithdrawalAge"
          type="number"
        />
        <mat-error *ngIf="maximumWefWithdrawalAge?.invalid">{{
          "cabinet.offers.plan.generalTab.maximumAgeForEarlyWithdrawalWEFError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="plan-form-item">
      <mat-form-field appearance="outline" class="width100">
        <mat-label class="fieldLabel">{{
          "cabinet.offers.plan.generalTab.ageForEndOfContributions" | translate
        }}</mat-label>
        <input matInput formControlName="endOfContributionAge" type="number" />
        <mat-error *ngIf="endOfContributionAge?.invalid">{{
          "cabinet.offers.plan.generalTab.ageForEndOfContributionsError"
            | translate
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
<div class="title-plan">
  {{ "cabinet.offers.plan.generalTab.benefitRetirementAge" | translate }}
</div>
<div class="plan-form">
  <form [formGroup]="conversionForm" class="plan-form-item">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.generalTab.conversionRateRetirementAge" | translate
      }}</mat-label>
      <input matInput formControlName="key_1900" type="number" /><mat-icon
        matSuffix
        >%</mat-icon
      >
      <mat-error *ngIf="key_1900?.invalid">{{
        "cabinet.offers.plan.generalTab.conversionRateRetirementAgeError"
          | translate
      }}</mat-error>
    </mat-form-field>
  </form>
  <form [formGroup]="planGeneralForm" class="plan-form-item">
    <mat-form-field appearance="outline">
      <mat-label class="fieldLabel">{{
        "cabinet.offers.plan.generalTab.maximumLumpSumWithdrawal" | translate
      }}</mat-label>
      <input matInput formControlName="BSMAXKAPOP" type="number" /><mat-icon
        matSuffix
        >%</mat-icon
      >
      <mat-error *ngIf="BSMAXKAPOP?.invalid">{{
        "cabinet.offers.plan.generalTab.maximumLumpSumWithdrawalError"
          | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</div>
