@if (!tableEmpty) {
<div class="mat-elevation-z8 insurant-table">
  <table
    mat-table
    class="full-width-table"
    matSort
    aria-label="Elements"
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('lastName')"
      >
        {{ "cabinet.tableFields.name" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.firstName }} {{ row.lastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ssn">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('ssn')"
      >
        {{ "cabinet.tableFields.socialSecurity" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.ssn }}
      </td>
    </ng-container>
    <ng-container matColumnDef="categorie">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "cabinet.tableFields.categorie" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.categorie }}
      </td>
    </ng-container>
    <ng-container matColumnDef="birthday">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('dateOfBirth')"
      >
        {{ "cabinet.tableFields.birthday" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.dateOfBirth | date : "dd.MM.yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="maritalStatus">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('maritalStatus')"
      >
        {{ "cabinet.tableFields.martialStatus" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{
          "cabinet.profile.personal.maritalStatusCode." + row.maritalStatus
            | translate
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="sex">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('sex')"
      >
        Sex
      </th>
      <td mat-cell *matCellDef="let row">
        {{ "cabinet.profile.personal.genderCode." + row.sex | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="levelOfEmployment">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('levelOfEmployment')"
      >
        {{ "cabinet.profile.levelOfEmployment" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.levelOfEmployment / 100 | percent }}
      </td>
    </ng-container>
    <ng-container matColumnDef="salary">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('salary')"
      >
        {{ "cabinet.tableFields.annualSalaryCHF" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.salary | money }}
      </td>
    </ng-container>
    <ng-container matColumnDef="savingsTotal">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('savingsTotal')"
      >
        {{ "cabinet.tableFields.regulatorySaving" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.savingsTotal | money }}
      </td>
    </ng-container>
    <ng-container matColumnDef="savingsCapitalBvg">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('savingsCapitalBvg')"
      >
        {{ "cabinet.tableFields.savingTotal" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.savingsCapitalBvg | money }}
      </td>
    </ng-container>
    <ng-container matColumnDef="levelOfDisability">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('levelOfDisability')"
      >
        {{ "cabinet.tableFields.levelOfDisability" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.levelOfDisability / 100 | percent }}
      </td>
    </ng-container>
    <ng-container matColumnDef="collective">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('collective')"
      >
        {{ "cabinet.tableFields.categories" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ "cabinet.tableFields.category" | translate }} {{ row.collective }}
      </td>
    </ng-container>
    <ng-container matColumnDef="savingPlan">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (click)="handleSortChange('savingPlan')"
      >
        {{ "cabinet.tableFields.savingPlan" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
       <div *ngIf="row.savingPlan == 0">{{ "cabinet.offers.plan.savingContributionTab.defaultText"  | translate }} </div>
       <div *ngIf="row.savingPlan == 1">{{ "cabinet.offers.plan.savingContributionTab.minusText"  | translate  }} </div>
       <div *ngIf="row.savingPlan == 2">{{ "cabinet.offers.plan.savingContributionTab.plusText"  | translate  }} </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.tableFields.actions" | translate }}
      </th>
      <td mat-cell class="action-button" *matCellDef="let row">
        <button
          type="button"
          class="edit-button"
          mat-icon-button
          [matTooltip]="'cabinet.tableFields.editOffer' | translate"
          color="primary"
          [disabled]="isDeleteLoading(row.id) || isPreviewMode || !isEditable"
          (click)="handleEditOffer(row.id)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          type="button"
          class="delete-button"
          mat-icon-button
          color="warn"
          [matTooltip]="'cabinet.tableFields.deleteOffer' | translate"
          [disabled]="isDeleteLoading(row.id) || isPreviewMode || !isEditable"
          (click)="handleDeleteOffer(row.id, row.firstName, row.lastName)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    @if(tableEmpty){
    <ng-container>
      <tr class="mat-row empty-table" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <p class="empty-table_message">
            {{ "cabinet.tableFields.noData" | translate }}
          </p>
        </td>
      </tr>
    </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  @if (tableLoading) {
  <app-spinner></app-spinner>
  }
  <mat-paginator
    #paginator
    [length]="totalElements"
    [pageSize]="pageSize"
    [pageIndex]="pageNumber"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="handlePageChange($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
}
