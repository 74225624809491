<div #tableWrapper class="table-wrapper">
  <table
    mat-table
    [dataSource]="dataSourceCat"
    (keydown.enter)="$event.preventDefault()"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="criticalValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.offers.plan.savingContributionTab.ageClasses" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <div class="form-field">
          <input
            appArrowNavigation
            (input)="onChange(i, row)"
            class="ageInput"
            type="number"
            matInput
            [(ngModel)]="row.criticalValue"
            pattern="/^-?\d+(\.\d{5})?$/"
            [disabled]="isPreviewMode || !isEditable ||  !isPlanEditable"
          />
        </div>
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="criticalValueTo">
    <th mat-header-cell *matHeaderCellDef>
      {{ "cabinet.offers.plan.savingContributionTab.ageClasses" | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <span>{{ row.criticalValueTo }}</span>
    </td>
  </ng-container> -->
    <ng-container matColumnDef="employee">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.offers.plan.savingContributionTab.employee" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <input
          appArrowNavigation
          [tabindex]="i"
          class="ageInput"
          type="number"
          [(ngModel)]="row.employee"
          (input)="onChange(i, row)"
          [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="employer">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.offers.plan.savingContributionTab.employer" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <input
          class="ageInput"
          appArrowNavigation
          type="number"
          [(ngModel)]="row.employer"
          (input)="onChange(i, row)"
          [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="minimum">
      <th mat-header-cell *matHeaderCellDef>
        {{
          "cabinet.offers.plan.savingContributionTab.EmployerDeductionsForSavingsMinus"
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <input
          class="ageInput"
          appArrowNavigation
          type="number"
          [(ngModel)]="row.minimum"
          (input)="onChange(i, row)"
          [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="maximum">
      <th mat-header-cell *matHeaderCellDef>
        {{
          "cabinet.offers.plan.savingContributionTab.EmployerSurchargeForSavingsPlus"
            | translate
        }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <input
          class="ageInput"
          appArrowNavigation
          type="number"
          [(ngModel)]="row.maximum"
          (input)="onChange(i, row)"
          [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "cabinet.tableFields.actions" | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let i = index" class="button-box">
        <button
          mat-icon-button
          class="removeButtonStyle"
          type="button"
          (click)="removeAgeStep(row.tariffValueId)"
          [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsCat"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsCat"></tr>
  </table>
  <table
    mat-table
    [dataSource]="[{}]"
    class="mat-elevation-z8"
    (keydown.enter)="$event.preventDefault()"
  >
    <form [formGroup]="planSavingForm" onsubmit="return false">
      <ng-container matColumnDef="criticalValue">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "cabinet.offers.plan.savingContributionTab.ageClasses" | translate
          }}
        </th>
        <td mat-cell *matCellDef="let row">
          <input
            id="ageFrom"
            class="ageInput"
            appArrowNavigation
            formControlName="ageFrom"
            type="number"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="employee">
        <th mat-header-cell *matHeaderCellDef>
          {{ "cabinet.offers.plan.savingContributionTab.employee" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <input
            id="ageFrom"
            class="ageInput"
            appArrowNavigation
            formControlName="employee"
            type="number"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="employer">
        <th mat-header-cell *matHeaderCellDef>
          {{ "cabinet.offers.plan.savingContributionTab.employer" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <input
            id="ageFrom"
            class="ageInput"
            appArrowNavigation
            formControlName="employer"
            type="number"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="minimum">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "cabinet.offers.plan.savingContributionTab.EmployerDeductionsForSavingsMinus"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let row">
          <input
            id="ageFrom"
            class="ageInput"
            appArrowNavigation
            formControlName="minimum"
            type="number"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="maximum">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "cabinet.offers.plan.savingContributionTab.EmployerSurchargeForSavingsPlus"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let row">
          <input
            id="ageFrom"
            class="ageInput"
            appArrowNavigation
            formControlName="maximum"
            type="number"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ "cabinet.tableFields.actions" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="button-box">
          <button
            class="addButton"
            mat-mini-fab
            color="primary"
            type="button"
            (click)="handleSubmit()"
            [disabled]="isPreviewMode || !isEditable || !isPlanEditable"
          >
            <mat-icon>add</mat-icon>
          </button>
        </td>
      </ng-container>
    </form>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsCat"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsCat"></tr>
  </table>
  <div class="errorNotification" *ngIf="errorNotificationActive">Error</div>
</div>
