import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { StrongPasswordRegx } from '@core/constances/constances';
import { SnackbarService, DialogService } from '@core/services';
import { UserService } from '@core/services/user/user.service';
import { TStepperMode } from '@core/types';
import { confirmedValidator } from '@core/validators/confirm-password.validator';
import { passwordMatchValidator } from '@core/validators/password-match.validator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@shared/components';
import { NextBackButtonComponent } from '@shared/components/next-back-button/next.back.button.component';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatChipsModule,
    MatSelectModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NextBackButtonComponent,
  ],
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss',
})
export class CreateUserComponent implements OnInit, OnDestroy {
  checkAdmin = false;
  checkEditor = false;
  checkViewer = false;
  checkClient = false;
  mode: TStepperMode;
  roles = [];
  foundations = [];
  currentUser;
  selectedRoles = [];
  selectedFoundations = [];
  typePassword: string = 'password';
  typeConfirmPassword: string = 'password';
  saveLoading = false;
  private _subscriptions: Subscription[] = [];
  currentUserId = this._activatedRoute.snapshot.params['id'];
  uferForm: FormGroup;
  tenant: string;
  userForm = this._fb.group(
    {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.pattern(StrongPasswordRegx)],
      confirmPassword: [''],
      position: ['', Validators.required],
      role: [[0], Validators.required],
      foundation: [[0], Validators.required],
    },
    {
      validators: [
        confirmedValidator('password', 'confirmPassword'),
        passwordMatchValidator('firstName', 'lastName', 'email', 'password'),
      ],
    }
  );

  constructor(
    private _fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _translate: TranslateService,
    private _dialogService: DialogService
  ) {}

  ngOnInit() {
    this.tenant = localStorage.getItem('tenant');
    const routeSubscription = this._activatedRoute.url.subscribe((segments) => {
      this.mode = segments[0].path as TStepperMode;
    });
    this._subscriptions.push(routeSubscription);
    const getRoles$ = this._userService.getUserRoles();
    const getFoundations$ = this._userService.getUserFoundations();
    forkJoin([getRoles$, getFoundations$]).subscribe(([roles, foundations]) => {
      this.roles = roles;
      this.foundations = foundations;
      if (this.mode == 'edit') {
        this.populateUserForm();
      }
    });
    this.password.valueChanges.subscribe((value) => {
      if (value !== '') {
        this.confirmPassword.setValidators([Validators.required]);
      } else {
        this.confirmPassword.setValidators(null);
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get email() {
    return this.userForm.get('email');
  }
  get password() {
    return this.userForm.get('password');
  }
  get confirmPassword() {
    return this.userForm.get('confirmPassword');
  }
  get firstName() {
    return this.userForm.get('firstName');
  }
  get lastName() {
    return this.userForm.get('lastName');
  }
  get phone() {
    return this.userForm.get('phone');
  }
  get position() {
    return this.userForm.get('position');
  }
  get role() {
    return this.userForm.get('role');
  }

  get foundation() {
    return this.userForm.get('foundation');
  }
  get address() {
    return this.userForm.get('address');
  }

  populateUserForm() {
    this._userService.getCurrentUser(this.currentUserId).subscribe({
      next: (data) => {
        this.currentUser = data;
        const roleIds = this.currentUser?.role.map((role) => role.id) || [];
        const foundationsIds = this.currentUser?.foundation.map((fond) => fond.id) || [];
        const editValues = {
          email: this.currentUser?.email,
          firstName: this.currentUser?.firstName,
          lastName: this.currentUser?.lastName,
          phone: this.currentUser?.phone,
          position: this.currentUser?.position,
          address: this.currentUser?.address,
          role: roleIds,
          foundation: foundationsIds,
        };
        this.userForm.patchValue(editValues);
      },
      error: (err) => console.warn(err),
    });
  }
  getFoundations() {
    this._userService.getUserFoundations().subscribe({
      next: (foundations) => {
        this.foundations = foundations;
      },
      error: (err) => console.warn(err),
    });
  }
  getRoles() {
    this._userService.getUserRoles().subscribe({
      next: (roles) => {
        this.roles = roles;
      },
      error: (err) => console.warn(err),
    });
  }
  toggleVisibility(type, control) {
    if (control === 'currentPasswordControl') {
      this.typePassword = type;
      this.typePassword = this.typePassword === 'password' ? 'text' : 'password';
    }
    if (control === 'passwordControl') {
      this.typePassword = type;
      this.typePassword = this.typePassword === 'password' ? 'text' : 'password';
    }
    if (control === 'confirmPasswordControl') {
      this.typeConfirmPassword = type;
      this.typeConfirmPassword = this.typeConfirmPassword === 'password' ? 'text' : 'password';
    }
  }
  changeRoleName(roleName) {
    if (roleName == 'OFFERTOOL_ADMIN') {
      roleName = 'auth.userManagement.admin';
      return roleName;
    }
    if (roleName == 'OFFERTOOL_READ_ONLY') {
      roleName = 'auth.userManagement.viewer';
      return roleName;
    }
    if (roleName == 'OFFERTOOL_EDIT') {
      roleName = 'auth.userManagement.user';
      return roleName;
    }
    return roleName;
  }
  handleCreateUser() {
    this.password.markAsTouched();
    this.confirmPassword.markAsTouched();
    if (this.userForm.invalid) return;
    const tenant = localStorage.getItem('tenant');
    let roles;
    let foundations;
    if (this.mode == 'edit') {
      roles = this.role.value.map((id) => {
        return this.roles.find((obj) => obj.id === id);
      });
      foundations = this.foundation.value.map((id) => {
        return this.foundations.find((obj) => obj.id === id);
      });
    }
    const body = {
      email: this.email.value,
      password: this.password.value ? this.password.value : null,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      phone: this.phone.value,
      position: this.position.value,
      address: this.address.value,
      role: roles ? roles : this.role.value,
      foundation: foundations ? foundations : this.foundation.value,
    };
    if (this.mode === 'new') {
      this.saveLoading = true;
      this._userService.createUser(tenant, body).subscribe({
        next: () => {
          this._snackbarService.handleOpenSnackbar(
            this._translate.instant('cabinet.notifications.createUserSuccess', { name: body.firstName }),
            'Close',
            'success'
          );
          this.saveLoading = false;
          this._router.navigate(['/users/list']);
        },
        error: (err) => {
          this.saveLoading = false;
          if(err.status == 409) {
            this._dialogService.handleDialogOpen(ConfirmationDialogComponent, {
              width: '300px',
              data: {
                title:  this._translate.instant('auth.userManagement.userProfileExists'),
                message: this._translate.instant('auth.userManagement.userProfileExistsDescription'),
                showOnlyOkBtn: true
              }
             
            });
          }
          console.warn(err);
        },
      });
    }
    if (this.mode === 'edit') {
      this.saveLoading = true;
      this._userService.updateUser(this.currentUserId, this.tenant, body).subscribe({
        next: () => {
          this._snackbarService.handleOpenSnackbar(
            this._translate.instant('cabinet.notifications.updateUserSuccess', { name: body.firstName }),
            'Close',
            'success'
          );
          this.saveLoading = false;
          this._router.navigate(['/users/list']);
        },
        error: (err) => console.warn(err),
      });
    }
  }

  selectRoles(role, event) {
    if (event.checked) {
      this.selectedRoles.push(role);
    } else {
      let filtered = this.selectedRoles.filter((r) => {
        return r.id !== role.id;
      });
      this.selectedRoles = filtered;
    }
  }
  selectFoundations(foundation, event) {
    if (event.checked) {
      this.selectedFoundations.push(foundation);
    } else {
      let filtered = this.selectedFoundations.filter((fon) => {
        return fon.id !== foundation.id;
      });

      this.selectedFoundations = filtered;
    }
  }
  returnToUsersList() {
    this._router.navigate(['/users/list']);
  }
}
